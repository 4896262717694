import React, { useEffect, useState } from 'react';
import { Select, Form as AntdForm } from 'antd';
import Cookie from 'js-cookie';
import axios from 'axios';
import styled from 'styled-components';
import { treatment } from '../../../hooks/treatment';

interface props {
  codParState: string;
  defaultValue: string;
  defaultOption: { CODTIPVENDA: string; DESCRTIPVENDA: string };
}

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function SelectTipVenda(props: props) {
  const [options, setOptions] = useState<any[]>([props.defaultOption]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookie.get('deskioapp-front-token');

  async function get() {
    setIsLoading(true);
    if (props.codParState !== '') {
      const res = await axios.post(
        'https://apiapp.deskio.com.br/api/v1/quotes/search-tipo-negociacao',
        {
          codpar: props.codParState,
        },
        {
          headers: {
            authorization: `bearer ${token?.replaceAll('"', '')}`,
          },
        },
      );

      treatment(res.data);

      if (Array.isArray(res.data)) {
        setOptions(res.data);
      } else {
        setOptions([res.data]);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    get();
  }, [props.codParState]);

  return (
    <AntdForm.Item
      label="Tipo de venda"
      name="CODTIPVENDA"
      style={{ width: '100%' }}
      rules={[{ required: true, message: `Campo obrigatório` }]}
    >
      <AntdSelect
        defaultValue={props.defaultValue}
        filterOption={false}
        placeholder="Escolha um tipo de venda"
        loading={isLoading}
      >
        {options?.map(option => (
          <AntdSelect.Option value={option.CODTIPVENDA}>{option.DESCRTIPVENDA}</AntdSelect.Option>
        ))}
      </AntdSelect>
    </AntdForm.Item>
  );
}
