import React, { useState, Suspense, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { Switch } from 'react-router-dom';
import { PageHeader } from '~/components/page-headers/page-headers';
import { Main } from '~/container/styled';
import EmptyMessage from '~/components/emptyMessage';
import List from './overview/List';
import axios from 'axios';
import { ThemeLayout } from '../../layout/themeLayout';
import Cookies from 'js-cookie';
import { CONFERENCE_QUEUE_URL } from '~/utility/useLinks';
import { useAuth } from '../../hooks/useAuth';

function Conference() {
  const [conferenceData, setConferenceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = Cookies.get('deskioapp-front-token');
  const { errorSessionTokenControl } = useAuth();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await axios.get(CONFERENCE_QUEUE_URL, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const responseBody = response.data;
        setConferenceData(responseBody);

        if (responseBody?.success === false) {
          errorSessionTokenControl(responseBody);
        }
      }
      setLoading(false);
    }

    fetchData();
  }, [errorSessionTokenControl, token]);

  /*   useEffect(() => {
    setLoading(true)
    if(token) {
      async function fetchData() {
        const res = await axios.get("https://apiapp.deskio.com.br/api/v1/fila-conferencia", {
          headers: {
            authorization: `Bearer ${token}`
          }
        });

        if(res.data) {
          const responseBody = res;

          if(!!responseBody) {
            setConferenceData(lastState => lastState ? [...lastState, ...responseBody] : responseBody);
            setLoading(false)
          } else {
           
              if(!conferenceData) {
                setLoading(false)
              }
            
          }
        }
      }
      fetchData();
    }
  }, []); */

  /*  useEffect(() => {
    setLoading(true)
    if(token) {
      async function fetchData() {
        const res = await axios.get("https://apiapp.deskio.com.br/api/v1/fila-conferencia-ea", {
          headers: {
            authorization: `Bearer ${token}`
          }
        });

        if(res.data) {
          const responseBody = res.data.responseBody;
          if(!!responseBody?.result) {
            setConferenceData(lastState => lastState ? [...lastState, ...responseBody.result] : responseBody.result);
            setLoading(false)
          } else {
            setTimeout(() => {
              if(!conferenceData) {
                setLoading(false)
              }
            }, 35000) // 35seg
          }
        }
        
      }
  
      fetchData();
    }
  }, []);

  useEffect(() => {
    setLoading(true)
    if(token) {
      async function fetchData() {
        const res = await axios.get("https://apiapp.deskio.com.br/api/v1/fila-conferencia-ar", {
          headers: {
            authorization: `Bearer ${token}`
          }
        });

        if(res.data) {
          const responseBody = res.data.responseBody;
          if(!!responseBody?.result) {
            setConferenceData(lastState => lastState ? [...lastState, ...responseBody.result] : responseBody.result);
            setLoading(false)
          } else {
            setTimeout(() => {
              if(!conferenceData) {
                setLoading(false)
              }
            }, 35000) // 35seg
          }
        }
        
      }
  
      fetchData();
    }
  }, []);

  useEffect(() => {
    setLoading(true)
    if(token) {
      async function fetchData() {
        const res = await axios.get("https://apiapp.deskio.com.br/api/v1/fila-conferencia-ra", {
          headers: {
            authorization: `Bearer ${token}`
          }
        });

        if(res.data) {
          const responseBody = res.data.responseBody;
          if(!!responseBody?.result) {
            setConferenceData(lastState => lastState ? [...lastState, ...responseBody.result] : responseBody.result);
            setLoading(false)
          } else {
            setTimeout(() => {
              if(!conferenceData) {
                setLoading(false)
              }
            }, 35000) // 35seg
          }
        }
      }
  
      fetchData();
    }
  }, []);
 */
  return (
    <ThemeLayout>
      <PageHeader
        ghost
        title="Fila de Conferência"
        buttons={[
          <div key="1" className="page-header-actions">
            {/* <Button
              size="small"
              key="2"
              type="primary"
              onClick={() => {
                updateList();
              }}
            >
              <SyncOutlined size={12} />
              Atualizar
            </Button> */}
          </div>,
        ]}
      />
      <Main>
        <Row gutter={5}>
          <Col flex={1} className="product-content-col" xxl={24} lg={24} md={14} xs={24}>
            {loading ? (
              <div className="spin d-flex align-center-v">
                <Spin />
              </div>
            ) : (
              <>
                {!conferenceData ? (
                  <Col xs={24}>
                    <div className="spin">
                      <EmptyMessage text="Nenhuma Informação" iconSize={70} textSize={14} />
                    </div>
                  </Col>
                ) : (
                  <Switch>
                    <Suspense
                      fallback={
                        <div className="spin d-flex align-center-v">
                          <Spin />
                        </div>
                      }
                    >
                      <List data={conferenceData} />
                    </Suspense>
                  </Switch>
                )}
              </>
            )}
          </Col>
        </Row>
      </Main>
    </ThemeLayout>
  );
}

export default Conference;
