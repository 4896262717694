import React from 'react';
import { Container } from './styles';

interface ButtonOrcProps {
  title: string;
  color: string;
  redirectLink: string;
}

export function ButtonOrc({ color: titleColor, title, redirectLink }: ButtonOrcProps) {
  function color() {
    switch (titleColor) {
      case 'AMARELO':
        return '#ff8c00';
      case 'AZUL':
        return '#00b4d8';
      default:
        return '#40916c';
    }
  }

  function bgColor() {
    switch (titleColor) {
      case 'AMARELO':
        return '#fcefb4';
      case 'AZUL':
        return '#caf0f8';
      default:
        return '#b7efc5';
    }
  }

  return (
    <Container>
      <button
        onClick={() => window.open(redirectLink, '_blank')}
        style={{
          color: color(),
          background: bgColor(),
          border: `0px solid ${color()}`,
          padding: '3px 10px',
          borderRadius: 10,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'start',
          boxSizing: 'border-box',
          fontVariant: 'tabular-nums',
          listStyle: 'none',
          fontFeatureSettings: 'tnum',
          display: 'inline-block',
          height: 'auto',
          fontSize: '12px',
          lineHeight: '20px',
          whiteSpace: 'nowrap',
          transition: 'all 0.3s',
        }}
      >
        {title}
      </button>
    </Container>
  );
}
