import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button, Input } from 'antd';
import { useParams } from 'react-router-dom';
import ItemsCardList from './ItemsCardList';
import { CheckOutlined, BarcodeOutlined } from '@ant-design/icons';
import axios from 'axios';
import EmptyMessage from '~/components/emptyMessage';
import { SAVE_ITEM_CONFERRED_URL } from '../../../utility/useLinks';
import Cookies from 'js-cookie';
import { api } from '../../../services/api';
import { useAuth } from '../../../hooks/useAuth';
import useSound from 'use-sound';
import sound from '../../../sounds/boop.mp3';

const ItemsList = ({ refetchData, numConf, data, extraData, divergencias, setExtraData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemModalInfo, setItemModalInfo] = useState(undefined);
  const [qtdConferida, setQtdConferida] = useState(undefined);
  const { errorSessionTokenControl } = useAuth();
  const [play] = useSound(sound);

  useEffect(() => {
    if (itemModalInfo) {
      api
        .post('/get-multiplicator', {
          codprod: itemModalInfo[0],
        })
        .then(response => {
          //(response);
        });
    }
  }, [itemModalInfo]);

  useEffect(() => {
    if (!modalIsOpen) setQtdConferida(undefined);
  }, [modalIsOpen]);

  const { codPedido } = useParams();

  useEffect(() => {
    if (codPedido && itemModalInfo) {
      api
        .post(
          'status-itens-conferidos',
          { nuConf: numConf },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        )
        .then(response => {
          const res = response.data.responseBody.result.find(item => item[2] === itemModalInfo[0]);

          if (res) setQtdConferida(res[8]);
          else setQtdConferida(0);
        });
    }
  }, [codPedido, modalIsOpen]);

  const [quantityText, setQuantityText] = useState(1);
  const [codBar, setCodBar] = useState('');

  const [conferenceLoading, setConferenceLoading] = useState(false);
  const token = Cookies.get('deskioapp-front-token');

  const handlOpenModal = item => {
    setItemModalInfo(item);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setItemModalInfo(undefined);
    setModalIsOpen(false);
  };

  useEffect(() => {
    //(Number(quantityText));
  }, [quantityText]);

  async function conferirItem() {
    if (itemModalInfo) {
      // if (itemModalInfo[0] !== codBar && itemModalInfo[16] !== codBar) {
      //   Modal.warning({
      //     title: 'Aviso',
      //     content: (
      //       <div>
      //         <p>O Código do produto não confere!</p>
      //       </div>
      //     ),
      //   });
      //   return;
      // }

      if (Number(quantityText) <= 0) {
        play();
        Modal.warning({
          title: 'Aviso',
          content: (
            <div>
              <p>A quantidade não pode ser menor ou igual a zero.</p>
            </div>
          ),
        });
        return;
      }

      setConferenceLoading(true);

      async function fetchData() {
        const res = await axios.post(
          SAVE_ITEM_CONFERRED_URL,
          {
            numConf: numConf,
            nunNota: codPedido,
            codBar: String(itemModalInfo[0]),
            qtde: quantityText,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );
        const response1 = res.data;
        if (response1?.success == false) {
          errorSessionTokenControl(response1);
        }

        if (res.data.status === '1') {
          refetchData();
          setConferenceLoading(false);
          setExtraData({
            barcode: String(itemModalInfo[0]),
            qtde: quantityText,
          });
          setCodBar();
          setModalIsOpen(false);
        } else {
          play();
          Modal.warning({
            title: 'Erro!',
            content: (
              <div>
                <p>{res.data.statusMessage}</p>
              </div>
            ),
          });
          setConferenceLoading(false);
        }
      }
      fetchData();
    }
    setTimeout(() => {
      setExtraData(null);
    }, 200);
  }

  function onChangeInputNotNumber(e) {
    function isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }

    if (isNumber(e)) {
      setQuantityText(e);
    }
  }

  return (
    <Row gutter={15}>
      {data.length ? (
        data.map((item, index) => {
          return (
            <Col xs={24} key={index}>
              <ItemsCardList
                item={item}
                data={extraData}
                divergencias={divergencias}
                onClick={() => handlOpenModal(item)}
              />
            </Col>
          );
        })
      ) : (
        <Col xs={24}>
          <div className="spin">
            <EmptyMessage text="Nenhuma informação" iconSize={70} textSize={20} />
          </div>
        </Col>
      )}

      {modalIsOpen && (
        <Modal
          title={itemModalInfo[1]}
          visible
          onCancel={handleCloseModal}
          footer={null}
          maskClosable={false}
          width={600}
        >
          <div>
            <span style={{ fontWeight: 600 }}>Código do Produto:</span>
            <Input
              value={codBar}
              onChange={txt => setCodBar(txt.target.value)}
              addonAfter={<BarcodeOutlined />}
              placeholder="Código de barras"
              style={{ width: '100%', marginBottom: '1rem' }}
            />

            <div style={{ display: 'flex', alignItens: 'center' }}>
              <p>
                <span style={{ fontWeight: 600, marginRight: '0.5rem' }}>QTD Total:</span>
                {itemModalInfo[4]}
              </p>
              <p style={{ marginLeft: 'auto' }}>
                <span style={{ fontWeight: 600 }}>QTD Conferida:</span>
                {qtdConferida ? qtdConferida : 'carregando...'}
              </p>
            </div>

            <span style={{ fontWeight: 600 }}>Qtde:</span>
            <Input
              type="number"
              value={quantityText}
              onChange={txt => onChangeInputNotNumber(txt.target.value)}
              placeholder="Quantidade"
              style={{ width: '100%' }}
            />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Button
                style={{ marginTop: '1rem' }}
                icon={<CheckOutlined />}
                onClick={conferirItem}
                size="large"
                type="primary"
                loading={conferenceLoading}
              >
                {conferenceLoading ? 'Conferindo...' : 'Conferir'}
              </Button>

              <div>
                COD Local: <span>{itemModalInfo[10]}</span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Row>
  );
};

export default ItemsList;
