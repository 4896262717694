import Cookies from 'js-cookie';
import { treatment } from '../../../hooks/treatment';
import { api } from '../../../services/api';

interface IGetProductProps {
  codprod: string;
  nunota: string;
}

export interface IGetProductReturn {
  codlocalorig: string;
  codprod: string;
  percdesc: string;
  precobase: string;
  qtdneg: string;
  vlrunit: string;
  codvol: string;
}

const searchURL = '/quotes/get-item-informations';

export async function getProduct({ codprod, nunota }: IGetProductProps) {
  const token = Cookies.get('deskioapp-front-token')?.replaceAll('"', '');
  const bodyContent = {
    nunota,
    codprod,
  };

  const { data } = await api.post(searchURL, bodyContent, {
    headers: {
      authorization: `bearer ${token}`,
    },
  });

  treatment(data);

  const res: IGetProductReturn = {
    codlocalorig: data.NOTA.CODLOCALORIG,
    codprod: data.NOTA.CODPROD,
    percdesc: data.NOTA.PERCDESC,
    precobase: data.NOTA.PRECOBASE,
    qtdneg: data.NOTA.QTDNEG,
    vlrunit: data.NOTA.VLRUNIT,
    codvol: data.NOTA.CODVOL,
  };

  return res;
}
