import React, { useState } from 'react';
import { Select, Form as AntdForm } from 'antd';
import Cookie from 'js-cookie';
import axios from 'axios';
import styled from 'styled-components';
import { api } from '../../../../services/api';

interface SelectParProps {
  onChange: (e: string) => void;
}

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function SelectPar({ onChange }: SelectParProps) {
  const [options, setOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  async function get(search: string) {
    setIsLoading(true);
    const res = await api.post('/search-parc', { term: search });

    setOptions(res.data.data);
    setIsLoading(false);
  }

  let timer: NodeJS.Timeout;
  function debounce(event: string) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      get(event);
    }, 1000);
  }

  return (
    <AntdForm.Item
      label="Parceiro/Cliente"
      name="CODPARC"
      style={{ width: '100%' }}
      rules={[{ required: true, message: `Campo obrigatório` }]}
    >
      <AntdSelect
        onChange={(e: any) => onChange(e)}
        showSearch
        filterOption={false}
        placeholder="Busque um parceiro"
        onSearch={e => debounce(e)}
        loading={isLoading}
      >
        {options?.map(option => (
          <AntdSelect.Option value={option.CODPARC}>{option.NOMEPARC}</AntdSelect.Option>
        ))}
      </AntdSelect>
    </AntdForm.Item>
  );
}
