import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { IGetProductReturn } from '../functions';
import { IProductDTO } from '../types';

interface IHandleChangeRow {
  state: IProductDTO[];
  saveds: { id: string; value: 'saved' | 'created' | 'editaded' }[];
  setSaveds: (e: { id: string; value: 'saved' | 'created' | 'editaded' }[]) => void;
  setState: (value: React.SetStateAction<IProductDTO[]>) => void;
  productProps: IGetProductReturn;
  rowId: string;
  produDescription: string;
}

export function handleChangeRow({
  saveds,
  setSaveds,
  produDescription,
  productProps,
  rowId,
  setState,
  state,
}: IHandleChangeRow) {
  const user = JSON.parse(Cookies.get('strutural-user') || '') as { urlimagens: string };
  const initialProduct = state.find(prod => prod.id === rowId);
  const array = [...state.filter(itens => itens.id !== rowId)];

  if (!initialProduct || !user) {
    return;
  }

  const { codlocalorig, codprod, percdesc, qtdneg, vlrunit, codvol } = productProps;

  const obj: IProductDTO = {
    id: initialProduct.id,
    sequencia: initialProduct.sequencia,
    percdesc: percdesc,
    codlocalorig,
    codprod,
    descprod: produDescription,
    codvol: codvol,
    qtdneg: Number(qtdneg),
    img: user.urlimagens.replace('{CODPROD}', codprod),
    vlrunit: Number(vlrunit),
    vlrdesc: Number(percdesc),
    vlrtot: 0,
    vlrliq: 0,
    position: initialProduct.position,
  };

  const itesFilter = saveds.filter(item => item.id !== initialProduct.codprod);
  itesFilter.push({ id: codprod, value: 'created' });

  const find = array.find(item => item.codprod === obj.codprod);
  if (find) {
    toast.error('Este produto já está cadastrado');
    return;
  }

  setSaveds(itesFilter);
  setState([...array, obj]);
}
