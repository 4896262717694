import styled from 'styled-components';
import { theme } from '../../../../config/theme/themeVariables';

export const Container = styled.div`
  border-radius: 0.5rem;

  flex: 1;

  header {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0;
      color: ${theme['heading-color']};
    }

    p {
      margin-bottom: 0;
      color: ${theme['gray-solid']};
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;

  background-color: ${theme['bg-color-deep']};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 12px;

  th,
  td {
    text-align: start;
    padding: 1rem;
    white-space: nowrap;
  }

  td {
    background-color: #fff;
  }

  tr {
    border-top: 1px solid ${theme['bg-color-deep']};
  }
`;
