import React, { useState } from 'react';
import { Select, Form as AntdForm } from 'antd';
import Cookie from 'js-cookie';
import axios from 'axios';
import styled from 'styled-components';

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }

  .ant-select-selection {
    max-height: 8rem;
  }
`;

export function SelectPar({ id }: { id: string }) {
  const [options, setOptions] = useState<any[]>();
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookie.get('deskioapp-front-token');

  async function get(search: string) {
    setIsLoading(true);
    const res = await axios.post(
      'https://apiapp.deskio.com.br/api/v1/contacts/search-parc-chatwoot',
      {
        term: search,
        id: id,
      },
      {
        headers: {
          authorization: `bearer ${token?.replaceAll('"', '')}`,
        },
      },
    );

    setOptions(Array.isArray(res.data) ? res.data : [res.data]);
    setIsLoading(false);
  }

  let timer: NodeJS.Timeout;
  function debounce(event: string) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      get(event);
    }, 1000);
  }

  return (
    <AntdForm.Item
      label="Buscar Contato"
      name="codparc"
      style={{ width: '100%' }}
      rules={[{ required: true, message: `Campo obrigatório` }]}
    >
      <AntdSelect
        showSearch
        filterOption={false}
        placeholder="Busque um contato"
        onSearch={e => debounce(e)}
        loading={isLoading}
      >
        {options?.map(option => (
          <AntdSelect.Option value={option[0]}>{option[1]}</AntdSelect.Option>
        ))}
      </AntdSelect>
    </AntdForm.Item>
  );
}
