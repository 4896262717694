import React, { useRef } from 'react';
import { Button, DatePicker, Form, FormInstance, Input } from 'antd';

import moment from 'moment';
import { FiltersWrapper } from '../../instances/style';
import { SelectPar } from './SelectPar';

type ITypeFilter = {
  content: string;
  key: string;
};

type FiltersProps = {
  filters: ITypeFilter[];
  clearFilters: () => void;
  handleAddFilters: (event: { key: string; content: string }) => void;
  handleApplyFilters: () => void;
};

export function FilterDrawer({ handleAddFilters, clearFilters, filters, handleApplyFilters }: FiltersProps) {
  function handleChangeFilters([date1, date2]: any) {
    if (!date1 || !date2) return;
    const format = (date?: moment.Moment) => (date ? date.format('DD-MM-YYYY') : '');
    handleAddFilters({ key: '&createdAt=', content: `${format(date1)},${format(date2)}` });
  }

  function setNewFilters(key: string, value: string) {
    handleAddFilters({ key, content: value });
  }

  const formRef = useRef<FormInstance<any>>(null);

  return (
    <FiltersWrapper>
      <Form ref={formRef} layout="vertical">
        <Form.Item label="Data Emissão">
          <DatePicker.RangePicker
            placeholder={['Data Inicial', 'Data Final']}
            format="DD/MM/YYYY"
            onChange={dates => !!dates && handleChangeFilters(dates)}
          />
        </Form.Item>

        <Form.Item label="Número Único">
          <Input
            value={filters.find(item => item.key === '&NUNOTA=')?.content}
            onChange={e => setNewFilters('&NUNOTA=', e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Número do Pedido">
          <Input
            value={filters.find(item => item.key === '&NUMNOTA=')?.content}
            onChange={e => setNewFilters('&NUMNOTA=', e.target.value)}
          />
        </Form.Item>

        <SelectPar onChange={e => setNewFilters('&CODPARC=', e)} />
      </Form>

      <footer>
        {filters.length !== 0 && (
          <Button
            type="primary"
            danger
            size="large"
            onClick={() => {
              clearFilters();
              formRef.current?.resetFields();
            }}
          >
            Limpar filtros
          </Button>
        )}
        <Button type="primary" size="large" onClick={handleApplyFilters}>
          Adicionar filtros
        </Button>
      </footer>
    </FiltersWrapper>
  );
}
