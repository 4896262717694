import React, { createContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import md5 from "md5";

import Cookie from "js-cookie";

import { api } from "../../services/api";
import { Form, Input, Modal, Button } from "antd";
import Heading from "../../components/heading/heading";

import type { 
  AuthProviderProps, 
  IUserProps, 
  signInCredentials,

  signUpCredentials
} from "./types";

interface AuthContextData {
  signIn: (credentials: signInCredentials) => Promise<void>;
  signUp: (credentials: signUpCredentials) => Promise<void>;
  signOut: () => void;
  signUpAffiliate: (credentials: any) => Promise<void>;
  user: IUserProps | undefined;
  errorSessionTokenControl: (err: any) => void;
}

export const AuthContext = createContext({} as AuthContextData); 

export function AuthProvider({ children }: AuthProviderProps) {
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [form] = Form.useForm();

  const [user, setUser] = useState<IUserProps | undefined>(() => {
    const userCookie = Cookie.get("strutural-user");

    if(userCookie) return JSON.parse(userCookie);
    return undefined;
  });

  useEffect(() => {
    const token = Cookie.get('deskioapp-front-token');
    if (token) {
      api.defaults.headers.common.authorization = `Bearer ${token}`;
    }
  }, []);

  async function signIn({ email, password }) {
    try {
      const { data: skData } = await api.post("https://apiapp.deskio.com.br/api/v1/login-sankhya", {
        email:email.toLowerCase(),
        password,
        url: window.location.origin,
      })

     
        if(skData.tsError){
          toast.error(skData.statusMessage);

        }else{
          if(skData.responseBody.jsessionid){

            
              const jsessionID = skData.responseBody.jsessionid.$;

              const { data } = await api.post('/login', {
                email:email.toLowerCase(),
                jsessionID,
                url: window.location.origin,
                password:password,
                password_md5: md5(email.toUpperCase() + password),
              });

              
              api.defaults.headers.common.authorization = `Bearer ${data.token.accessToken}`;
              const dataFetch = await api.get("/accounts/account-info")

              Cookie.set('deskioapp-account-info',  JSON.stringify(dataFetch.data.data), {
                expires: new Date(Date.now() + 1000 * 60 * 60 * 24), // one day
              });

              Cookie.set('deskioapp-front-token', data.token.accessToken, {
                expires: new Date(Date.now() + 1000 * 60 * 60 * 24), // one day
              });

              Cookie.set('strutural-jsessionID', data.jsessionID, {
                expires: new Date(Date.now() + 1000 * 60 * 60 * 24), // one day
              });

              Cookie.set('strutural-user', JSON.stringify(data.user), {
                expires: new Date(Date.now() + 1000 * 60 * 60 * 24), // one day
              });

              Cookie.set('strutural-user-permissions', data.user.permissions, {
                expires: new Date(Date.now() + 1000 * 60 * 60 * 24), // one day
              });

              setUser(user);
              toast.success(`Seja bem vindo, ${data.user.name}!`)
              history.push('/admin/products');
              window.location.reload();
          }
        }
     

    } catch (err: any) {
      if(err.response?.data.message.message === "error.userNotFound") {
        toast.error("Usuário não encontrado");
      } 

      if(err.response?.data.message.message === "error.passwordInvalid") {
        toast.error("Senha incorreta");
      } 
      if(err.response?.data.message.message === "error.userInactive") {
        toast.error("Usuário Inativo ou Pendente!");
      } 

      
    };
  }

  function errorSessionTokenControl(responseData: any) {
    if(responseData?.error === 90) {
      setModalIsOpen(true);
    }
    if(responseData?.message) {
      toast.error(responseData?.message);
    }
  }

async function signUp({email, password, name, whatsapp_contact}){
  try {
    await api.post('/register', {
      email,
      name,
      whatsapp_contact,
      password,
    });

    signIn({email,password})

  } catch (err: any) {
    toast.error(err.response.data.message);
  };
}

async function signUpAffiliate({email, password, name, whatsapp_contact, instagram, youtube}){
  try {

    await api.post('/register', {
      email,
      name,
      whatsapp_contact,
      password,
      instagram, youtube
    });

    signIn({email,password})

  } catch (err: any) {
    toast.error(err.response.data.message);
  };
}

  function signOut() {
    Cookie.remove("deskioapp-front-token");
    Cookie.remove("strutural-jsessionID");
    history.push("/");
    setModalIsOpen(false);
  }

  async function onConfirm(data: {user: string, password: string}) {
    await signIn({ email: data.user, password: data.password });
    window.location.reload();
  }

  return (
    <AuthContext.Provider value={{ user, signIn, signUp, signOut, signUpAffiliate, errorSessionTokenControl }}>
      <Toaster 
        position="top-right"
        reverseOrder={false}
      />

      <Modal visible={modalIsOpen} footer={false} closeIcon={false}>
        <Form name="login" form={form} onFinish={onConfirm} layout="vertical">
          <Heading as="h4">
            Sua sessão no Sankhya expirou, <span className="color-secondary">faça login novamente</span>
          </Heading>

          <Form.Item
            style={{marginTop: "2rem"}}
            name="user"
            rules={[{ message: "Digite aqui o seu SankhyaID (email)!", required: true }]}
            label="Usuário"
          >
            <Input />
          </Form.Item>

          <Form.Item 
            name="password" 
            rules={[{ message: "Digite sua senha!", required: true }]} 
            label="Senha"
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item style={{ display: "flex", alignItems: "center" }}>
            <Button style={{width: "49%"}} onClick={signOut} className="btn-signin" htmlType="submit" danger type="primary" size="large">
              Voltar
            </Button>

            <Button style={{width: "49%", marginLeft: "0.5rem"}} className="btn-signin" htmlType="submit" type="primary" size="large">
              Fazer Login
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      
      {children}
    </AuthContext.Provider>
  );
}
