import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { Form } from './ContactForm';

export function VincularContanto({ refetch, chatwoodId }: { refetch: () => void; chatwoodId: string }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function fetch() {
    setModalIsOpen(false);
    refetch();
  }
  return (
    <>
      <Button onClick={() => setModalIsOpen(true)}>Vincular contato</Button>
      <Modal
        title="Vincular contato"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form setModalIsOpen={setModalIsOpen} chatWoodIdContact={chatwoodId} refetch={fetch} />
      </Modal>
    </>
  );
}
