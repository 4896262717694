import React, { useEffect } from 'react';
import Cookie from 'js-cookie';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

import { ProtectedRoute } from '../components/utilities/protectedRoute';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import { Contacts } from '../pages/contacts';
import { Segments } from '../pages/segments';
import { Campaigns } from '../pages/campaigns';
import { Instances } from '../pages/instances';
import { UserInteration } from '../pages/interations/UserInterations';
import { Interations } from '../pages/interations/AdminInterations';
import { FaqAdmin } from '../pages/faq/AdminFaq/index';
import { ProfilePage } from '../pages/profilepage';
import { hortmartEvents } from '../pages/hotmartEvents';
import { homarts } from '../pages/hotmart';
import Faq from '../pages/faq/UserFaq';
import Video from '../pages/video';
import { ViewInstanceDetails } from '../pages/viewInstanceDetails';
import { Plugins } from '../pages/plugins/AdminInterations';
import { UserPlugin } from '../pages/plugins/UserInterations';
import SignUpAffiliate from '../pages/SignUpAffiliate';
import Conference from '../pages/conference/Conference';
import ConferenceDetails from '../pages/conference/ConferenceDetails';
import Products from '../pages/products/Products';

import { Users } from '../pages/users';
import { Invoicesqueue } from '../pages/invoicesqueue';
import { FilaOfNotas } from '../pages/filaOfNotes';
import { EditOrcamento } from '../pages/EditOrcamento';

import { Logs } from '../pages/logs';
import { Companies } from '../pages/companies';
import { CampaignsWhats } from '../pages/campaignsWhats';
import { Plans } from '../pages/plans';
import { Payments } from '../pages/payments';
import { Pipeline } from '../pages/pipeline';

import { AddOrcamento } from '../pages/AddOrcamento';
import { FilaOfNotasClient } from '../pages/filaOfNotesClient';
import { Tags } from '../pages/tags';
import { Times } from '../pages/times';

export function Routes() {
  const location = useLocation();
  const history = useHistory();

  const token = Cookie.get('deskioapp-front-token');

  useEffect(() => {
    if (location.pathname === '/' && token) {
      history.push('/admin/products');
    }
  }, [history, token, location.pathname]);

  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route path="/signUp" component={SignUp} />
      <Route path="/chatwood" component={FilaOfNotasClient} />
      <Route path="/signup-affiliate" component={SignUpAffiliate} />
      <ProtectedRoute path="/profile/:inititial" component={ProfilePage} />
      <ProtectedRoute path="/video" component={Video} />
      <ProtectedRoute path="/faq" component={Faq} />
      <ProtectedRoute path="/integrations" component={UserInteration} />
      <ProtectedRoute path="/plugins" component={UserPlugin} />
      <ProtectedRoute exact path="/instances" component={Instances} />
      <ProtectedRoute path="/instances/:id" component={ViewInstanceDetails} />

      <ProtectedRoute path="/admin/contacts" component={Contacts} />
      <ProtectedRoute path="/admin/tags" component={Tags} />
      <ProtectedRoute path="/admin/teams" component={Times} />
      <ProtectedRoute path="/admin/events/:product_id" component={hortmartEvents} />
      <ProtectedRoute path="/admin/hotmart" component={homarts} />
      <ProtectedRoute path="/admin/faq" component={FaqAdmin} />
      <ProtectedRoute path="/admin/segments" component={Segments} />
      <ProtectedRoute path="/admin/interations" component={Interations} />
      <ProtectedRoute path="/admin/plugins" component={Plugins} />

      <ProtectedRoute path="/admin/usuarios" component={Users} />

      <ProtectedRoute path="/admin/products" component={Products} />
      <ProtectedRoute path="/admin/logs" component={Logs} />
      <ProtectedRoute path="/admin/campaignswhats" component={CampaignsWhats} />
      <ProtectedRoute path="/admin/payments" component={Payments} />
      <ProtectedRoute path="/admin/profilepage" component={ProfilePage} />
      <ProtectedRoute path="/admin/contacts" component={Contacts} />
      <ProtectedRoute path="/admin/pipeline" component={Pipeline} />
      <ProtectedRoute path="/admin/companies" component={Companies} />
      <ProtectedRoute path="/admin/campaigns" component={Campaigns} />
      <ProtectedRoute path="/admin/conferenceDetails/:codPedido" component={ConferenceDetails} />
      <ProtectedRoute path="/admin/conference" component={Conference} />
      <ProtectedRoute path="/admin/plans" component={Plans} />
      <ProtectedRoute path="/admin/invoicesqueue" component={Invoicesqueue} />
      <ProtectedRoute path="/admin/orcamentos" component={FilaOfNotas} />
      <ProtectedRoute path="/admin/orcamento/add" component={AddOrcamento} />
      <ProtectedRoute path="/admin/orcamento/:nunota" component={EditOrcamento} />
    </Switch>
  );
}
