import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { ICampaignsWhatsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: ICampaignsWhatsProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<ICampaignsWhatsProps> = [
    {
      title: 'Título',
      dataIndex: 'title',
      sorter: true,
    },
    {
      title: 'Início',
      dataIndex: 'started_at',
      sorter: true,
    },

    {
      title: 'Máximo por dia',
      dataIndex: 'max_per_day',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <TableButtonEdit campaignWhatsId={String(item.id)} refetch={refetch} data={item} />

            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} campaignWhatsId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      name: item.name,
      started_at: item.start_at,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
    });
  });

  return { columns, data };
}
