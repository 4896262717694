import React, { useEffect, useState } from 'react';
import { Select, Form as AntdForm } from 'antd';
import Cookie from 'js-cookie';
import axios from 'axios';
import styled from 'styled-components';
import { treatment } from '../../../hooks/treatment';

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

type SelectCodNatProps = {
  defaultValue: string;
};

export function SelectCodNat({ defaultValue }: SelectCodNatProps) {
  const [data, setData] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookie.get('deskioapp-front-token');

  function onSearch(e: string) {
    if (e === '' || !e) setOptions(data);
    else setOptions(data.filter(item => item.DESCRNAT.toUpperCase().includes(e.toUpperCase())));
  }

  async function get() {
    setIsLoading(true);
    const res = await axios.get('https://apiapp.deskio.com.br/api/v1/orders/search-codnat', {
      headers: {
        authorization: `bearer ${token?.replaceAll('"', '')}`,
      },
    });

    treatment(res.data);
    setData(res.data.data);
    setOptions(res.data.data);
    setIsLoading(false);
  }

  useEffect(() => {
    get();
  }, []);

  return (
    <AntdForm.Item
      label="Natureza"
      name="CODNAT"
      style={{ width: '100%' }}
      rules={[{ required: true, message: `Campo obrigatório` }]}
    >
      <AntdSelect
        filterOption={false}
        onSearch={onSearch}
        showSearch
        defaultValue={defaultValue}
        placeholder="Escolha a natureza"
        loading={isLoading}
      >
        {options?.map(option => (
          <AntdSelect.Option value={option.CODNAT}>{option.DESCRNAT}</AntdSelect.Option>
        ))}
      </AntdSelect>
    </AntdForm.Item>
  );
}
