import styled from 'styled-components';

export const FakeTableHeader = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  background-color: #f8f9fb;
  border: 1px solid #f2f3f6;

  color: #868298;

  height: 3.5rem;
  border-radius: 0.35rem;
  padding: 1rem;
  gap: 1rem;
  font-size: 0.9rem;
  font-weight: 400;

  p {
    margin: 0;
    padding-left: 1rem;

    &.data {
      width: 9rem;
    }

    &.pedido-cliente {
      width: 30rem;
      border-left: 1px solid #e3e6ef;
    }

    &.orcamentos {
      flex: 1;
      border-left: 1px solid #e3e6ef;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem;
  background-color: #fff;
  border-radius: 1rem;
`;
