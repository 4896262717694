import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';

import { Button } from '../../../../components/buttons/buttons';
import { Modal } from 'antd';
import { Form } from '../ContactForm';

interface ITableButtonEditProps {
  campaignWhatsId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  name: string;
  message: string;
  image: string;
  file: string;
  tags: string;
  segment_id: string;
  max_per_day: string;
  start_at: string;
  delay_start: string;
  delay_end: string;
  pause_time: string;
  pause_after: string;
}

export function TableButtonEdit({ campaignWhatsId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="edit" size={16} />
      </Button>

      <Modal
        title="Editar Campanha"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          formType="put"
          campaignWhatsId={campaignWhatsId}
          initialFields={{
            name: data.name,
            message: data.message,
            file: data.file,
            image: data.image,
            tags: data.tags,
            segment_id: data.segment_id,
            max_per_day: data.max_per_day,
            start_at: data.start_at,
            delay_start: data.delay_start,
            delay_end: data.delay_end,
            pause_time: data.pause_time,
            pause_after: data.pause_after,
          }}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}
