import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  overflow: auto;
  max-height: 20rem;
  padding: 1rem;
  background-color: #FFF;
  border: 1px solid #CCC;
  border-radius: 1rem;

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;

    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  .closed {
    margin-left: auto;
  }
`;