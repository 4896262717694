import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { ILogsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: any[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Descrição',
      dataIndex: 'description',
      sorter: true,
    },
    {
      title: 'Data',
      dataIndex: 'created_at',
      sorter: true,
      render: (_, data) => new Date(data.created_at).toLocaleDateString(),
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      sorter: true,
    },
  ];

  return { columns, data: dataList };
}
