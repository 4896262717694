import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IContactsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IContactsProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const MaxWidthColumn: React.FC<{ width: number }> = ({ width, children }) => (
    <div
      style={{
        maxWidth: width,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </div>
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
    },

    {
      title: 'Telefone',
      dataIndex: 'phone_number',
      sorter: true,
    },

    {
      title: 'Status Sankhya',
      dataIndex: 'codcontato',
      render: (_, row: IContactsProps) => {
        if (row.codcontato != null) {
          return (
            <MaxWidthColumn width={180}>
              {' '}
              <span
                style={{
                  color: '#20C9A5',
                  background: '#E2F4F0',
                  border: `0px solid ${'#b7eb8f'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '12px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                Vinculado
              </span>
            </MaxWidthColumn>
          );
        } else if (row.codcontato == null) {
          return (
            <MaxWidthColumn width={180}>
              {' '}
              <span
                style={{
                  color: '#d8ae3a',
                  background: '#f4eacd',
                  border: `0px solid ${'#f4eacd'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '12px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                Não-Vinculado
              </span>
            </MaxWidthColumn>
          );
        }
      },
    },

    {
      title: 'Data de criação',
      dataIndex: 'created_at',
      sorter: true,
      render: (_, data) => new Date(data.created_at).toLocaleDateString(),
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <TableButtonEdit contactId={String(item.id)} refetch={refetch} data={item} />

            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      name: item.name,
      userId: item.userId,
      segmentId: item.segmentId,
      email: item.email,
      phone: item.phone,
      company: item.company,
      instagram: item.instagram,
      facebook: item.facebook,
      linkedin: item.linkedin,
      sankhya_status: item.sankhya_status,
      codparc: item.codparc,
      codcontato: item.codcontato,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
    });
  });

  return { columns, data: dataList };
}
