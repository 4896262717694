import React, { useRef, useState } from 'react';

import { Button, Input, Form as AntdForm, Checkbox, Select } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import { useFetch } from '../../../hooks/useFetch';

interface IFormInitialFields {
  name: string;
}

interface IFormSegment {
  formType: 'put' | 'post';
  initialFields?: any;
  refetch: () => void;
  segmentId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ formType, setModalIsOpen, refetch, segmentId, initialFields }: IFormSegment) {
  const [isLoading, setIsLoading] = useState(false);

  const { dataFetch } = useFetch<{ id: number; sankhya_user: string }[]>({ baseUrl: '/users' });

  const [selectValues, setSelectValues] = useState(null);

  const formRef = useRef<any>(null);

  console.log(initialFields);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  const [isTure, setIsTure] = useState(initialFields?.allow_auto_assign || false);
  async function handleSubmit(data: any) {
    setIsLoading(true);

    try {
      if (formType === 'post') {
        await api.post('/teams', { name: data.name, description: data.description, allow_auto_assign: isTure });
      } else {
        if (segmentId)
          await api.put(`/teams/${segmentId}`, {
            name: data.name,
            description: data.description,
            allow_auto_assign: isTure,
          });
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
    } catch (err) {
      //console.log(err);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome obrigatório' }]}>
            <Input placeholder="Nome do Departamento" />
          </AntdForm.Item>
          <AntdForm.Item label="Descrição" name="description" rules={[{ required: true, message: 'Nome obrigatório' }]}>
            <Input placeholder="Descrição" />
          </AntdForm.Item>
          <AntdForm.Item label="Agentes" name="agentes" rules={[{ required: true, message: 'Nome obrigatório' }]}>
            <Select onChange={setSelectValues} mode="multiple">
              {dataFetch?.map(item => (
                <Select.Option value={item.id}>{item?.sankhya_user || 'Usuário sem nome'}</Select.Option>
              ))}
            </Select>
          </AntdForm.Item>
          <AntdForm.Item>
            <Checkbox defaultChecked={isTure} onChange={() => setIsTure(old => !old)} />
            <span> Permitir Atribuição de Conversas Automaticamente?</span>
          </AntdForm.Item>
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
