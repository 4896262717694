import React, { useRef, useState } from 'react';

import { Button, Input, Form as AntdForm, Radio } from 'antd';
import { Select } from '../../../components/Select';
import { MultiSelect } from '../../../components/MultiSelect';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import { TextareaWithEmoji } from '../../../components/textAreaWithEmoji';
import { UploadComponent } from '../../../components/UploadComponent';
import { UploadImage } from '../../../components/Uploads/UploadsImage';

interface IFormInitialFields {
  name: string;
  message: string;
  image: string;
  file: string;
  tags: string;
  segment_id: string;
  max_per_day: string;
  start_at: string;
  delay_start: string;
  delay_end: string;
  pause_time: string;
  pause_after: string;
}

interface IFormContact {
  formType: 'put' | 'post';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  campaignWhatsId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  campaignWhatsId,
  initialFields = {
    name: '',
    segment_id: '1',
    message: '',
    file: '',
    tags: '',
    start_at: '',
    max_per_day: '',
    delay_end: '',
    delay_start: '',
    image: '',
    pause_after: '',
    pause_time: '',
  },
}: IFormContact) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([] as UploadFile[]);
  const [textareaWithEmojiValue, setTextareaWithEmojiValue] = useState('');
  const [showRadio, setShowRadio] = useState(false);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    const obj = {
      name: data.name,
      message: textareaWithEmojiValue,
      image: '',
      file: fileList[0]?.response?.data?.name,
      tags: data.tags,
      segment_id: data.segment_id,
      max_per_day: data.max_per_day,
      start_at: data.start_at,
      delay_start: data.delay_start,
      delay_end: data.delay_end,
      pause_time: data.pause_time,
      pause_after: data.pause_after,
    };

    const obj2 = {
      name: data.name,
      message: textareaWithEmojiValue,
      image: '',
      file: fileList[0]?.response?.data?.name,
      max_per_day: data.max_per_day,
      start_at: data.start_at,
      delay_start: data.delay_start,
      delay_end: data.delay_end,
      pause_time: data.pause_time,
      pause_after: data.pause_after,
    };

    try {
      if (formType === 'post') {
        await api.post('/campaigns', showRadio ? obj : obj2);
      } else {
        if (campaignWhatsId) await api.put(`/campaigns/${campaignWhatsId}`, showRadio ? obj : obj2);
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
      setFileList([]);
    } catch (err) {}

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item label="Nome (interno):" name="name" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input placeholder="Nome da campanha" />
          </AntdForm.Item>

          <AntdForm.Item label="Mensagem">
            <TextareaWithEmoji rows={8} setValue={setTextareaWithEmojiValue} value={textareaWithEmojiValue} />
          </AntdForm.Item>

          <UploadComponent maxCount={1} fileList={fileList} setFileList={setFileList} />

          <AntdForm.Item label="Mensagem" name="nana">
            <Radio checked={!showRadio} onClick={() => setShowRadio(false)} name="nana">
              Usuários do Deskio
            </Radio>
            <Radio checked={showRadio} onClick={() => setShowRadio(true)} name="nana">
              Contatos
            </Radio>
          </AntdForm.Item>

          {showRadio && (
            <>
              <Select fetchUrl="contacts" name="segment_id" title="Categoria"></Select>
              <MultiSelect fetchUrl="tags" name="tags" title="Tags"></MultiSelect>
            </>
          )}

          <AntdForm.Item
            label="Iniciar o Disparo Em:"
            name="start_at"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input type="date" />
          </AntdForm.Item>

          <AntdForm.Item
            label="Máximo de envio por dia"
            name="max_per_day"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input placeholder="Máximo por dia" />
          </AntdForm.Item>

          <AntdForm.Item label="Delay De" name="delay_start" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input placeholder="Delay De" type="number" />
          </AntdForm.Item>

          <AntdForm.Item label="Delay Até" name="delay_end" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input placeholder="Delay Até" type="number" />
          </AntdForm.Item>

          <div
            style={{
              display: 'grid',
              alignItems: 'center',
            }}
          >
            Pausar depois de X mensagens
            <AntdForm.Item name="pause_after" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input placeholder="Pausar depois de X mensagens" type="number" />
            </AntdForm.Item>
            Tempo de pausa
            <AntdForm.Item name="pause_time" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input placeholder="Tempo de pausa" type="number" />
            </AntdForm.Item>
          </div>
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
