import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';

import { Button } from '../../../../components/buttons/buttons';
import { Modal } from 'antd';
import { Form } from '../ContactForm';

interface ITableButtonEditProps {
  contactId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  userId: number;
  name: string;
  email: string;
  status: number;
  tech_name: string;
  phone_tech: number;
  email_tech: string;
  url_sankhya: string;
  user_webservice: string;
  address: string;
  number: number;
  complement: string;
  district: string;
  cep: number;
  city: string;
  uf: string;
}

export function TableButtonEdit({ contactId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="edit" size={16} />
      </Button>

      <Modal
        title="Editar dados da empresa"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          formType="put"
          contactId={contactId}
          initialFields={{
            name: data.name,
            userId: data.userId,
            email: data.email,
            address: data.address,
            cep: data.cep,
            city: data.city,
            complement: data.complement,
            district: data.district,
            email_tech: data.email_tech,
            number: data.number,
            phone_tech: data.phone_tech,
            status: data.status,
            tech_name: data.tech_name,
            uf: data.uf,
            url_sankhya: data.url_sankhya,
            user_webservice: data.user_webservice,
          }}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}
