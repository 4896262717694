import { Col, Form, Input, Modal, Row, Button as AntButton, Switch, Divider, Checkbox, Spin, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../../container/styled';
import { useAuth } from '../../hooks/useAuth';
import { useFetch } from '../../hooks/useFetch';
import { ThemeLayout } from '../../layout/themeLayout';
import FeatherIcon from 'feather-icons-react';
import { api } from '../../services/api';
import { Button } from '../../components/buttons/buttons';
import { CardWrapper } from '../contacts/styles';
interface InstanceFetchProps {
  api_docs_url: string;
  container_id: string;
  webhook_url: string;
  create_at: string;
  created_at: string;
  id: number;
  payment_status: 'PAID' | 'PENDING';
  internal_name: string;
  trial_ends_at: string | null;
  reject_call: number;
  json_docs: string;
  license_key: string;
  name: string;
  postman_api_docs_url: string;
  qrcode: null | string;
  state: string;
  reject_call_message: any;
  status_connection: string;
  updated_at: string;
  auto_read_messages: number;
  webhook_events: string;
}

export function ViewInstanceDetails() {
  const params = useParams<any>();
  const [modalLoading, setModalLoading] = useState(false);
  const [modalisopen, setmodalisopen] = useState(false);
  const { dataFetch: modalVisualityInstance, refetch } = useFetch<InstanceFetchProps>({
    baseUrl: `/containers/${params.id}`,
    isLinkProps: false,
  });
  const [reject_call, setreject_call] = useState(modalVisualityInstance?.reject_call === 1);

  const [reject_call_message, setreject_call_message] = useState<any>();



  const [auto_read_messages, setauto_read_messages] = useState(modalVisualityInstance?.auto_read_messages === 1);

  const webhook_events = modalVisualityInstance?.webhook_events
    ? Object.entries(JSON.parse(modalVisualityInstance.webhook_events))
    : [];

  const [selecteds, setSelecteds] = useState<any[]>([]);
  const toogleSelecteds = (e: string, a: boolean) => {
    if (!a) {
      setSelecteds(old => old.filter(item => item !== e));
    } else {
      setSelecteds(old => (old ? [...old, e] : [e]));
    }
  };

  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    async function opa() {
      if (modalVisualityInstance) {
        setreject_call(modalVisualityInstance?.reject_call === 1);
        Object.entries(await JSON.parse(modalVisualityInstance.webhook_events)).forEach(event => {
          if (event[1]) {
            const exists = selecteds.find(item => item === event[0]);
            if (!exists) toogleSelecteds(event[0], true);
          }
        });
      }
    }

    opa();
  }, [modalVisualityInstance]);

  console.log(modalVisualityInstance);

  const token = Cookies.get('deskioapp-front-token');

  const formRef = useRef<any>(null);

  const trad = {
    chatsSet: '(Receber lista de todas as conversas)',
    chatsUpdate: '(Informar status da conexão com whatsapp (conversas))',
    chatsDelete: '(Informar quando uma conversa é atualizadada)',
    contactsUpsert: '(Informar quando uma conversa é apagada)',
    contactsUpdate: '(Informar quando um contato é atualizado)',
    statusBroadcast: '(Notificar quando um contato publica um Status)',
    newMessage: '(Avisar quando uma mensagem é recebida)',
    sendMessage: '(Avisar quando uma mensagem é enviada)',
    sendMessageStart: '(Avisar quando um processo de envio de imagem é iniciado)',
    sebdMessageProcessing: '(Informar o processo de envio das mensagens (de 0 a 100%))',
    sendMessageCompleted: '(Avisar quando um processo de envio de imagem é finalizado)',
    sendMessageError: '(Avisar sobre erros durante o processo de envio)',
    messageDelete: '(Informar quando uma mensagem é excluída)',
    messageUpdate: '(Informar quando uma mensagem é atualizada)',
    messagesSet: '(Enviar uma lista com todas as suas mensagens antigas )',
    presenceUpdate: '(Atualizar sobre presença e ações de usuários)',
    requestPayment: '(Informar sobre requisições de pagamento)',
    sendPayment: '(Informar quando você recebe um pagamento)',
    declinePayment: '(Informar sobre rejeições de pagamentos)',
    cancelPayment: '(Informar se um pagamento foi cancelado)',
    generalError: '(Informar sobre erros gerais relacionados aos eventos)',
  };

  function closeModal() {
    setmodalisopen(false);
  }

  function openModal() {
    setmodalisopen(true);
  }



  async function handleEditInstance(data: any) {
    setModalLoading(true);
    const resCheckeds = webhook_events.map(e => {
      if (selecteds.includes(e[0])) {
        return [e[0], true];
      } else {
        return [e[0], false];
      }
    });

    await api.put(
      '/containers/' + data.id,
      {
        ...data,
        reject_call: reject_call ? 1 : 0,
        //reject_call_message: reject_call_message,
        auto_read_messages: auto_read_messages ? 1 : 0,
        webhook_events: Object.fromEntries(resCheckeds),
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    await refetch();
    closeModal();
    formRef.current.resetFields();
    setModalLoading(false);
  }

  const [isGenerateQrCodeModal, setIsGenerateQrCodeModal] = useState({ name: '', licenceKey: '', id: 0, state: '' });
  const [qrCodeConected, setQrCodeConected] = useState(false);

  const [textQrModal, setTextQrModal] = useState('Clique no Botão abaixo para Gerar o QRCode');
  function openGenerateQrCodeModal(name: any, licenceKey: any, id: any, state: string) {
    setIsGenerateQrCodeModal({ name, licenceKey, id, state });
    setQrCodeConected(false);
  }

  async function handleRedirectPay(id: string) {
    const { data } = await api.get(`/container/pay-subscription/${id}`);

    window.open(data.url, '_blank');
  }

  const [isGeneratingQrCode, setIsGeneratingQrCode] = useState(false);

  function closeGenerateQrCodeModal() {
    setIsGenerateQrCodeModal({ name: '', licenceKey: '', id: 0, state: '' });
    setIsGeneratingQrCode(false);
    setTextQrModal('Clique no Botão abaixo para Gerar o QRCode');
  }

  useEffect(() => {
    let timer: any = null;

    clearTimeout(timer);
    if (modalVisualityInstance?.status_connection !== 'open' && !!isGenerateQrCodeModal.name) {
      timer = setTimeout(() => refetch(), 5000);
    }
  }, [isGenerateQrCodeModal.name, modalVisualityInstance]);

  async function generateQrCode() {
    setIsGeneratingQrCode(true);
    try {
      const { data } = await axios.get(
        `https://api.cloudzapi.com/${isGenerateQrCodeModal.name}/instance/exportQrcodeBase64`,
        {
          headers: {
            apiKey: isGenerateQrCodeModal.licenceKey,
          },
        },
      );
      setQrCode(data.base64);

      setTimeout(() => {
        setQrCode('');
        setTextQrModal('Seu QrCode expirou, gere outro novamente.');
      }, 20000);
    } catch (err) {
      const error: any = err;
      toast.error(error.response.data.message);
    }
    setIsGeneratingQrCode(false);
  }

  function learnRegExp(value: string) {
    const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return urlPattern.test(value);
  }

  useEffect(() => {
    if (modalVisualityInstance?.status_connection === 'open') {
      setQrCodeConected(true);
    }
  }, [modalVisualityInstance]);

  const history = useHistory();
  const { user } = useAuth();

  return (
    <ThemeLayout>
      <PageHeader
        title={'Instancia:' + ` ${modalVisualityInstance?.internal_name}`}
        buttons={[
          <AntButton onClick={() => history.push('/instances')}>
            <FeatherIcon icon="arrow-left" size={14} />
            Voltar
          </AntButton>,
          <>
            {modalVisualityInstance?.status_connection.toUpperCase() === 'CLOSE' && (
              <AntButton
                onClick={() =>
                  openGenerateQrCodeModal(
                    modalVisualityInstance?.name,
                    modalVisualityInstance?.license_key,
                    modalVisualityInstance?.id,
                    modalVisualityInstance.state,
                  )
                }
              >
                <FeatherIcon icon="maximize" size={14} />
                Ler QRCode
              </AntButton>
            )}
          </>,
          <AntButton
            disabled={modalVisualityInstance?.state !== 'RUNNING'}
            onClick={openModal}
            type="primary"
            size="middle"
            htmlType="button"
          >
            <FeatherIcon icon="edit" size={14} />
            Editar Instância
          </AntButton>,
        ]}
      />
      <Main>
        {modalVisualityInstance && (
          <Row
            gutter={40}
            style={{
              padding: '1rem',
              marginRight: '15px',
              marginLeft: '15px',
              background: '#FFF',
              borderRadius: '0.5rem',
            }}
          >
            <Col
              xl={12}
              md={24}
              style={{
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              <CardWrapper
                style={{
                  marginTop: '1rem',
                  margin: '20px',
                }}
                type="inner"
                title="Detalhes da Instância"
              >
                <div style={{ marginBottom: '1rem' }}>
                  <strong>Nome da instância:</strong> {modalVisualityInstance?.internal_name}
                </div>
                <div style={{ marginBottom: '1rem' }}>
                  <strong>ID:</strong> {modalVisualityInstance?.name}
                </div>
                <div style={{ marginBottom: '1rem' }}>
                  <strong>Criada Em:</strong> {new Date(modalVisualityInstance?.created_at || '').toLocaleDateString()}
                </div>
                <div style={{ marginBottom: '1rem' }}>
                  <strong>Status Instância:</strong>{' '}
                  <span
                    style={{
                      color: modalVisualityInstance?.state === 'RUNNING' ? '#20C9A5' : '#ff4d4f',
                      background: modalVisualityInstance?.state === 'RUNNING' ? '#E2F4F0' : '#ff4d4f15',
                      border: `0px solid ${'#E2F4F0'}`,
                      padding: '3px 10px',
                      borderRadius: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxSizing: 'border-box',
                      fontVariant: 'tabular-nums',
                      listStyle: 'none',
                      fontFeatureSettings: 'tnum',
                      display: 'inline-block',
                      height: 'auto',
                      fontSize: '12px',
                      lineHeight: '20px',
                      whiteSpace: 'nowrap',
                      transition: 'all 0.3s',
                    }}
                  >
                    {modalVisualityInstance?.state.toUpperCase() === 'RUNNING' && 'Ativa'}
                    {modalVisualityInstance?.state.toUpperCase() === 'PAUSED' && 'Pausada'}
                    {modalVisualityInstance?.state.toUpperCase() === 'STOPPED' && 'Parada'}
                  </span>
                </div>

                <div style={{ marginBottom: '1rem' }}>
                  <strong>Status Whatsapp:</strong>{' '}
                  <span
                    style={{
                      color: modalVisualityInstance?.status_connection !== 'close' ? '#20C9A5' : '#ff4d4f',
                      background: modalVisualityInstance?.status_connection !== 'close' ? '#E2F4F0' : '#ff4d4f15',
                      border: `0px solid ${'#E2F4F0'}`,
                      padding: '3px 10px',
                      borderRadius: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxSizing: 'border-box',
                      fontVariant: 'tabular-nums',
                      listStyle: 'none',
                      fontFeatureSettings: 'tnum',
                      display: 'inline-block',
                      height: 'auto',
                      fontSize: '12px',
                      lineHeight: '20px',
                      whiteSpace: 'nowrap',
                      transition: 'all 0.3s',
                    }}
                  >
                    {modalVisualityInstance?.status_connection.toUpperCase() === 'OPEN' && 'Conectado'}
                    {modalVisualityInstance?.status_connection.toUpperCase() === 'CLOSE' && 'Desconectado'}
                    {modalVisualityInstance?.status_connection.toUpperCase() === 'CONNECTING' && 'Conectando'}
                    {modalVisualityInstance?.status_connection.toUpperCase() === 'REFUSED' && 'Perdido'}
                  </span>
                </div>

                {user?.plan_type.toString() !== '1' && (
                  <p>
                    {modalVisualityInstance.trial_ends_at ? (
                      <>
                        <strong>Trial Expira em:</strong>{' '}
                        <span
                          style={{
                            color: new Date(modalVisualityInstance.trial_ends_at) >= new Date() ? '#20C9A5' : '#ff4d4f',
                            background:
                              new Date(modalVisualityInstance.trial_ends_at) >= new Date() ? '#E2F4F0' : '#ff4d4f15',
                            border: `0px solid ${'#E2F4F0'}`,
                            padding: '3px 10px',
                            borderRadius: 40,
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            boxSizing: 'border-box',
                            fontVariant: 'tabular-nums',
                            listStyle: 'none',
                            fontFeatureSettings: 'tnum',
                            display: 'inline-block',
                            height: 'auto',
                            fontSize: '14px',
                            lineHeight: '20px',
                            whiteSpace: 'nowrap',
                            transition: 'all 0.3s',
                            marginRight: '30px',
                          }}
                        >
                          {new Date(modalVisualityInstance.trial_ends_at) >= new Date()
                            ? new Date(modalVisualityInstance.trial_ends_at).toLocaleDateString()
                            : 'Expirado'}
                        </span>
                      </>
                    ) : (
                      <>
                        {' '}
                        <strong> Pagamento:</strong>{' '}
                        <span
                          style={{
                            color: modalVisualityInstance?.payment_status !== 'PENDING' ? '#20C9A5' : '#ff4d4f',
                            background: modalVisualityInstance?.payment_status !== 'PENDING' ? '#E2F4F0' : '#ff4d4f15',
                            border: `0px solid ${'#E2F4F0'}`,
                            padding: '3px 10px',
                            borderRadius: 40,
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            boxSizing: 'border-box',
                            fontVariant: 'tabular-nums',
                            listStyle: 'none',
                            fontFeatureSettings: 'tnum',
                            display: 'inline-block',
                            height: 'auto',
                            fontSize: '14px',
                            lineHeight: '20px',
                            whiteSpace: 'nowrap',
                            transition: 'all 0.3s',
                            marginRight: '30px',
                          }}
                        >
                          {modalVisualityInstance?.payment_status === 'PAID' ? 'Pago' : 'Pendente de Pagamento'}
                        </span>
                      </>
                    )}
                    {user?.plan_type.toString() !== '1' && modalVisualityInstance?.payment_status === 'PENDING' && (
                      <Tooltip title="Pagar instancia">
                        <Button type="primary" onClick={() => handleRedirectPay(String(modalVisualityInstance.id))}>
                          Assinar
                        </Button>
                      </Tooltip>
                    )}
                  </p>
                )}

                <p>
                  <strong>Rejeitar Chamadas Automaticamente: </strong>
                  {modalVisualityInstance?.reject_call === 1 ? 'Sim' : 'não'}
                </p>
                <p>
                  <strong>Ler mensagens Automaticamente: </strong>
                  {modalVisualityInstance?.auto_read_messages === 1 ? 'Sim' : 'não'}
                </p>
              </CardWrapper>
            </Col>
            <Col
              xl={12}
              md={24}
              style={{
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              {modalVisualityInstance?.state !== 'RUNNING' ? (
                <CardWrapper
                  style={{
                    marginTop: '1rem',
                    margin: '20px',
                  }}
                  type="inner"
                  title="Documentação e API"
                >
                  <div
                    style={{
                      height: '20rem',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    Atenção! Sua instância está pausada! Regularize o pagamento pendente para mantê-la ativa e poder
                    visualizar os dados de documentação e chaves de API.
                  </div>
                </CardWrapper>
              ) : (
                <CardWrapper
                  style={{
                    marginTop: '1rem',
                    margin: '20px',
                  }}
                  type="inner"
                  title="Documentação"
                >
                  <Form.Item label="Api Key:" style={{ marginTop: '1rem' }} name="licence_key">
                    <Input
                      readOnly
                      style={{ backgroundColor: '#f3f7f6' }}
                      type="text"
                      defaultValue={modalVisualityInstance?.license_key}
                    />
                  </Form.Item>

                  <Form.Item label="URL para Webhook:" name="webhook_url">
                    <Input
                      style={{ backgroundColor: '#f3f7f6' }}
                      readOnly
                      type="text"
                      defaultValue={modalVisualityInstance?.webhook_url}
                    />
                  </Form.Item>

                  <a href={modalVisualityInstance?.api_docs_url} target="_blank">
                    <Form.Item label="URL da Documentação:" name="api_docs_url">
                      <Input
                        style={{ cursor: 'pointer', backgroundColor: '#f3f7f6' }}
                        readOnly
                        type="text"
                        defaultValue={modalVisualityInstance?.api_docs_url}
                      />
                    </Form.Item>
                  </a>
                  <a href={modalVisualityInstance?.json_docs} target="_blank">
                    <Form.Item label="Documentação Postman Json:" name="json_docs">
                      <Input
                        style={{ cursor: 'pointer', backgroundColor: '#f3f7f6' }}
                        readOnly
                        type="text"
                        defaultValue={modalVisualityInstance?.json_docs}
                      />
                    </Form.Item>
                  </a>
                  <a href={modalVisualityInstance?.postman_api_docs_url} target="_blank">
                    <Form.Item label="Documentação Postman Online:" name="postman_api_docs_url">
                      <Input
                        style={{ cursor: 'pointer', backgroundColor: '#f3f7f6' }}
                        readOnly
                        type="text"
                        defaultValue={modalVisualityInstance?.postman_api_docs_url}
                      />
                    </Form.Item>
                  </a>
                </CardWrapper>
              )}
            </Col>
          </Row>
        )}
      </Main>

      {modalVisualityInstance && (
        <Modal
          visible={modalisopen}
          width={1200}
          onCancel={closeModal}
          bodyStyle={{ padding: 0 }}
          footer={null}
          closable={false}
        >
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'center',
            }}
            layout="vertical"
            onFinish={handleEditInstance}
            ref={formRef}
            initialValues={{
              id: modalVisualityInstance.id,
              internal_name: modalVisualityInstance.internal_name,
              webhook_url: modalVisualityInstance.webhook_url,
              reject_call_message: modalVisualityInstance.reject_call_message,
            }}
          >
            <Row gutter={40}>
              <Col
                xl={12}
                md={24}
                style={{
                  paddingLeft: '30px',
                  paddingRight: '0px',
                }}
              >
                <CardWrapper
                  style={{
                    marginTop: '1rem',
                    margin: '20px',
                  }}
                  type="inner"
                  title={`Editar Instância - ${modalVisualityInstance?.internal_name}`}
                >
                  <Form.Item
                    label="Nome (nome interno da Instância):"
                    name="internal_name"
                    rules={[{ required: true, message: 'Nome obrigatório' }]}
                  >
                    <Input type="text" />
                  </Form.Item>

                  <div style={{ position: 'relative' }}>
                    <small style={{ position: 'absolute', top: '2rem' }}>
                      (OBS: Você poderá adicionar a URL para Webhook posteriormente)
                    </small>

                    <Form.Item
                      label="URL para Webhook:"
                      rules={[
                        () => ({
                          async validator(_, value: string) {
                            if (learnRegExp(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Deve ser uma URL.'));
                          },
                        }),
                      ]}
                      name="webhook_url"
                    >
                      <Input
                        type="text"
                        style={{ marginTop: '2rem' }}
                        defaultValue={modalVisualityInstance?.webhook_url}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item style={{ height: 0 }} label="" name="id">
                    <Input type="hidden" />
                  </Form.Item>

                  <Form.Item style={{ margin: 0 }}>
                    <span>Rejeitar Chamadas Automaticamente: </span>
                    <Switch
                      onChange={e => setreject_call(e)}
                      defaultChecked={modalVisualityInstance?.reject_call === 1}
                    />
                  </Form.Item>

                  {reject_call && (
                    
                     
                      <Form.Item label="Mensagem que será enviada quando alguém tentar fazer uma chamada:" name="reject_call_message">
                     
                     
                        <TextArea defaultValue={modalVisualityInstance.reject_call_message}  onChange={e => setreject_call_message(e)}  />
                      </Form.Item>
                    
                  )}

                  <Form.Item style={{ margin: 0 }}>
                    <span>Ler mensagens Automaticamente: </span>
                    <Switch
                      onChange={e => setauto_read_messages(e)}
                      defaultChecked={modalVisualityInstance?.auto_read_messages === 1}
                    />
                  </Form.Item>

                  <footer
                    style={{
                      gap: '5px',
                      padding: '20px',
                      display: 'flex',
                      justifyContent: 'end',
                      borderTop: '1px solid #f0f0f0',
                    }}
                  >
                    <AntButton type="default" htmlType="button" onClick={closeModal}>
                      Cancelar
                    </AntButton>
                    <AntButton disabled={modalVisualityInstance.state !== 'RUNNING'} type="primary" htmlType="submit">
                      {modalLoading ? 'Salvando...' : 'Salvar'}
                    </AntButton>
                  </footer>
                </CardWrapper>
              </Col>
              <Col
                xl={12}
                md={24}
                style={{
                  paddingLeft: '0px',
                  paddingRight: '30px',
                }}
              >
                <CardWrapper
                  style={{
                    marginTop: '1rem',
                    margin: '20px',
                  }}
                  type="inner"
                  title={`Configurar Eventos de Webhooks`}
                >
                  {webhook_events.map(item => (
                    <Form.Item style={{ margin: 0 }}>
                      <span>{item[0]}: </span>
                      <Switch onChange={e => toogleSelecteds(item[0], e)} defaultChecked={item[1] === true} />
                      <div style={{ marginBottom: '20px' }}>
                        <small>{trad[item[0]]}</small>
                      </div>
                    </Form.Item>
                  ))}
                </CardWrapper>
              </Col>
            </Row>

            <footer
              style={{
                gap: '5px',
                padding: '20px',
                display: 'flex',
                justifyContent: 'end',
                borderTop: '1px solid #f0f0f0',
              }}
            >
              <AntButton type="default" htmlType="button" onClick={closeModal}>
                Cancelar
              </AntButton>
              <AntButton disabled={modalVisualityInstance.state !== 'RUNNING'} type="primary" htmlType="submit">
                {modalLoading ? 'Salvando...' : 'Salvar'}
              </AntButton>
            </footer>
          </Form>
        </Modal>
      )}

      <Modal
        visible={!!isGenerateQrCodeModal.name}
        okText="Gerar QRCode"
        cancelText="Cancelar"
        onOk={() => isGenerateQrCodeModal.state === 'RUNNING' && generateQrCode()}
        footer={isGenerateQrCodeModal.state === 'RUNNING' ? (qrCodeConected ? null : undefined) : null}
        onCancel={closeGenerateQrCodeModal}
      >
        {isGenerateQrCodeModal.state === 'RUNNING' && isGeneratingQrCode && (
          <div
            style={{
              height: '20rem',
              width: '100%',
              display: 'flex',
              marginTop: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spin />
          </div>
        )}

        {isGenerateQrCodeModal.state !== 'RUNNING' && (
          <div
            style={{
              height: '20rem',
              width: '100%',
              display: 'flex',
              marginTop: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Não é possível gerar o QR code, pois sua instância está pausada! Clique no botão "Assinar" para regularizar
            o pagamento e, assim, conseguir se conectar ao Whatsapp.
          </div>
        )}

        {isGenerateQrCodeModal.state === 'RUNNING' && !isGeneratingQrCode && !qrCode && !qrCodeConected && (
          <div
            style={{
              height: '20rem',
              width: '100%',
              display: 'flex',
              marginTop: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {textQrModal}
          </div>
        )}

        {isGenerateQrCodeModal.state === 'RUNNING' && qrCodeConected && (
          <div
            style={{
              height: '20rem',
              width: '100%',
              display: 'flex',
              marginTop: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            QRCode Conectado
          </div>
        )}

        {isGenerateQrCodeModal.state === 'RUNNING' && !isGeneratingQrCode && qrCode && !qrCodeConected && (
          <div
            style={{
              height: '20rem',
              width: '100%',
              display: 'flex',
              marginTop: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span>Abra o aplicativo do whatsapp e leia o QRCode abaixo para se conectar a esta instância</span>
            <img src={qrCode} />
          </div>
        )}
      </Modal>
    </ThemeLayout>
  );
}
