import Cookie from 'js-cookie';
import toast from 'react-hot-toast';

export function treatment(data: any) {
  if (!data?.success && data?.error === 90) {
    Cookie.remove('deskioapp-front-token');
    Cookie.remove('strutural-jsessionID');
    window.location.reload();
  }

  if (!data?.success && data?.error === 50) {
    toast.error(data?.erro || 'Deu ruim');
  }
}
