import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { Button, Input, Form as AntdForm, Select, Switch, Divider } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { useAuth } from '../../../hooks/useAuth';

type IFormInitialFields = any;

interface IFormComponent {
  formType: 'put' | 'post';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  contactId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ formType, setModalIsOpen, refetch, contactId, initialFields = null }: IFormComponent) {
  const isEdit = !!initialFields;

  const initialChecks = JSON.parse(initialFields?.permissions ?? '[]');

  const [isLoading, setIsLoading] = useState(false);
  const [checks, setCheks] = useState<any>(() => {
    const itens = initialChecks.map(item => [item, true]);
    return Object.fromEntries(itens);
  });

  async function changeCheck(check: string) {
    const isChecked = !!checks[check] && checks[check] === true;

    if (isChecked) {
      setCheks((old: any) => ({ ...old, [check]: false }));
      return;
    }

    await api
      .post('/users/verify-permission', { user_id: initialFields?.user_id || 0, permission: check })
      .then(res => {
        if (res.data.status === false) {
          toast.error(res.data.message || 'Não está disponivel para você');
          setCheks((old: any) => ({ ...old, [check]: false }));
        } else {
          setCheks((old: any) => ({ ...old, [check]: true }));
        }
      })
      .catch(err => {
        console.log(err);
        toast.error('Algo deu errado');
        setCheks((old: any) => ({ ...old, [check]: false }));
      });
  }

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }
  async function handleSubmit(data: any) {
    setIsLoading(true);

    try {
      if (formType === 'post') {
        await api.post('/users', { ...data, checks });
        setModalIsOpen(false);
        toast.success('Criado com sucesso!');
      } else {
        if (contactId) {
          await api.put(`users/${contactId}`, { ...data, checks });
          toast.success('Atualizado com sucesso!');
          setModalIsOpen(false);
        }
      }
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        onFinish={handleSubmit}
        defaultValue={initialFields}
        initialValues={initialFields}
        layout="vertical"
        style={{ padding: 30 }}
      >
        <CardWrapper type="inner" title="Cadastro de Usuário">
          <AntdForm.Item
            label="Sankhya ID (email)"
            name="sankhya_id"
            rules={[
              {
                type: 'email',
                message: 'Digite um email válido!',
              },
              {
                required: true,
                message: 'Por favor, insira seu e-mail!',
              },
            ]}
          >
            <Input />
          </AntdForm.Item>

          {isEdit && (
            <AntdForm.Item label="Nome Usuário Sankhya" name="sankhya_user">
              <Input disabled />
            </AntdForm.Item>
          )}
        </CardWrapper>
        <br></br>
        <CardWrapper type="inner" title="Permissões de Acesso">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem 0' }}>
            <label>
              Módulo de<strong> Logística</strong>
            </label>
            <Switch checked={checks?.FILANOTAS} onChange={() => changeCheck('FILANOTAS')} />
          </div>

          {checks?.FILANOTAS && (
            <AntdForm.Item label="Cód. Parceiro Motorista" name="cod_parceiro_motorista">
              <Input />
            </AntdForm.Item>
          )}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem 0' }}>
            <label>
              Módulo <strong>Fila de Conferência</strong>
            </label>
            <Switch checked={checks?.FILACONFERENCIA} onChange={() => changeCheck('FILACONFERENCIA')} />
          </div>

          {checks?.FILACONFERENCIA && (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem 0' }}
            >
              <label>Gerente de Conferência</label>
              <Switch checked={checks?.GERCONF} onChange={() => changeCheck('GERCONF')} />
            </div>
          )}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem 0' }}>
            <label>
              Módulo <strong>Força de Vendas</strong>
            </label>
            <Switch checked={checks?.VENDAS} onChange={() => changeCheck('VENDAS')} />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem 0' }}>
            <label>
              Módulo <strong>Multi-Atendimento</strong>{' '}
            </label>
            <Switch checked={checks?.MULTIATENDIMENTO} onChange={() => changeCheck('MULTIATENDIMENTO')} />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem 0' }}>
            <label>
              Módulo <strong>Automações de Marketing</strong>{' '}
            </label>
            <Switch checked={checks?.MARKETING} onChange={() => changeCheck('MARKETING')} />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem 0' }}>
            <label>
              <strong>Administrador</strong>
            </label>
            <Switch checked={checks?.ADMIN} onChange={() => changeCheck('ADMIN')} />
          </div>
        </CardWrapper>
        <footer style={{ padding: '20px 30px' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
