import React, { useState } from 'react';
import { Select, Form as AntdForm } from 'antd';
import Cookie from 'js-cookie';
import axios from 'axios';
import styled from 'styled-components';

interface SelectParProps {
  defaultValue: string;
  defaultOption: Array<string>;
}

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function SelectPar({ defaultOption = [], defaultValue }: SelectParProps) {
  const [options, setOptions] = useState<any[]>([defaultOption]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookie.get('deskioapp-front-token');

  async function get(search: string) {
    setIsLoading(true);
    const res = await axios.post(
      'https://apiapp.deskio.com.br/api/v1/quotes/search-parc',
      {
        term: search,
      },
      {
        headers: {
          authorization: `bearer ${token?.replaceAll('"', '')}`,
        },
      },
    );

    setOptions(Array.isArray(res.data) ? res.data : [res.data]);
    setIsLoading(false);
  }

  let timer: NodeJS.Timeout;
  function debounce(event: string) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      get(event);
    }, 1000);
  }

  const fakeOptionsDataBase = [
    { opição: 'opição de teste para o pipeline category' },
    { opição: 'opição de teste para o pipeline category' },
    { opição: 'opição de teste para o pipeline category' },
  ];

  return (
    <AntdForm.Item
      label="Parceiro/Cliente"
      name="CODPARC"
      style={{ width: '100%' }}
      rules={[{ required: true, message: `Campo obrigatório` }]}
    >
      <AntdSelect
        showSearch
        filterOption={false}
        defaultValue={defaultValue}
        placeholder="Busque um parceiro"
        onSearch={e => debounce(e)}
        loading={isLoading}
      >
        {fakeOptionsDataBase?.map(option => (
          <AntdSelect.Option>{option.opição}</AntdSelect.Option>
        ))}
      </AntdSelect>
    </AntdForm.Item>
  );
}
