import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.35rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f8f9fb;
  }

  > p {
    margin: 0;
    margin-left: 1rem;
    line-height: 2.75rem;
    font-weight: 600;
    font-size: 0.9rem;

    color: #424666;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 3rem;
  }

  > div {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0 1rem;
    overflow: auto;
    gap: 0.25rem;
  }

  /* section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.25rem;

    width: 12rem;
  } */
`;
