import React from 'react';

interface IMenuItemApearProps {
  html: JSX.Element;
  permission?: string;
  model?: string;
  permissions?: string[];
  models: {
    enable_search_products: boolean;
    enable_invoicesqueue: boolean;
    enable_conference: boolean;
    enable_quotes: boolean;
    enable_omnichannel: boolean;
  };
}

export function menuItemApear(props: IMenuItemApearProps) {
  if (!props.permissions) return <></>;
  if (!props.models) return <></>;

  if (!props.model && !props.permission) {
    return props.html;
  }

  if (props.model && props.permission) {
    if (props.models[props.model] === true && props.permissions.includes(props.permission)) return props.html;
    else return <></>;
  }

  if (props.model && props.models[props.model] === true) {
    return props.html;
  }

  if (props.permission && props.permissions.includes(props.permission)) {
    return props.html;
  }

  return <></>;
}
