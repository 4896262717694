import React from 'react';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useFetch } from '../../../hooks/useFetch';
import { Container } from './styles';
import { TableWrapper } from '../../../container/styled';
import { useHistory } from 'react-router-dom';

export function TableComponent({
  tipo,
  nunota,
  close,
  tipoName,
}: {
  tipo: number;
  nunota: number;
  tipoName: string;
  close: () => void;
}) {
  const { dataFetch, isFetching } = useFetch<any>({ baseUrl: `/quotes/${nunota}`, isLinkProps: false });
  const history = useHistory();
  const columns: ColumnsType<any> = [
    {
      title: 'Cod. Local',
      dataIndex: 'codLocal',
    },
    {
      title: 'Cod. Prod.',
      dataIndex: 'codProd',
    },
    {
      title: 'Cod. Vol.',
      dataIndex: 'codVol',
    },
    {
      title: 'Desc. Local',
      dataIndex: 'descLocal',
    },
    {
      title: 'Produto',
      dataIndex: 'descProd',
    },
    {
      title: 'Desconto',
      dataIndex: 'desconto',
    },
    {
      title: 'ICMS',
      dataIndex: 'icms',
    },
    {
      title: 'NCM',
      dataIndex: 'ncm',
    },
    {
      title: 'Nº Pedido',
      dataIndex: 'nuNota',
    },
    {
      title: 'Qtd',
      dataIndex: 'qtd',
    },
    {
      title: 'Sequência',
      dataIndex: 'sequencia',
    },
    {
      title: 'Valor Unitário',
      dataIndex: 'valorUnitario',
    },
  ];

  return (
    <Container>
      <div>
        <p style={{ margin: 0 }}>
          {tipoName}: {nunota}
        </p>
        <Button
          className="closed"
          onClick={() => history.push('/admin/orcamento/' + nunota + '?type=' + tipo)}
          type="primary"
        >
          Abrir {tipoName}
        </Button>

        <Button onClick={close} type="primary" danger>
          Fechar
        </Button>
      </div>
      <TableWrapper className="table-order table-responsive">
        <Table dataSource={dataFetch?.data} loading={isFetching} columns={columns} />
      </TableWrapper>
    </Container>
  );
}
