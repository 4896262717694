import React, { useEffect, useState } from 'react';
import { ThemeLayout } from '../../layout/themeLayout';
import { Main } from '../../container/styled';

import { LeftMenu } from './components/LeftMenu';
import { MyAccount } from './components/MyAccount';
import { Password } from './components/Password';
import { MyPlan } from './components/MyPlan';
import { History } from './components/History';
import { BillingInformation } from './components/BillingInformation';
import { useFetch } from '../../hooks/useFetch';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import Alert from '../../components/alerts/alerts';
import { useAuth } from '../../hooks/useAuth';
import { Modules } from './components/Modules';
import { Plans } from './components/Plans';

interface fetchProps {
  billing_information: null;
  cnpj: string;
  email: string;
  expire_at: string;
  name: string;
  social_name: string;
  plan_id: 1;
  plan_qty: string;
  prices: any;
  plan_name: string;
  whatsapp_contact: null | string;
  whatsapp_notifications: null | string;
}

export function ProfilePage() {
  const params = useParams<any>();
  const [pageNumber, setPageNumber] = useState(Number(params?.inititial) || 0);

  useEffect(() => {
    setPageNumber(Number(params?.inititial) || 0);
  }, [params]);

  const { dataFetch, isFetching, refetch } = useFetch<fetchProps>({
    baseUrl: '/accounts/account-info',
    isLinkProps: false,
  });

  const isExpirado = new Date(dataFetch?.expire_at || '') < new Date();
  const isCancelado = !dataFetch?.plan_name;

  const { user } = useAuth();

  return (
    <ThemeLayout>
      {user?.plan_type === 1 && (
        <>
          {isExpirado && !isCancelado && user?.plan_type === 1 && (
            <Alert type="error" message="Erro" description="Seu plano expirou" />
          )}
          {isCancelado && !!dataFetch?.plan_name && user?.plan_type === 1 && (
            <Alert type="error" message="erro" description="Seu plano foi cancelado" />
          )}
        </>
      )}
      <div
        style={{
          display: 'flex',
          minHeight: '3rem',
          alignItems: 'center',
          justifyContent: 'start',
          flexWrap: 'wrap',
          padding: '2rem 2rem',
        }}
      >
        <LeftMenu numberPage={pageNumber} setNumberPage={setPageNumber} />
      </div>{' '}
      <Main>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'start',
            flexDirection: 'row',
            gap: '1rem',
          }}
        >
          {isFetching && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                height: '15rem',
              }}
            >
              <Spin />
            </div>
          )}

          {pageNumber === 0 && dataFetch && <MyAccount refetch={refetch} data={dataFetch} />}
          {pageNumber === 1 && dataFetch && <Password />}
          {pageNumber === 2 && dataFetch && <MyPlan data={dataFetch} />}
          {pageNumber === 3 && dataFetch && <History />}
          {pageNumber === 4 && dataFetch && (
            <BillingInformation
              refetch={refetch}
              data={{
                social_name: dataFetch?.social_name,
                cnpj: dataFetch.cnpj,
                billing_information: dataFetch?.billing_information,
              }}
            />
          )}
          {pageNumber === 5 && dataFetch && <Modules data={dataFetch} />}
          {pageNumber === 6 && dataFetch && <Plans />}
        </div>
      </Main>
    </ThemeLayout>
  );
}
