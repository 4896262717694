import styled from 'styled-components';
export const Container = styled.div`
  button {
    &:hover {
      cursor: pointer;
      filter: brightness(0.95);
    }
    &:disabled {
      filter: brightness(0.95);
      cursor: not-allowed;
    }
  }
`;
