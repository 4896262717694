import React, { useRef, useState } from 'react';

import { Button, Input, Form as AntdForm, Select as AntdSelect } from 'antd';
import { Select } from '../../../components/Select';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadComponent } from '../../../components/UploadComponent';
import { TextareaWithEmoji } from '../../../components/textAreaWithEmoji';

interface IFormInitialFields {
  title: string;
  userId: number;
  segmentId: number;
  status: number;
  type: string;
  tag: string;
  content: string;
  media: string;
  send_datetime: string;
}

interface IFormContact {
  formType: 'put' | 'post';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  contactId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  contactId,
  initialFields = {
    title: '',
    userId: 1,
    segmentId: 1,
    type: '',
    tag: '',
    content: '',
    media: '',
    send_datetime: '',
    status: 1,
  },
}: IFormContact) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([] as UploadFile[]);
  const [textareaWithEmojiValue, setTextareaWithEmojiValue] = useState('shvbhjdbsvh');

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    try {
      if (formType === 'post') {
        await api.post('/campaigns', {
          title: data.title,
          userId: data.userId,
          segmentId: data.segmentId,
          type: data.type,
          content: data.content,
          media: data.media,
          send_datetime: data.send_datetime,
          status: data.status,
        });
      } else {
        if (contactId) await api.put(`/campaigns/${contactId}`, { name: data.title });
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
      setFileList([]);
    } catch (err) {}

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item label="Título" name="title" rules={[{ required: true, message: 'Título é obrigatório' }]}>
            <Input placeholder="Título" />
          </AntdForm.Item>
          <AntdForm.Item label="Tipo" rules={[{ required: true, message: 'tipo é obrigatório' }]}>
            <AntdSelect placeholder="Tipo" defaultValue={'Usuários'}>
              <AntdSelect.Option value={'Usuários'}> Usuários </AntdSelect.Option>
              <AntdSelect.Option value={'Contatos'}> Contatos </AntdSelect.Option>
            </AntdSelect>
          </AntdForm.Item>

          <AntdForm.Item name="tag">
            <Select name="tag" title="Tag" fetchUrl="campaigns" />
          </AntdForm.Item>

          <AntdForm.Item label="Texto" name="campaigns" rules={[{ required: true, message: 'Texto é obrigatório' }]}>
            <TextareaWithEmoji rows={8} setValue={setTextareaWithEmojiValue} value={textareaWithEmojiValue} />
          </AntdForm.Item>

          <AntdForm.Item label="Mídia" name="media" rules={[{ required: true, message: 'Mídia é obrigatória' }]}>
            <UploadComponent fileList={fileList} setFileList={setFileList} />
          </AntdForm.Item>

          <AntdForm.Item name="send_datetime" label="Data" rules={[{ required: true, message: 'Data é obrigatória' }]}>
            <Input type="datetime-local" placeholder="Data" />
          </AntdForm.Item>
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
