import { Button, Form, Input, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';

import styled from 'styled-components';
import { api } from '../../../../services/api';
import { Container } from './styles';

interface Props {
  data: {
    social_name?: string;
    cnpj?: string;
    billing_information?: any;
  };
  refetch: () => Promise<void>;
}

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    > span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function BillingInformation({ data }: Props) {
  const [saving, setSaving] = useState(false);

  const [countries, setCountries] = useState<{ nome: string; id: { M49: string; 'ISO-ALPHA-2': string } }[]>([]);
  const [states, setStates] = useState<{ nome: string; id: number; sigla: string }[]>([]);
  const [selectedState, setSelectedState] = useState(data?.billing_information?.state || '');
  const [cities, setCities] = useState<{ id: number; nome: string }[]>([]);
  const [pessoaJuridica, setPessoaJuridica] = useState('nao');
  const [selectedCountry, setSelectedCountry] = useState(data?.billing_information?.country || '');
  useEffect(() => {
    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/paises')
      .then(response => response.json())
      .then(response => setCountries(response));

    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(response => response.json())
      .then(response => setStates(response));
  }, []);

  useEffect(() => {
    const findStateSigla = states.find(item => item.nome === selectedState)?.sigla;

    if (selectedState && findStateSigla) {
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${findStateSigla}/municipios`)
        .then(response => response.json())
        .then(response => setCities(response));
    }
  }, [states, selectedState]);

  function onChangeSelectState(e: any) {
    setSelectedState(e);
  }

  function submit(formData: any) {
    setSaving(true);

    const data = {
      social_name: formData.name,
      cnpj: formData.cnpj,
      billing_information: {
        address: formData.address,
        postal_code: formData.postal_code,
        city: formData.city,
        complement: formData.complement,
        country: formData.country,
        cpf: formData.cpf,
        district: formData.district,
        number: formData.number,
        state: formData.state,
      },
    };

    api
      .post('/accounts/update-info', data)
      .then(() => toast.success('Salvo com sucesso'))
      .catch(() => toast.error('Não voi possivel salvar'))
      .finally(() => setSaving(false));
  }

  const changePessoa = (e: any) => {
    setPessoaJuridica(e);
  };

  console.log(data);
  return (
    <Container>
      <header>
        <h1>Informações de cobrança</h1>
      </header>

      <main>
        <Form
          layout="vertical"
          onFinish={submit}
          initialValues={{
            address: data?.billing_information?.address,
            postal_code: data?.billing_information?.postal_code,
            city: data?.billing_information?.city,
            complement: data?.billing_information?.complement,
            country: data?.billing_information?.country,
            cpf: data?.billing_information?.cpf,
            district: data?.billing_information?.district,
            name: data.social_name,
            cnpj: data.cnpj,
            number: data?.billing_information?.number,
            state: data?.billing_information?.state,
          }}
        >
          <Form.Item label="Razão Social" name="name" rules={[{ required: true, message: 'Esse campo é obrigatório' }]}>
            <Input disabled type="text" defaultValue={data?.social_name || ''} />
          </Form.Item>

          <Form.Item label="CNPJ" name="cnpj" rules={[{ required: true, message: 'Esse campo é obrigatório' }]}>
            <InputMask disabled className="ant-input" mask="99.999.999/9999-99" defaultValue={data?.cnpj || ''} />
          </Form.Item>

          <Form.Item rules={[{ required: true, message: 'Esse campo é obrigatório' }]} label="País" name="country">
            <AntdSelect
              showSearch
              onChange={e => setSelectedCountry(e)}
              defaultValue={data?.billing_information?.country}
            >
              {countries.map(c => (
                <AntdSelect.Option key={c.nome} value={c.nome}>
                  {c.nome}
                </AntdSelect.Option>
              ))}
            </AntdSelect>
          </Form.Item>

          {!!selectedCountry && selectedCountry !== 'Brasil' ? (
            <Form.Item rules={[{ required: true, message: 'Esse campo é obrigatório' }]} label="Estado" name="state">
              <Input type="text" defaultValue={data?.billing_information?.state} />
            </Form.Item>
          ) : (
            <Form.Item rules={[{ required: true, message: 'Esse campo é obrigatório' }]} label="Estado" name="state">
              <AntdSelect
                showSearch
                filterOption
                onChange={e => onChangeSelectState(e)}
                defaultValue={data?.billing_information?.state || ''}
              >
                {states.map(s => (
                  <AntdSelect.Option key={s.nome} value={s.nome}>
                    {s.nome}
                  </AntdSelect.Option>
                ))}
              </AntdSelect>
            </Form.Item>
          )}

          {!!selectedCountry && selectedCountry !== 'Brasil' ? (
            <Form.Item rules={[{ required: true, message: 'Esse campo é obrigatório' }]} label="Estado" name="city">
              <Input type="text" defaultValue={data?.billing_information?.city} />
            </Form.Item>
          ) : (
            <Form.Item rules={[{ required: true, message: 'Esse campo é obrigatório' }]} label="Cidade" name="city">
              <AntdSelect showSearch defaultValue={data?.billing_information?.city || ''}>
                {cities.map(s => (
                  <AntdSelect.Option key={s.nome} value={s.nome}>
                    {s.nome}
                  </AntdSelect.Option>
                ))}
              </AntdSelect>
            </Form.Item>
          )}

          <Form.Item rules={[{ required: true, message: 'Esse campo é obrigatório' }]} label="Bairro" name="district">
            <Input type="text" defaultValue={data?.billing_information?.district} />
          </Form.Item>

          <Form.Item rules={[{ required: true, message: 'Esse campo é obrigatório' }]} label="CEP" name="postal_code">
            <Input type="text" defaultValue={data?.billing_information?.postal_code} />
          </Form.Item>

          <Form.Item rules={[{ required: true, message: 'Esse campo é obrigatório' }]} label="Endereço" name="address">
            <Input type="text" defaultValue={data?.billing_information?.address} />
          </Form.Item>

          <div style={{ display: 'flex', gap: '1rem' }}>
            <Form.Item rules={[{ required: true, message: 'Esse campo é obrigatório' }]} label="Número" name="number">
              <Input type="text" defaultValue={data?.billing_information?.number} />
            </Form.Item>

            <Form.Item label="Complemento" name="complement">
              <Input type="text" defaultValue={data?.billing_information?.complement} />
            </Form.Item>
          </div>

          <div className="changes">
            <Button type="primary" loading={saving} htmlType="submit">
              Salvar Alterações
            </Button>

            {/* <Button type="default" size="large"> */}
            {/* Cancelar */}
            {/* </Button> */}
          </div>
        </Form>
      </main>
    </Container>
  );
}
