import React, { useEffect, useState } from 'react';
import { Button, Table as AntdTable } from 'antd';

import { ColumnsType } from 'antd/lib/table/interface';
import { IProductDTO } from '../types';


function Img({ url }: { url: string }) {
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    if (url) {
      const defaultUrl = require('../../../static/img/produto-sem-imagem.png');
      const img = new Image();
      img.src = url;

      if (img.complete) {
        setImage(url);
      } else {
        img.onload = () => {
          setImage(url);
        };

        img.onerror = () => {
          setImage(defaultUrl);
        };
      }
    }
  }, [url]);

  return <img src={image || ''} style={{ maxHeight: '3rem', borderRadius: '0.5rem' }} alt="asdas" />;
}


export function TableComponent() {
  const columns: ColumnsType<IProductDTO> = [
    {
      title: '',
      dataIndex: 'addprodu',
    },
    {
      title: 'Produto',
      dataIndex: 'descprod',
    },
    {
      title: 'CodProd',
      dataIndex: 'codprod',
    },
    {
      title: 'CodVol',
      dataIndex: 'codvol',
    },
    {
      title: 'Qtd Neg',
      dataIndex: 'qtdneg',
    },
    {
      title: 'Vlr. Unit',
      dataIndex: 'vlrunit',
    },
    {
      title: 'Perc. Desc (%)',
      dataIndex: 'vlrdesc',
    },
    {
      title: 'Total',
      dataIndex: 'vlrtot',
    },
    {
      title: 'Vlr Líquido',
      dataIndex: 'vlrliq',
    },

    {
      title: 'Ações',
      dataIndex: 'Action',
    },
  ];

  return (
    <>
      <AntdTable style={{ height: '100%' }} pagination={false} showSorterTooltip={false} columns={columns} />

      <Button style={{ marginTop: '1rem' }} type="primary" disabled>
        + Add Produto
      </Button>
    </>
  );
}
