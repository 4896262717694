import React, { useEffect, useState } from 'react';
import { Select, Form as AntdForm } from 'antd';
import Cookie from 'js-cookie';
import axios from 'axios';
import styled from 'styled-components';

interface SelectParProps {
  defaultValue?: number;
  onChange?: (value: any) => void;
}

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function SelectPar({ defaultValue, onChange }: SelectParProps) {
  const [options, setOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookie.get('deskioapp-front-token');

  useEffect(() => {
    if (defaultValue) {
      get(String(defaultValue));
    }
  }, [defaultValue]);

  async function get(search: string) {
    setIsLoading(true);
    try {
      const res = await axios.post(
        'https://apiapp.deskio.com.br/api/v1/contacts/search-parc',
        {
          term: search,
        },
        {
          headers: {
            authorization: `bearer ${token?.replaceAll('"', '')}`,
          },
        },
      );

      if (!res.data) {
      }

      setOptions(Array.isArray(res.data) ? res.data : [res.data]);
    } catch (err) {
      setOptions([]);
    }
    setIsLoading(false);
  }

  let timer: NodeJS.Timeout;
  function debounce(event: string) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      get(event);
    }, 1000);
  }

  return (
    <AntdForm.Item label="Parceiro/Cliente (Sankhya)" name="codparc" style={{ width: '100%' }}>
      <AntdSelect
        showSearch
        filterOption={false}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder="Digite para pesquisar um Parceiro"
        onSearch={e => debounce(e)}
        loading={isLoading}
      >
        {options?.map(option => (
          <AntdSelect.Option value={option[0]}>
            {`(${option[0]}) `}
            {option[1]}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    </AntdForm.Item>
  );
}
