import uuid from 'react-uuid';
import { IProductDTO } from '../types';

interface IHandleCreateRow {
  state: IProductDTO[];
  setState: (value: React.SetStateAction<IProductDTO[]>) => void;
}

export function handleCreateRow({ state, setState }: IHandleCreateRow) {
  const array = [
    ...state,
    {
      id: uuid(),
      descprod: 'Faça aqui sua pesquisa',
      codprod: '0',
      codvol: '0',
      qtdneg: 0,
      vlrunit: 0,
      advlrmin: '0',
      vlrdesc: 0,
      vlrtot: 0,
      vlrliq: 0,
      img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYVbePsHQi3VxklVm1gh6gW1aTysdd04mk5A&usqp=CAU',
      position: state.length + 1,
      codlocalorig: '',
      percdesc: '0',
      sequencia: '',
    },
  ];

  setState(array);
}
