import React, { useEffect, useState } from 'react';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '~/components/buttons/buttons';
import { ProductCard } from '../Style';
import Cookies from 'js-cookie';
import { Divider } from 'antd';

const ProductCardsBase = ({ product, action, priceLabel, priceQuantity }) => {
  const name = product.Cadastro_DESCRPROD?.$;
  const id = product.Cadastro_CODPROD?.$;
  const [image, setImage] = useState(null);
  const cookies_user = JSON.parse(Cookies.get('strutural-user'));

  useEffect(() => {
    if (product) {
      const url = cookies_user.urlimagens.replace('{CODPROD}', product.Cadastro_CODPROD?.$);
      const defaultUrl = require('../../../static/img/produto-sem-imagem.png');
      const img = new Image();
      img.src = url;
      if (img.complete) {
        setImage(url);
      } else {
        img.onload = () => {
          setImage(url);
        };

        img.onerror = () => {
          setImage(defaultUrl);
        };
      }
    }
  }, [product, cookies_user.urlimagens]);

  const estoque = Object.entries(product).filter(item => item[0].includes('Estoque'));
  function handleClick() {
    const data = {
      id: id,
      name: name,
      estoque: product[estoque[0][0]].$,
    };
    return action(data);
  }

  const ss =
    Object.entries(product).filter(item => item[0]?.includes('Preço') && item[0]?.includes('PROMO'))[0]?.[1].$ || 0;

  const precopromo = Number(ss || 0).toFixed(2);
  return (
    <ProductCard onClick={handleClick} style={{ cursor: 'pointer' }}>
      <div className="divImg">
        <Button onClick={handleClick} block size="small" className="btn-cart" type="primary">
          {product.CODPROD?.$}
        </Button>

        <div>
          <img src={image} alt={`img${id}`} />
        </div>
      </div>

      <div className="rigthDiv">
        <p style={{ fontSize: '1rem' }}>
          <strong>{product.DESCRPROD?.$ || ''}</strong>
        </p>
        <div style={{ display: 'flex' }}>
          <div className="rigthDiv" style={{ padding: '0' }}>
            {product.Cadastro_MARCA?.$ && (
              <p>
                <span className="title">Marca: </span>
                {product.Cadastro_MARCA?.$}
              </p>
            )}

            <p>
              <span className="title">NCM: </span> {product.Cadastro_NCM?.$ || ''}
            </p>
            {product.Cadastro_AD_CODBARRA?.$ && (
              <p>
                <span className="title">EAN: </span> {product.Cadastro_AD_CODBARRA?.$}
              </p>
            )}
            <p>
              <span className="title">VOL: </span> {product.CODVOL?.$ || ''}
            </p>

            {priceQuantity === 1 ? (
              product.ISPROMOCAO === 'false' ? (
                <div style={{ display: 'flex', alignItens: 'center' }}>
                  <p style={{ fontSize: '1rem' }} className="value">
                    R$ {Number(product[priceLabel].$ || 0).toFixed(2)}
                  </p>
                </div>
              ) : (
                <div className="promoDiv">
                  <p style={{ fontSize: '1rem' }} className="promoAnt">
                    R${Number(product[priceLabel].$ || 0).toFixed(2)}
                  </p>
                  <p style={{ fontSize: '1rem' }} className="promoNew">
                    R$ {precopromo}
                  </p>
                </div>
              )
            ) : (
              <>
                <div style={{ display: 'flex', gap: '1rem', alignItens: 'center' }}>
                  {Object.entries(product)
                    .filter(item => item[0].includes('Preço') && !item[0].includes('PROMO'))
                    .map(item => (
                      <span>
                        {item[0]}: <br /> R${Number(item[1].$).toFixed(2)}
                      </span>
                    ))}
                </div>
                {Object.entries(product).filter(item => item[0].includes('Preço') && item[0].includes('PROMO')).length >
                  0 && (
                  <>
                    <Divider style={{ margin: '5px 0' }} />
                    <div style={{ display: 'flex', gap: '1rem', alignItens: 'center' }}>
                      {Object.entries(product)
                        .filter(item => item[0].includes('Preço') && item[0].includes('PROMO'))
                        .map(item => (
                          <span>
                            {item[0]}: <br /> R${Number(item[1].$).toFixed(2)}
                          </span>
                        ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <div style={{ marginTop: 'auto' }}>
            {estoque.length > 1 ? (
              <>
                {estoque.map(item => (
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {item[0]}: <br /> {Number(item[1].$)}
                  </span>
                ))}
              </>
            ) : (
              <>
                {parseFloat(Number(product[estoque?.[0]?.[0]]?.$)) ? (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    Em estoque: <br />
                    <div className="estoque">{parseFloat(Number(product[estoque[0][0]].$))}</div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </ProductCard>
  );
};

ProductCardsBase.propTypes = {
  product: PropTypes.object,
};

export default memo(ProductCardsBase, () => false);
