import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { theme } from '../../../../config/theme/themeVariables';
import toast from 'react-hot-toast';
import { api } from '../../../../services/api';
import { CardWrapper } from '../../../contacts/styles';
import { Link, useHistory } from 'react-router-dom';

interface MyPlanProps {
  data: any;
}

export function MyPlan({ data }: MyPlanProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [qty, setQty] = useState(data.qtd_users || 0);

  const [modalCorrect, setModalCorrect] = useState(false);

  function submit() {
    setSaving(true);
    if (!data.billing_information) {
      setModalCorrect(true);
      setSaving(false);
      return;
    }

    api
      .post('/accounts/update-info', { qtd_users: qty })
      .then(res => {
        toast.success(res.data.message);
        window.open(res.data.url_redirect, '_blank');
      })
      .catch(res => toast.error(res.data.message))
      .finally(() => {
        setSaving(false);
        setModalIsOpen(false);
      });
  }

  // function cancelAssin() {
  //   setSaving(true);
  //   api
  //     .post('/cancel-plan')
  //     .then(res => toast.success(res.data.message))
  //     .catch(res => toast.error(res.data.message))
  //     .finally(() => setSaving(false));
  // }

  console.log(data);

  const isExpirado = new Date(data.day_duo) < new Date();
  const history = useHistory();
  return (
    <Container>
      <header>
        <h1>Meu plano</h1>
      </header>

      <Modal
        visible={modalCorrect}
        okText={'Ok'}
        cancelText="Cancelar"
        onOk={() => history.push('/profile/4')}
        onCancel={() => setModalCorrect(false)}
      >
        Antes de completar sua assinatura, preencha as informações de cobrança.{' '}
        <Link to={'/profile/4'}>Clique Aqui</Link> para preencher
      </Modal>

      <main>
        <p>
          <strong>Número de Usuários:</strong>
          {data.qtd_users}
        </p>
        <p>
          <strong>Dia do vencimento da Mensalidade:</strong>
          <span style={{ color: isExpirado ? 'red' : '' }}>{data.day_duo}</span>
        </p>

        <p>
          <Button onClick={() => setModalIsOpen(true)}>Alterar plano</Button>
        </p>
      </main>

      <Modal
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        bodyStyle={{ padding: 0 }}
        footer={null}
        closable={false}
      >
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'center',
          }}
          layout="vertical"
        >
          <CardWrapper
            style={{
              marginTop: '1rem',
              margin: '20px',
            }}
            type="inner"
            title={`Editar plano`}
          >
            <Form.Item style={{ marginTop: '2rem' }} label="Número de Usuários:">
              <Input type="number" min={0} value={qty} onChange={e => setQty(Number(e.target.value))} />
            </Form.Item>
            <Form.Item label={'Valor do plano'}>
              <span style={{ color: theme['primary-color'] }}>R$ {Number(data.price_per_user) * qty} / mês</span>
            </Form.Item>
          </CardWrapper>

          <footer
            style={{
              gap: '5px',
              padding: '20px',
              display: 'flex',
              justifyContent: 'end',
              borderTop: '1px solid #f0f0f0',
            }}
          >
            <Button type="default" htmlType="button" onClick={() => setModalIsOpen(false)}>
              Cancelar
            </Button>
            <Button type="primary" htmlType="button" onClick={submit}>
              {saving ? 'Salvando' : 'Salvar'}
            </Button>
          </footer>
        </Form>
      </Modal>
    </Container>
  );
}
