import { Button } from 'antd';
import React from 'react';
import { useFetch } from '../../../../hooks/useFetch';
import { Container } from './styles';

export function History() {
  const { dataFetch } = useFetch<any[]>({
    baseUrl: 'user-payments',
    isLinkProps: false,
  });

  return (
    <Container>
      <header>
        <h1>Pagamentos</h1>
      </header>

      <table>
        <tr>
          <th>Detalhes</th>
          <th>Valor</th>
          <th>Vencimento</th>
          <th>Data Pagamento</th>
          <th>Status</th>
          <th></th>
        </tr>

        {dataFetch?.map(item => (
          <tr>
            <td style={{ whiteSpace: 'normal' }}>{item.description}</td>
            <td>{Number(item.amount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
            <td>{new Date(item?.payment_duodate || '').toLocaleDateString()}</td>
            <td>{item?.paid_at ? new Date(item.paid_at).toLocaleDateString() : 'Não pago'}</td>
            <td>
              <span
                style={{
                  color: item.plan.status === 0 ? '#396dd6' : '#B60016',
                  background: item.plan.status === 0 ? '#d3e2ff' : '#FF7878',
                  border: `0px solid ${item.plan.status === 0 ? '#d3e2ff' : '#FF7878'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '12px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                {item.plan.status === 0 ? 'Pago' : 'Não pago'}
              </span>
            </td>
            <td>
              <Button onClick={() => window.open(`https://pay.deskio.br/payment/${item.plan.uuid}`, '_blank')}>
                Ver Fatura
              </Button>
            </td>
          </tr>
        ))}
      </table>
    </Container>
  );
}
