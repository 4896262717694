import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Button, Input, Modal, Table as AntdTable } from 'antd';

import { ColumnsType } from 'antd/lib/table/interface';
import { SelectProdu } from './selectProdu';
import { useParams } from 'react-router-dom';
import Feather from 'feather-icons-react';
import { IProductDTO } from '../types';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { api } from '../../../services/api';

interface ITableComponentProps {
  initialData: any[];
  changeVlrUnit(vlrUnit: number, id: string): void;
  statusNota: string;
  pendente: string;
  codtipoper: string;
  fetch: () => Promise<void>;
  saveds: { id: string; value: 'saved' | 'created' | 'editaded' }[];
  handleAddItem: () => void;
  setSaveds: (e: { id: string; value: 'saved' | 'created' | 'editaded' }[]) => void;
  changeQuantity(qtdned: number, id: string): void;
  state: IProductDTO[];
  setState: (value: React.SetStateAction<IProductDTO[]>) => void;
  changeVlrDesc(vlrDesc: number, id: string): void;
  get: () => Promise<void>;
  codpar: string;
}

function Img({ url }: { url: string }) {
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    if (url) {
      const defaultUrl = require('../../../static/img/produto-sem-imagem.png');
      const img = new Image();
      img.src = url;

      if (img.complete) {
        setImage(url);
      } else {
        img.onload = () => {
          setImage(url);
        };

        img.onerror = () => {
          setImage(defaultUrl);
        };
      }
    }
  }, [url]);

  return <img src={image || ''} style={{ maxHeight: '3rem', borderRadius: '0.5rem' }} alt="asdas" />;
}

export function TableComponent({
  state,
  setState,
  initialData,
  changeQuantity,
  setSaveds,
  saveds,
  handleAddItem,
  changeVlrUnit,
  fetch,
  changeVlrDesc,
  pendente,
  statusNota,
  codtipoper,
  get,
  codpar,
}: ITableComponentProps) {
  const { nunota } = useParams<any>();
  const token = Cookies.get('deskioapp-front-token');
  const [saveIsLoading, setSaveIsLoading] = useState(0);

  const isDisabled = !!saveds.find(item => item.value !== 'saved') || state.length >= saveds.length + 1;

  async function salvarProdu(id: any) {
    setSaveIsLoading(id);
    const item = state.find(item => item.id === id);
    if (!item) {
      setSaveIsLoading(0);
      return;
    }

    const body = {
      NUNOTA: nunota,
      CODPROD: item.codprod,
      CODLOCALORIG: item.codlocalorig,
      QTDNEG: item.qtdneg,
      CODVOL: item.codvol,
      VLRUNIT: item.vlrunit,
      VLRDESC: item.vlrdesc,
      PERCDESC: item.percdesc,
      VLRTOT: item.vlrtot,
    };

    try {
      if (item.sequencia && item.sequencia !== '0') {
        await api.post(
          '/quotes/insert-item',
          { ...body, SEQUENCIA: item.sequencia },
          {
            headers: {
              authorization: `bearer ${token}`,
            },
          },
        );
      } else {
        await api.post('/quotes/insert-item', body, {
          headers: {
            authorization: `bearer ${token}`,
          },
        });
      }

      await fetch();
      await get();
      const itesFilter = saveds.filter(itsem => itsem.id !== item.codprod);
      itesFilter.push({ id: item.codprod, value: 'saved' });
      setSaveIsLoading(0);
      setSaveds(itesFilter);
      toast.success('Produto adicionado');
    } catch (err) {
      const { response }: any = err;
      setSaveIsLoading(0);
      toast.error(response.data.erro);
    }
  }

  async function editProdu(id: any) {
    setSaveIsLoading(id);
    const item = state.find(item => item.id === id);
    if (!item) {
      setSaveIsLoading(0);
      return;
    }

    const body = {
      NUNOTA: nunota,
      CODPROD: item.codprod,
      CODLOCALORIG: item.codlocalorig,
      QTDNEG: item.qtdneg,
      CODVOL: item.codvol,
      VLRUNIT: item.vlrunit,
      VLRDESC: item.vlrdesc,
      PERCDESC: item.percdesc,
      VLRTOT: item.vlrtot,
      SEQUENCIA: item.sequencia,
    };

    try {
      await api.post('/quotes/insert-item', body, {
        headers: {
          authorization: `bearer ${token}`,
        },
      });

      await fetch();
      await get();

      const itesFilter = saveds.filter(itsem => itsem.id !== item.codprod);
      itesFilter.push({ id: item.codprod, value: 'saved' });

      setSaveds(itesFilter);
      setSaveIsLoading(0);
      toast.success('Produto Editado');
    } catch (err) {
      setSaveIsLoading(0);
      toast.error('Não foi possivel editar o produto');
    }
  }

  async function deleteProduct(codprod: any) {
    setSaveIsLoading(codprod);
    const itesFilter = state.filter(itsem => String(itsem.codprod) !== String(codprod));
    const item = state.find(item => item.codprod === codprod);

    if (!item?.sequencia || item.codprod === '0') {
      setState(itesFilter.filter(item => item.codprod !== '0'));
      setSaveds(saveds.filter(item => item.id !== codprod));
      setSaveIsLoading(0);
      return;
    }

    setState(itesFilter);
    setSaveds(saveds.filter(item => item.id !== codprod));

    await api.post(
      '/quotes/delete-item',
      { NUNOTA: nunota, SEQUENCIA: item.sequencia },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      },
    );

    await get();
    setSaveIsLoading(0);
  }

  async function chamarFuncao(callbackFunction: any) {
    if (isDisabled) {
      Modal.warning({
        title: 'Erro!',
        content: (
          <div>
            <p>Salve primeiro os itens antes de executar essa ação</p>
          </div>
        ),
      });
      return;
    }

    await callbackFunction();
  }

  const columns: ColumnsType<IProductDTO> = [
    {
      title: '',
      dataIndex: 'addprodu',

      render: (item, row) => {
        return <Img url={row.img} />;
      },
    },
    {
      title: 'Produto',
      dataIndex: 'descprod',

      render: (item, row) => {
        return (
          <SelectProdu
            setSaveds={setSaveds}
            codpar={codpar}
            saveds={saveds}
            defaultValue={row.descprod}
            state={state}
            setState={setState}
            nunota={nunota}
            row={row}
          />
        );
      },
    },
    {
      title: 'CodProd',
      dataIndex: 'codprod',
      render: item => <Input style={{ minWidth: '7rem' }} value={item} disabled />,
    },
    {
      title: 'CodVol',
      dataIndex: 'codvol',
      render: item => <Input style={{ maxWidth: '3rem' }} value={item} disabled />,
    },
    {
      title: 'Qtd Neg',
      dataIndex: 'qtdneg',
      render: (item, row) => (
        <Input type="number" value={item} onChange={e => changeQuantity(Number(e.target.value), row.id)} />
      ),
    },
    {
      title: 'Vlr. Unit (R$)',
      dataIndex: 'vlrunit',
      render: (item, row) => (
        <Input
          type="number"
          className="ant-input"
          value={item}
          onChange={e => changeVlrUnit(Number(e.target.value), row.id)}
        />
      ),
    },
    {
      title: 'Perc. Desc (%)',
      dataIndex: 'percdesc',
      render: (item, row) => (
        <Input
          type="number"
          style={{ maxWidth: '4rem' }}
          value={item}
          onChange={e => changeVlrDesc(Number(e.target.value), row.id)}
        />
      ),
    },
    {
      title: 'Total',
      dataIndex: 'vlrtot',
      render: item => (
        <CurrencyInput
          className="ant-input ant-input-disabled"
          name="input-name"
          value={item.toFixed(2)}
          prefix="R$ "
          decimalsLimit={2}
          disabled
        />
      ),
    },
    {
      title: 'Vlr Líquido',
      dataIndex: 'vlrliq',
      render: item => (
        <CurrencyInput
          className="ant-input ant-input-disabled"
          name="input-name"
          value={item.toFixed(2)}
          prefix="R$ "
          decimalsLimit={2}
          disabled
        />
      ),
    },

    {
      title: 'Ações',
      dataIndex: 'Action',
      render: (key, item) => {
        const mySaved = saveds.find(saved => saved.id === item.codprod);

        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            {mySaved?.value === 'created' && (
              <p
                onClick={() => salvarProdu(item.id)}
                style={{
                  background: '#7ae582',
                  height: '2rem',
                  width: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  color: '#FFF',
                  cursor: 'pointer',
                }}
              >
                {String(saveIsLoading) === item.id ? (
                  <Feather icon="loader" size={16} />
                ) : (
                  <Feather icon="check" size={16} />
                )}
              </p>
            )}
            {mySaved?.value === 'editaded' && (
              <p
                onClick={() => editProdu(item.id)}
                style={{
                  background: '#ffd100',
                  height: '2rem',
                  width: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  color: '#FFF',
                  cursor: 'pointer',
                }}
              >
                {String(saveIsLoading) === item.id ? (
                  <Feather icon="loader" size={16} />
                ) : (
                  <Feather icon="check" size={16} />
                )}
              </p>
            )}
            {mySaved?.value === 'saved' && (
              <p
                style={{
                  background: '#e5e5e5',
                  height: '2rem',
                  width: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  color: '#FFF',
                  cursor: 'not-allowed',
                }}
              >
                {String(saveIsLoading) === item.id ? (
                  <Feather icon="loader" size={16} />
                ) : (
                  <Feather icon="check" size={16} />
                )}
              </p>
            )}

            <p
              onClick={() => deleteProduct(item.codprod)}
              style={{
                background: '#d90429',
                height: '2rem',
                width: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                color: '#FFF',
                cursor: 'pointer',
              }}
            >
              <Feather style={{ height: '1.2rem' }} icon="trash" />
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <AntdTable
        style={{ height: '100%' }}
        pagination={false}
        showSorterTooltip={false}
        dataSource={initialData}
        columns={columns}
      />

      {/* {statusNota === 'A' && pendente === 'S' && codtipoper === '900' && ( */}
      {pendente === 'S' && (
        <Button style={{ marginTop: '1rem' }} type="primary" onClick={() => chamarFuncao(handleAddItem)}>
          + Add Produto
        </Button>
      )}
    </>
  );
}
