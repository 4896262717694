import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { ColumnsType } from 'antd/lib/table';

import { TableButtonEdit } from './TableButtonEdit';

import { Button } from '../../../../components/buttons/buttons';
import { TableModalView } from './TableModalView';
import Cookies from 'js-cookie';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Tooltip } from 'antd';

interface ITableColumnControllerProps {
  handleDeleteFunction: (contactId: string) => Promise<void>;
  refetch: () => void;
  dataList?: string[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const user: any = JSON.parse(Cookies.get('strutural-user') || '');
  const token = Cookies.get('deskioapp-front-token');
  const [loadingDanfe, setLoadingDanfe] = useState(0);
  const [loadingBoleto, setLoadingBoleto] = useState(0);
  const [loadingGenerateBoleto, setLoadingGenerateBoleto] = useState(0);

  const MaxWidthColumn: React.FC<{ width: number }> = ({ width, children }) => (
    <div
      style={{
        maxWidth: width,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </div>
  );

  async function handleGenerateBoleto(nunota: any) {
    setLoadingGenerateBoleto(nunota);
    if (!token) {
      setLoadingGenerateBoleto(0);
      return;
    }

    await axios
      .post(
        `https://apiapp.deskio.com.br/api/v1/quotes/generate-build-slip`,
        { NUNOTA: nunota },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then(res => {
        if (!res.data.avisos) {
          toast.success('Boleto gerado.');
          return;
        }

        toast.error(res.data.avisos.aviso);
      })
      .catch(res => {
        toast.error('Não foi possivel gerar o boleto.');
      })
      .finally(() => setLoadingGenerateBoleto(0));
  }

  async function handleBoleto(nunota: any) {
    setLoadingBoleto(nunota);
    if (!token) {
      setLoadingBoleto(0);
      return;
    }

    await axios
      .post(
        `https://apiapp.deskio.com.br/api/v1/quotes/generate-slip`,
        { NUNOTA: nunota },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
          responseType: 'blob',
        },
      )

      .then(res => {
        console.log(res.data);
        if (res.data.size < 100) {
          toast.error('Boleto Não Existente');
        } else {
          toast.success('Abrindo Visualização de Boleto...');
          const file = new Blob([res.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      /* 
      .then(res => {
        if (!res.data.success) {
          toast.error(res.data.message);
          return;
        }
        toast.success('Abrindo Visualização de Boleto...');
        axios
          .get(res.data.url_boleto, {
           
            headers: {
              'Cookie': `${res.data.Cookie}`+';'+' Path=/mge; Domain=.jiva.strutural.net;',
              'jset':`${res.data.Cookie}`+';'+' Path=/mge; Domain=.jiva.strutural.net;'
            },
            responseType: 'blob',
          })
          .then(res => {
            const file = new Blob([res.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          });

          
      }) */
      .catch(res => {
        toast.error('Não foi possivel gerar o boleto.');
      })
      .finally(() => setLoadingBoleto(0));
  }

  async function handlePDF(nunota: any) {
    setLoadingDanfe(nunota);
    if (!user.jsessionID) {
      setLoadingDanfe(0);
      return;
    }

    await axios
      .get(`https://apiapp.deskio.com.br/api/v1/gera-pdf-danfe-app/${window.location.hostname.split('.')[0]}/${nunota}`, {
        headers: {
          authorization: `bearer ${user.jsessionID}`,
        },
        responseType: 'blob',
      })
      .then(res => {
        const file = new Blob([res.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .finally(() => setLoadingDanfe(0));
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Emissão',
      dataIndex: 'emissao',
      sorter: true,
    },

    {
      title: 'Agendamento',
      dataIndex: 'agendamento',
      sorter: true,
    },

    {
      title: 'Nota',
      dataIndex: 'nota',
      sorter: true,
    },
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      sorter: true,
    },
    {
      title: 'Vendedor',
      dataIndex: 'vendedor',
      sorter: true,
      render: (_, row) => (
        <MaxWidthColumn width={180}>
          {' '}
          <span
            style={{
              color: '#20C9A5',
              background: '#E2F4F0',
              border: `0px solid ${'#b7eb8f'}`,
              padding: '3px 10px',
              borderRadius: 40,
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              boxSizing: 'border-box',
              fontVariant: 'tabular-nums',
              listStyle: 'none',
              fontFeatureSettings: 'tnum',
              display: 'inline-block',
              height: 'auto',
              fontSize: '12px',
              lineHeight: '20px',
              whiteSpace: 'nowrap',
              transition: 'all 0.3s',
            }}
          >
            {row.vendedor}
          </span>
        </MaxWidthColumn>
      ),
    },
    {
      title: 'Entregador',
      dataIndex: 'entregador',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, row: any) => {
        if (String(row.status) === '2') {
          return (
            <MaxWidthColumn width={180}>
              {' '}
              <span
                style={{
                  color: '#20C9A5',
                  background: '#E2F4F0',
                  border: `0px solid ${'#b7eb8f'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '12px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                Entregue
              </span>
            </MaxWidthColumn>
          );
        } else if (String(row.status) === '1') {
          return (
            <MaxWidthColumn width={180}>
              {' '}
              <span
                style={{
                  color: '#396dd6',
                  background: '#d3e2ff',
                  border: `0px solid ${'#d3e2ff'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '12px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                Proc. Entrega
              </span>
            </MaxWidthColumn>
          );
        } else if (String(row.status) === '0') {
          return (
            <MaxWidthColumn width={180}>
              {' '}
              <span
                style={{
                  color: '#d8ae3a',
                  background: '#f4eacd',
                  border: `0px solid ${'#f4eacd'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '12px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                Pendente
              </span>
            </MaxWidthColumn>
          );
        } else if (String(row.status) === '3') {
          return (
            <MaxWidthColumn width={180}>
              {' '}
              <span
                style={{
                  color: '#B60016',
                  background: '#FF7878',
                  border: `0px solid ${'#FF7878'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '12px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                Problema
              </span>
            </MaxWidthColumn>
          );
        }
      },
    },

    {
      title: 'Expedição',
      dataIndex: 'expedicao',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            {/* <Tooltip title="Gerar boleto">
              <Button
                className="btn-icon"
                type="info"
                onClick={() => handleGenerateBoleto(item.nuNota)}
                to="#"
                shape="circle"
              >
                {loadingGenerateBoleto === item.nuNota ? (
                  <FeatherIcon icon="loader" size={16} />
                ) : (
                  <FeatherIcon icon="log-out" size={16} />
                )}
              </Button>
            </Tooltip>

            <Tooltip title="Abrir boleto">
              <Button className="btn-icon" type="info" onClick={() => handleBoleto(item.nuNota)} to="#" shape="circle">
                {loadingBoleto === item.nuNota ? (
                  <FeatherIcon icon="loader" size={16} />
                ) : (
                  <FeatherIcon icon="paperclip" size={16} tip="teste" />
                )}
              </Button>
            </Tooltip> */}

            <Tooltip title="Abrir PDF">
              <Button className="btn-icon" type="info" onClick={() => handlePDF(item.nuNota)} to="#" shape="circle">
                {loadingDanfe === item.nuNota ? (
                  <FeatherIcon icon="loader" size={16} />
                ) : (
                  <FeatherIcon icon="download" size={16} />
                )}
              </Button>
            </Tooltip>
            {item.status !== '2' && (
            <TableButtonEdit contactId={String(item.nuNota)} refetch={refetch} data={item} />  
       
      )}
            

            <TableModalView refresh={refetch} data={item} />
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item[2],
      emissao: `${item[0].substr(0, 2)}/${item[0].substr(2, 2)}/${item[0].substr(4, 4)} ${item[0].substr(8, 6)}`,
      agendamento: item[1]
        ? `${item[1].substr(0, 2)}/${item[1].substr(2, 2)}/${item[1].substr(4, 4)} ${item[1].substr(8, 6)}`
        : item[1],
      nota: item[2],
      cliente: item[3],
      vendedor: item[4],
      entregador: item[5],
      status: item[6],
      expedicao: item[7],
      nuNota: item[8],
    });
  });

  return { columns, data };
}
