import { Input, Form } from 'antd';
import React, { useState } from 'react';
import Picker, { IEmojiData } from 'emoji-picker-react';

interface Props {
  value: string;
  label?: string;
  setValue: (e: string) => void;
  rows: number;
}

export function TextareaWithEmoji({ label, setValue, value, rows }: Props) {
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const onEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, emojiObject: IEmojiData) => {
    setValue(value + emojiObject.emoji);
    setEmojiPickerOpen(false);
  };

  return (
    <div style={{ position: 'relative', flex: 1 }}>
      <label style={{ position: 'absolute', top: '-1.5rem', left: '0' }}>{label}</label>

      <button
        onClick={() => setEmojiPickerOpen(true)}
        type="button"
        style={{
          position: 'absolute',
          zIndex: 9999,
          top: '0.5rem',
          right: '0.5rem',
          background: 'transparent',
          border: 'none',
          color: '#ccc',
        }}
      >
        😀
      </button>
      <Input.TextArea
        rows={rows}
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder="um texto"
        style={{ resize: 'none', marginBottom: '1rem' }}
      />

      {emojiPickerOpen && (
        <div
          onMouseLeave={() => setEmojiPickerOpen(false)}
          style={{ position: 'absolute', top: '0rem', right: '0rem', zIndex: 9999 }}
        >
          <Picker onEmojiClick={onEmojiClick} />
        </div>
      )}
    </div>
  );
}
