import styled from 'styled-components';
import { theme } from '../../../../config/theme/themeVariables';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;

  flex: 1;

  header {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 1.1rem;
      margin-bottom: 0;
      color: ${theme['heading-color']};
    }

    p {
      margin-bottom: 0;
      color: ${theme['gray-solid']};
    }
  }

  table {
    width: 100%;

    th,
    td {
      text-align: start;
      padding: 1rem;
      white-space: nowrap;
    }

    tr {
      border-top: 1px solid ${theme['bg-color-deep']};
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;

  background-color: ${theme['bg-color-deep']};
`;
