import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { api } from '../../../../services/api';
import { Container } from './styles';

export function Password() {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [saving, setSaving] = useState(false);

  function submit() {
    if (!password || !newPassword) {
      toast.error('Os campos não podem estar em branco');
      return;
    }

    setSaving(true);
    api
      .post('update-info', { password: newPassword, old_password: password })
      .then(response => toast.success(response.data.message))
      .catch(response => toast.error(response.data.message))
      .finally(() => setSaving(false));
  }

  return (
    <Container>
      <header>
        <h1>Trocar senha</h1>
        <p>Preencha os campos abaixo para trocar sua senha</p>
      </header>

      <main>
        <Form layout="vertical">
          <Form.Item label="Senha antiga" name="old-password">
            <Input value={password} onChange={e => setPassword(e.target.value)} type="password" />
          </Form.Item>

          <Form.Item label="Nova senha" name="new-password">
            <Input value={newPassword} onChange={e => setNewPassword(e.target.value)} type="password" />
          </Form.Item>
        </Form>

        <div className="changes">
          <Button loading={saving} type="primary" onClick={submit} size="large">
            Trocar senha
          </Button>
        </div>
      </main>
    </Container>
  );
}
