import Cookies from 'js-cookie';
import { treatment } from '../../../hooks/treatment';
import { api } from '../../../services/api';

interface ISearchProductProps {
  search: string;
  nunota: string;
  codpar: string;
  handleLoading: (e: boolean) => void;
}

const searchURL = '/quotes/search-produto';

export async function searchProduct({ search, handleLoading, nunota, codpar }: ISearchProductProps) {
  handleLoading(true);
  if (search.length <= 2) return handleLoading(false);

  const token = Cookies.get('deskioapp-front-token')?.replaceAll('"', '');
  const bodyContent = {
    nunota,
    term: search,
    codpar,
  };

  const { data } = await api.post(searchURL, bodyContent, {
    headers: {
      authorization: `bearer ${token}`,
    },
  });

  treatment(data);

  const newData: any = Array.isArray(data.produtos?.produto) ? data.produtos?.produto : [data.produtos?.produto];

  return (
    newData?.map(item => {
      return {
        value: item.CODPROD,
        label: item.DESCRPROD,
      };
    }) || []
  );
}
