import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { Col, Spin, Card, Table } from 'antd';
import Heading from '~/components/heading/heading';
import { Cards } from '~/components/cards/frame/cards-frame';
import EmptyMessage from '~/components/emptyMessage';
import { Sidebar, SidebarSingle } from '../Style';
import { INVENTORY_DETAILS_URL, PENDING_ENTRIES_URL } from '~/utility/useLinks';
import axios from 'axios';
import Cookie from 'js-cookie';
import { useAuth } from '../../../hooks/useAuth';

const DetailsRight = ({ product }) => {
  const [inventoryDetails, setInventoryDetais] = useState(null);
  const [pendingEntries, setPendingEntries] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const { errorSessionTokenControl } = useAuth();
  const token = Cookie.get('deskioapp-front-token');
  const jstoken = Cookie.get('strutural-jsessionID');

  useEffect(() => {
    async function fetchData() {
      setIsLoader(true);

      const detailsResponse = await axios.post(
        INVENTORY_DETAILS_URL,
        {
          CODPROD: product.id,
        },
        {
          headers: {
            jsessionID: jstoken,
            authorization: `Bearer ${token}`,
          },
        },
      );
      const responseData = detailsResponse.data;
      if (responseData?.success === false) {
        errorSessionTokenControl(responseData);
      }

      const pendingResponse = await axios.post(
        PENDING_ENTRIES_URL,
        {
          CODPROD: product.id,
        },
        {
          headers: {
            jsessionID: jstoken,
            authorization: `Bearer ${token}`,
          },
        },
      );

      const pendingResponseData = pendingResponse.data;
      if (pendingResponseData?.success === false) {
        errorSessionTokenControl(pendingResponseData);
      }

      const detailsBody = detailsResponse.data.responseBody;
      const inventory = detailsBody.produto.estoques;
      if (inventory?.estoque) {
        const estoque = [];
        if (Array.isArray(inventory?.estoque)) {
          inventory.estoque.forEach((element, index) => {
            estoque.push({
              key: index,
              disponivel: element.DISPONIVEL?.$,
              local: `${element.DESCRLOCAL?.$} (${element.CODLOCAL?.$})`,
            });
          });
        } else {
          estoque.push({
            key: 1,
            disponivel: inventory.estoque.DISPONIVEL?.$,
            local: `${inventory.estoque.DESCRLOCAL?.$} (${inventory.estoque.CODLOCAL?.$})`,
          });
        }
        setInventoryDetais(estoque);
      }
      const pendingBody = pendingResponse.data.responseBody;

      if (Array.isArray(pendingBody.entradasPendentes)) {
        setPendingEntries(null);
        setIsLoader(false);
        return;
      }

      const entries = pendingBody.entradasPendentes.entradaPendente;
      if (Array.isArray(entries)) {
        const pending = [];

        entries.forEach((element, index) => {
          pending.push({
            key: index,
            numnota: element.NUMNOTA?.$,
            qtd: element.QTD?.$,
          });
        });

        setPendingEntries(pending);

        if (pending.length === 0) {
          setPendingEntries(null);
        }
      } else {
        setPendingEntries([
          {
            key: 1,
            numnota: entries.NUMNOTA?.$,
            qtd: entries.QTD?.$,
          },
        ]);
      }

      setIsLoader(false);
    }
    if (product) {
      fetchData();
    }

    return () => {
      setInventoryDetais(null);
    };
  }, [product, errorSessionTokenControl, jstoken, token]);

  const inventoryColumns = [
    {
      title: 'Disponível',
      dataIndex: 'disponivel',
      key: 'disponivel',
    },
    {
      title: 'Nome local',
      dataIndex: 'local',
      key: 'local',
    },
  ];

  const pendingColumns = [
    {
      title: 'Pedido',
      dataIndex: 'numnota',
      key: 'numnota',
    },
    {
      title: 'Quantidade',
      dataIndex: 'qtd',
      key: 'qtd',
    },
    {
      title: 'Cód. Empresa',
      dataIndex: 'CODEMP',
      key: 'CODEMP',
    },
    {
      title: 'Nome Empresa',
      dataIndex: 'NOMEFANTASIA',
      key: 'NOMEFANTASIA',
    },
  ];

  return (
    <Sidebar>
      <Cards
        title={
          <span>
            <FeatherIcon icon="info" size={14} />
            <span ellipsis="true">{product?.name ? product.name : 'Detalhes'}</span>
          </span>
        }
      >
        {!product ? (
          <Col xs={24}>
            <div className="spin">
              <EmptyMessage text="Nenhuma informação" iconSize={40} textSize={15} />
            </div>
          </Col>
        ) : (
          <>
            {isLoader ? (
              <div className="spin d-flex align-center-v">
                <Spin />
              </div>
            ) : (
              <>
                {inventoryDetails || pendingEntries ? (
                  <>
                    <SidebarSingle style={{ marginBottom: 15 }}>
                      <Heading as="h5">Detalhes de estoque</Heading>
                      {inventoryDetails ? (
                        <Table dataSource={inventoryDetails} columns={inventoryColumns} pagination={false} />
                      ) : (
                        <Col xs={24} style={{ padding: 10 }}>
                          <Card style={{ width: '100%' }}>
                            <div className="spin" style={{ height: 100 }}>
                              <EmptyMessage text="Nenhuma informação" iconSize={40} textSize={15} />
                            </div>
                          </Card>
                        </Col>
                      )}
                    </SidebarSingle>
                    <div style={{ marginBottom: 32 }}>
                      <span style={{ fontWeight: 'bold' }}>Estoque total:</span> <span>{product.estoque}</span>
                    </div>
                    <SidebarSingle style={{ marginBottom: 32 }}>
                      <Heading as="h5">Entradas pendentes</Heading>
                      {pendingEntries ? (
                        <Table dataSource={pendingEntries} columns={pendingColumns} pagination={false} />
                      ) : (
                        <Col xs={24}>
                          <Card bordered style={{ width: '100%' }}>
                            <div className="spin" style={{ height: 100 }}>
                              <EmptyMessage text="Nenhuma informação" iconSize={40} textSize={15} />
                            </div>
                          </Card>
                        </Col>
                      )}
                    </SidebarSingle>
                  </>
                ) : (
                  <Col xs={24}>
                    <div className="spin">
                      <EmptyMessage text="Nenhuma informação" iconSize={40} textSize={15} />
                    </div>
                  </Col>
                )}
              </>
            )}
          </>
        )}
      </Cards>
    </Sidebar>
  );
};

DetailsRight.propTypes = {
  product: PropTypes.object,
};

export default DetailsRight;
