import { Button, Switch, Form, Input } from 'antd';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { api } from '../../../../services/api';
import { Container } from './styles';

export function Modules({ data }: { data: any }) {
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    enable_search_products: data.enable_search_products,
    enable_quotes: data.enable_quotes,
    enable_conference: data.enable_conference,
    enable_invoicesqueue: data.enable_invoicesqueue,
    enable_omnichannel: data.enable_omnichannel,
    cod_parceiro_motorista: data.cod_parceiro_motorista,
  });

  const [formData2, setFormData2] = useState({
    url_imagens: data.url_imagens,
    url_base_sankhya: data.url_base_sankhya,
    queue_top_range: data.queue_top_range,
  });

  function handleData(unicData: string | boolean, key: string) {
    setFormData(oldData => ({ ...oldData, [key]: unicData }));
  }
  function handleData2(unicData: string | boolean, key: string) {
    setFormData2(oldData => ({ ...oldData, [key]: unicData }));
  }

  function submit() {
    if (data.enable_invoicesqueue && data.cod_parceiro_motorista === '') {
      toast.error('O campo de código do motorista não pode estar em branco.');
      return;
    }

    setSaving(true);
    api
      .post('/accounts/update-info', formData)
      .then(response => toast.success(response.data?.message || 'Concluido com sucesso!'))
      .catch(response => toast.error(response.data?.message || 'Não foi possivel concluir'))
      .finally(() => setSaving(false));
  }

  function submit2() {
    setSaving(true);
    api
      .post('/accounts/update-info', formData2)
      .then(response => toast.success(response.data?.message || 'Concluido com sucesso!'))
      .catch(response => toast.error(response.data?.message || 'Não foi possivel concluir'))
      .finally(() => setSaving(false));
  }

  return (
    <Container>
      {/* <header>
        <h1>Módulos</h1>
      </header>

      <main>
        <Form layout="vertical">
          <div>
            <Switch
              checked={formData.enable_search_products}
              onClick={() => handleData(!formData.enable_search_products, 'enable_search_products')}
            />
            <span style={{ marginLeft: '1rem' }}>Módulo Consulta de Produtos</span>
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            <Switch
              checked={formData.enable_quotes}
              onClick={() => handleData(!formData.enable_quotes, 'enable_quotes')}
            />
            <span style={{ marginLeft: '1rem' }}>Módulo Orçamentos/Pedidos</span>
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            <Switch
              checked={formData.enable_conference}
              onClick={() => handleData(!formData.enable_conference, 'enable_conference')}
            />
            <span style={{ marginLeft: '1rem' }}>Módulo Fila de Conferência</span>
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            <Switch
              checked={formData.enable_invoicesqueue}
              onClick={() => handleData(!formData.enable_invoicesqueue, 'enable_invoicesqueue')}
            />
            <span style={{ marginLeft: '1rem' }}>Módulo Fila de Entregas/Notas</span>
          </div>
          {formData.enable_invoicesqueue && (
            <Form.Item style={{ marginTop: '0.5rem' }} label="Cód. Motorista Padrão">
              <Input
                placeholder="Sem Motorista"
                value={formData.cod_parceiro_motorista}
                onChange={e => handleData(e.target.value, 'cod_parceiro_motorista')}
              />
            </Form.Item>
          )}
          <div style={{ marginTop: '0.5rem' }}>
            <Switch
              checked={formData.enable_omnichannel}
              onClick={() => handleData(!formData.enable_omnichannel, 'enable_omnichannel')}
            />
            <span style={{ marginLeft: '1rem' }}>Módulo Multi-Atendimento</span>
          </div>
        </Form>

        <div className="changes">
          <Button loading={saving} type="primary" onClick={submit} size="large">
            Salvar
          </Button>
        </div>
      </main> */}

      <header>
        <h1>Diversos</h1>
      </header>

      <main>
        <Form layout="vertical">
          <Form.Item style={{ marginTop: '0.5rem' }} label="URL base de imagens de produtos">
            <Input
              placeholder="URL base de imagens de produtos"
              value={formData2.url_imagens}
              onChange={e => handleData2(e.target.value, 'url_imagens')}
            />
          </Form.Item>
          <Form.Item style={{ marginTop: '0.5rem' }} label="URL base Ambiente do Sankhya">
            <Input
              placeholder="URL base Ambiente do Sankhya"
              value={formData2.url_base_sankhya}
              onChange={e => handleData2(e.target.value, 'url_base_sankhya')}
            />
          </Form.Item>
          <Form.Item style={{ marginTop: '0.5rem' }} label="Range de TOPs para Fila de Entregas (separado por vírgula)">
            <Input
              placeholder="Range de TOPs para Fila de Entregas (Ex: 1001,1021)"
              value={formData2.queue_top_range}
              onChange={e => handleData2(e.target.value, 'queue_top_range')}
            />
          </Form.Item>
        </Form>
        <div className="changes">
          <Button loading={saving} type="primary" onClick={submit2} size="large">
            Salvar
          </Button>
        </div>
      </main>
    </Container>
  );
}
