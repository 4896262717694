import { Button, Modal, Pagination, Select, Spin } from 'antd';
import React, { useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../../container/styled';
import { useFetch } from '../../hooks/useFetch';
import { ThemeLayout } from '../../layout/themeLayout';
import { BlockComponent } from './BlockComponent';
import { FilterButton } from './FilterButton';
import { Content, FakeTableHeader } from './styles';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { SelectTipOper } from './SelectTipOper';
import toast from 'react-hot-toast';

interface ItemsProps {
  nunota: number;
  tipo: number;
  cor: 'AMARELO' | 'AZUL';
  titulo: string;
}

interface DataProps {
  data: string;
  parceiro: string;
  items: ItemsProps[];
  id: number;
}

interface FetchProps {
  data: DataProps[];
  total: number;
}

export function FilaOfNotas() {
  const {
    dataFetch,
    ApplyFilters,
    currentPage,
    itensPerPage,
    setCurrentPage,
    filters,
    handleAddFilters,
    clearFilters,
    isFetching,
    setColumnOrdenation,
    setItensPerPage,
  } = useFetch<FetchProps>({
    baseUrl: '/quotes',
    isArray: true,
  });
  const [selectedNumber, setSelectedNumber] = useState({ nunota: 0, nucomp: 0 });
  const history = useHistory();
  const [sorterOrder, setSorterOrder] = useState({ order: 'ASC', field: 'PARC' });

  function onChangeSorterOrder(sorter: string, order: 'DESC' | 'ASC') {
    setColumnOrdenation(`${sorter}:${order}`);
    setSorterOrder({ field: sorter, order });
  }

  const [isOpen, setIsOpen] = useState(false);
  const [tip, setIsTip] = useState('');
  function handleOK() {
    if (!tip) {
      toast.error('Selecione um tipo de operação');
      return;
    }

    history.push('/admin/orcamento/add?type=' + tip);
  }

  return (
    <ThemeLayout>
      <PageHeader
        ghost
        title="Orçamentos"
        buttons={[
          <div key="1" className="page-header-actions">
            {filters.length > 0 && (
              <Button onClick={clearFilters} danger type="primary">
                Limpar filtros
              </Button>
            )}
            ,
            <FilterButton
              ApplyFilters={ApplyFilters}
              filters={filters}
              handleAddFilters={handleAddFilters}
              clearFilters={clearFilters}
            />
            <Button onClick={() => setIsOpen(true)} type="primary">
              Adicionar
            </Button>
          </div>,
        ]}
      />

      <Modal
        title="Selecione um tipo de operação"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => handleOK()}
      >
        <SelectTipOper onChange={setIsTip} />
      </Modal>

      <Main>
        <Content>
          <FakeTableHeader>
            <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="data">
              Data
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                  <CaretUpOutlined
                    size={5}
                    style={{
                      height: '0.5rem',
                      color: sorterOrder.field === 'Created_at' && sorterOrder.order === 'ASC' ? '#40499f' : '#c6c6c6',
                    }}
                    onClick={() => onChangeSorterOrder('Created_at', 'ASC')}
                  />
                </span>
                <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                  <CaretDownOutlined
                    size={5}
                    style={{
                      height: '0.5rem',
                      color: sorterOrder.field === 'Created_at' && sorterOrder.order === 'DESC' ? '#40499f' : '#c6c6c6',
                    }}
                    onClick={() => onChangeSorterOrder('Created_at', 'DESC')}
                  />
                </span>
              </div>
            </p>
            <p
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              className="pedido-cliente"
            >
              Parceiro/Cliente
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                  <CaretUpOutlined
                    size={5}
                    style={{
                      height: '0.5rem',
                      color: sorterOrder.field === 'PARC' && sorterOrder.order === 'ASC' ? '#40499f' : '#c6c6c6',
                    }}
                    onClick={() => onChangeSorterOrder('PARC', 'ASC')}
                  />
                </span>
                <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                  <CaretDownOutlined
                    size={5}
                    style={{
                      height: '0.5rem',
                      color: sorterOrder.field === 'PARC' && sorterOrder.order === 'DESC' ? '#40499f' : '#c6c6c6',
                    }}
                    onClick={() => onChangeSorterOrder('PARC', 'DESC')}
                  />
                </span>
              </div>
            </p>
            <p
              className="orcamentos"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              Orçamento/Pedidos
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                  <CaretUpOutlined
                    size={5}
                    style={{
                      height: '0.5rem',
                      color: sorterOrder.field === 'NUNOTA' && sorterOrder.order === 'ASC' ? '#40499f' : '#c6c6c6',
                    }}
                    onClick={() => onChangeSorterOrder('NUNOTA', 'ASC')}
                  />
                </span>
                <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                  <CaretDownOutlined
                    style={{
                      color: sorterOrder.field === 'NUNOTA' && sorterOrder.order === 'DESC' ? '#40499f' : '#c6c6c6',
                    }}
                    onClick={() => onChangeSorterOrder('NUNOTA', 'DESC')}
                  />
                </span>
              </div>
            </p>
          </FakeTableHeader>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            {isFetching ? (
              <p
                style={{
                  height: '100vh',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spin />
              </p>
            ) : (
              <>
                {dataFetch?.data.map(item => (
                  <BlockComponent
                    key={item.id}
                    selectedNumber={selectedNumber}
                    setSelectedNumber={setSelectedNumber}
                    id={item.id}
                    data={item.data}
                    items={item.items}
                    parceiro={item.parceiro}
                  />
                ))}
              </>
            )}
          </div>

          <div>
            <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
              <Pagination
                current={currentPage}
                onChange={(e, i) => setCurrentPage(e)}
                defaultCurrent={1}
                total={dataFetch?.total}
                showSizeChanger={false}
              />
              <Select defaultValue={itensPerPage} onChange={setItensPerPage}>
                <Select.Option value={10}>10 / pagina</Select.Option>
                <Select.Option value={30}>30 / pagina</Select.Option>
                <Select.Option value={50}>50 / pagina</Select.Option>
              </Select>
            </div>
          </div>
        </Content>
      </Main>
    </ThemeLayout>
  );
}
