import { Button, Checkbox, Input, Modal, Switch } from 'antd';
import React, { useState } from 'react';
import { useFetch } from '../../../../hooks/useFetch';
import { Container, Table } from './styles';
import { api } from '../../../../services/api';
import toast from 'react-hot-toast';
import FeatherIcon from 'feather-icons-react';

import { Button as OtherButton } from '../../../../components/buttons/buttons';
import { TableWrapper } from '../../../../container/styled';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { TopToolBox } from '../../../conference/Style';

export function Plans() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { dataFetch, refetch } = useFetch<any[]>({
    baseUrl: 'subscriptions',
    isLinkProps: false,
  });

  const [inputValue, setInputValue] = useState('1');

  const { dataFetch: plansFetch } = useFetch<any[]>({
    baseUrl: 'plans',
    isLinkProps: false,
  });

  async function handleAdd(planId: string | number) {
    try {
      const { data } = await api.post('/subscriptions', { plan_id: planId, max_users: inputValue });
      if (data.success === false) {
        toast.error(data.erro || 'Não foi possível cadastrar o plano');
      } else {
        toast.success('Criado com sucesso!');
        setModalIsOpen(false);
      }
    } catch (error) {
      const err: any = error;
      toast.error(err?.response?.erro || 'Não foi possível cadastrar o plano');
    }
    setInputValue('1');
    refetch();
  }

  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteOpenModal = (e: string) => setModalDeleteIsOpen(e);
  const handleDeleteCloseModal = () => setModalDeleteIsOpen(null);

  const [modalEditIsOpen, setModalEditIsOpen] = useState<string | null>(null);

  const handleEditOpenModal = (data: string, id: string) => {
    setModalEditIsOpen(id);
    setInputValue(data.toString());
  };
  const handleEditCloseModal = () => {
    setInputValue('1');
    setModalEditIsOpen(null);
  };

  async function handleDelete() {
    setIsLoading(true);
    if (!modalDeleteIsOpen) return;
    await api.delete('/subscriptions/' + modalDeleteIsOpen);
    handleDeleteCloseModal();
    refetch();
    setIsLoading(false);
  }

  async function handleEdit() {
    setIsLoading(true);
    if (!modalEditIsOpen) return;
    await api.put('/subscriptions/' + modalEditIsOpen, { plan_id: modalEditIsOpen, max_users: inputValue });
    handleEditCloseModal();
    refetch();
    setIsLoading(false);
  }

  return (
    <Container>
      <header
        style={{
          background: 'transparent',
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h1>Módulos Adquiridos</h1>
        <Button type="primary" size="large" onClick={() => setModalIsOpen(true)}>
          Adicionar Módulo
        </Button>
      </header>

      <TableWrapper className="table-order table-responsive">
        <Table>
          <tr>
            <th>Nome</th>
            <th>Status</th>
            <th>Preço</th>
            <th>N° Licencas</th>
            <th>Total Usuários</th>
            <th></th>
          </tr>

          {dataFetch?.map(item => (
            <tr>
              <td style={{ borderRadius: '6px 0 0 6px', whiteSpace: 'normal' }}>
                <strong>{item.plan.name}</strong>
                <br></br>
                <small>{item.plan.description}</small>
              </td>
              <td>
                <span
                  style={{
                    color: '#20C9A5',
                    background: '#E2F4F0',
                    border: `0px solid ${'#b7eb8f'}`,
                    padding: '3px 10px',
                    borderRadius: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    boxSizing: 'border-box',
                    fontVariant: 'tabular-nums',
                    listStyle: 'none',
                    fontFeatureSettings: 'tnum',
                    display: 'inline-block',
                    height: 'auto',
                    fontSize: '12px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                    transition: 'all 0.3s',
                  }}
                >
                  Ativo
                </span>
              </td>
              <td>
                {Number(item.plan.amount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} / usuário /
                mês
              </td>
              <td style={{ borderRadius: '0 6px 6px 0', whiteSpace: 'normal' }}>{item.max_users}</td>
              <td style={{ borderRadius: '0 6px 6px 0', whiteSpace: 'normal' }}>{item.total_users}</td>
              <td>
                <div className="table-actions">
                  <OtherButton
                    onClick={() => handleDeleteOpenModal(item.id)}
                    className="btn-icon"
                    type="danger"
                    shape="circle"
                  >
                    <FeatherIcon icon="trash-2" size={16} />
                  </OtherButton>

                  <OtherButton
                    className="btn-icon"
                    type="info"
                    onClick={() => handleEditOpenModal(item.max_users, item.id)}
                    to="#"
                    shape="circle"
                  >
                    <FeatherIcon icon="edit" size={16} />
                  </OtherButton>
                </div>
              </td>
            </tr>
          ))}
        </Table>

        <Modal
          title="Deletar"
          visible={!!modalDeleteIsOpen}
          onOk={handleDelete}
          onCancel={handleDeleteCloseModal}
          okText="Confirmar"
          okType="danger"
          confirmLoading={isLoading}
        >
          <p>Deseja mesmo deletar esse módulo?</p>
        </Modal>
        <Modal
          title="Editar"
          visible={!!modalEditIsOpen}
          onOk={handleEdit}
          onCancel={handleEditCloseModal}
          okText="Confirmar"
          okType="danger"
          confirmLoading={isLoading}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <strong>Quantidade de Licenças:</strong>
            <Input
              type="number"
              style={{ maxWidth: '200px' }}
              value={inputValue}
              onChange={e => {
                if (Number(e.target.value) >= 1) {
                  setInputValue(e.target.value);
                }
              }}
            />
          </div>
        </Modal>
      </TableWrapper>

      <Modal
        title="Adicionar Módulo"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={1200}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              padding: '1rem 1rem 0 1rem',
              alignItems: 'center',
              gap: '1rem',
              justifyContent: 'flex-end',
            }}
          >
            <strong>Quantidade de Licenças:</strong>
            <Input
              type="number"
              style={{ maxWidth: '200px' }}
              value={inputValue}
              onChange={e => {
                if (Number(e.target.value) > 1) {
                  setInputValue(e.target.value);
                }
              }}
            />
          </div>

          <div style={{ padding: '0 1rem 1rem 1rem' }}>
            <Table>
              <tr>
                <th>Nome</th>

                <th>Preço</th>
                <th></th>
              </tr>

              {plansFetch?.map(item => (
                <tr
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.1) -4px 1px 9px 2px',
                    borderRadius: '6px 0 0 6px',
                    whiteSpace: 'normal',
                    fontWeight: 600,
                  }}
                >
                  <td>
                    <strong>{item.name}</strong>
                    <br></br>
                    <small
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      {item.description}
                    </small>
                  </td>

                  <td>
                    {Number(item.amount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} / usuário /
                    mês
                  </td>
                  <td>
                    <div>
                      <Button onClick={() => handleAdd(item.id)} type="primary">
                        Adicionar Módulo
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        </div>
      </Modal>
    </Container>
  );
}
