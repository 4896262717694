import React, { useEffect, useState } from 'react';
import { Container } from './styles';

interface ButtonOrcProps {
  data: {
    nunota: number;
    tipo: number;
    cor: 'AMARELO' | 'AZUL';
    titulo: string;
  };
  selectedNumber: number;
  setTipo: (e: number) => void;
  setTipoName: (e: string) => void;
  setSelectedNumber: (e: number) => void;
}

export function ButtonOrc({ setTipoName, setTipo, data, selectedNumber, setSelectedNumber }: ButtonOrcProps) {
  const isSelected = selectedNumber === data.nunota;

  const toggleName = () => {
    setTipoName(data.titulo);
    setTipo(data.tipo);
    setSelectedNumber(data.nunota);
  };

  function color() {
    switch (data.cor) {
      case 'AMARELO':
        return '#ff8c00';
      case 'AZUL':
        return '#00b4d8';
      default:
        return '#40916c';
    }
  }

  function bgColor() {
    switch (data.cor) {
      case 'AMARELO':
        return '#fcefb4';
      case 'AZUL':
        return '#caf0f8';
      default:
        return '#b7efc5';
    }
  }

  return (
    <Container>
      <button // Orçamento
        disabled={isSelected}
        onClick={toggleName}
        style={{
          color: color(),
          background: bgColor(),
          border: `0px solid ${color()}`,
          padding: '3px 10px',
          borderRadius: 10,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'start',
          boxSizing: 'border-box',
          fontVariant: 'tabular-nums',
          listStyle: 'none',
          fontFeatureSettings: 'tnum',
          display: 'inline-block',
          height: 'auto',
          fontSize: '12px',
          lineHeight: '20px',
          whiteSpace: 'nowrap',
          transition: 'all 0.3s',
        }}
      >
        {data.titulo} <br />
        {data.nunota}
      </button>
    </Container>
  );
}
