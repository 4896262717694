import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { api } from '../services/api';
import { useAuth } from './useAuth';

interface IUseFetchProps {
  baseUrl: string;
  isLinkProps?: boolean;
  isArray?: boolean;
}

interface FilterProps {
  key: string;
  content: string;
}

export function useFetch<T = unknown>({ baseUrl, isLinkProps = true, isArray = true }: IUseFetchProps) {
  const [dataFetch, setDataFetch] = useState<T | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [filterLink, setFilterLink] = useState('');

  const [itensPerPage, setItensPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItens, setTotalItens] = useState(0);

  const [filters, setFilters] = useState<FilterProps[]>([] as FilterProps[]);
  const [search, setSearch] = useState('');
  const { errorSessionTokenControl } = useAuth();
  const [columnOrdenation, setColumnOrdenation] = useState('');

  const linkFetch = `${baseUrl}?limit=${itensPerPage}&page=${currentPage}&search=${search}&sortBy=${columnOrdenation}${filterLink}`;

  const fetchAPI = useCallback(async () => {
    let variableFetchLink = linkFetch;

    if (!isLinkProps) {
      variableFetchLink = `${baseUrl}`;
    }

    setIsFetching(true);
    try {
      const { data } = await api.get(variableFetchLink);

      if (isArray) {
        setDataFetch(data.data);
        setTotalItens(data.meta.total);
      } else {
        setDataFetch(data);
        setTotalItens(0);
      }

      const response1 = data.data;
      if (response1?.success === false) {
        errorSessionTokenControl(response1);
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
    }
  }, [baseUrl, errorSessionTokenControl, isArray, isLinkProps, linkFetch]);

  async function refetch() {
    await fetchAPI();
  }

  function ApplyFilters() {
    const res = filters.map(item => {
      return `${item.key}${item.content}`;
    });
    setFilterLink(res.join(''));
  }

  function applyFiltersNow(object: FilterProps) {
    let initialArray = filters;
    let arrayFilter = initialArray.filter(item => item.key !== object.key);
    let finalArray = [...arrayFilter, object];
    const res = finalArray.map(item => {
      return `${item.key}${item.content}`;
    });

    setFilters(finalArray);
    setFilterLink(res.join(''));
  }

  function handleAddFilters(object: FilterProps) {
    let initialArray = filters;
    let arrayFilter = initialArray.filter(item => item.key !== object.key);
    let finalArray = [...arrayFilter, object];
    setFilters(finalArray);
  }

  function clearFilters() {
    setFilters([]);
    setFilterLink('');
  }

  function handleAddFiltersWithApplyFilters(object: FilterProps) {
    let initialArray = filters;
    let arrayFilter = initialArray.filter(item => item.key !== object.key);
    let finalArray = [...arrayFilter, object];
    const res = finalArray.map(item => {
      return `${item.key}${item.content}`;
    });

    setFilters(finalArray);
    setFilterLink(res.join(''));
  }

  const [cookies] = useCookies(['deskioapp-front-token']);
  const token = cookies['deskioapp-front-token'];

  useEffect(() => {
    if (token) {
      api.defaults.headers.common.authorization = `Bearer ${token}`;
      fetchAPI();
    }
  }, [fetchAPI, token]);

  useEffect(() => {
    if (token) fetchAPI();
  }, [linkFetch, fetchAPI, token]);

  return {
    dataFetch,
    isFetching,
    currentPage,
    setCurrentPage,
    clearFilters,
    itensPerPage,
    setItensPerPage,
    totalItens,
    filters,
    handleAddFilters,
    handleAddFiltersWithApplyFilters,
    search,
    setSearch,
    columnOrdenation,
    setColumnOrdenation,
    refetch,
    ApplyFilters,
    applyFiltersNow,
  };
}
