import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IUserProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { Badge } from 'antd';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (contactId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IUserProps[];
}

export function TableColumnController({ dataList, handleDeleteFunction, refetch }: ITableColumnControllerProps) {
  const MaxWidthColumn: React.FC<{ width: number }> = ({ width, children }) => (
    <div
      style={{
        maxWidth: width,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </div>
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'sankhya_user',
      sorter: true,
    },

    {
      title: 'Sankhya ID',
      dataIndex: 'sankhya_id',
      sorter: true,
    },

    {
      title: 'Usuário Webservice',
      dataIndex: 'is_webservice',
      sorter: true,
      render: (_, data) => {
        return (
          <span
            style={{
              color: data.is_webservice === true ? '#396dd6' : '#d8ae3a',
              background: data.is_webservice === true ? '#d3e2ff' : '#f4eacd',
              padding: '3px 10px',
              borderRadius: 40,
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              boxSizing: 'border-box',
              fontVariant: 'tabular-nums',
              listStyle: 'none',
              fontFeatureSettings: 'tnum',
              display: 'inline-block',
              height: 'auto',
              fontSize: '12px',
              lineHeight: '20px',
              whiteSpace: 'nowrap',
              transition: 'all 0.3s',
            }}
          >
            {data.is_webservice === true ? 'SIM' : 'NÃO'}
          </span>
        );
      },
    },

    {
      title: 'Último Login',
      dataIndex: 'last_login',
      sorter: true,
      render: (_, data) => new Date(data.last_login).toLocaleDateString('pt-br'),
    },

    {
      title: 'Adicionado em',
      dataIndex: 'created_at',
      sorter: true,
      render: (_, data) => new Date(data.created_at).toLocaleDateString('pt-br'),
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            {item.is_webservice === true ? '' : <TableButtonEdit contactId={String(item.id)} refetch={refetch} data={item} />}
            {item.is_webservice === true ? '' : <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />}
            
            
          </div>
        );
      },
    },
  ];

  return { columns, data: dataList };
}
