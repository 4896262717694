import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Form, FormInstance, Input, Select } from 'antd';

import { FiltersWrapper } from '../../styles';
import moment from 'moment';
import { api } from '../../../../services/api';
import { SelectPar } from './SelectPar';

type ITypeFilter = {
  content: string;
  key: string;
};

type FiltersProps = {
  filters: ITypeFilter[];
  clearFilters: () => void;
  formRef: React.RefObject<FormInstance<any>>;
  handleAddFilters: (event: { key: string; content: string }) => void;
  handleApplyFilters: () => void;
};

export function FilterDrawer({ handleAddFilters, formRef, clearFilters, filters, handleApplyFilters }: FiltersProps) {
  function handleChangeFilters([date1, date2]: any) {
    if (!date1 || !date2) return;
    const format = (date?: moment.Moment) => (date ? date.format('DD-MM-YYYY') : '');
    handleAddFilters({ key: '&createdAt=', content: `${format(date1)},${format(date2)}` });
  }

  function handleClearFilters() {
    clearFilters();
    if (formRef.current) formRef.current.resetFields();
  }

  function handleFilterNumberNote(event: string) {
    handleAddFilters({ key: '&nota=', content: event });
  }

  function handleFilterStatus(event: string) {
    handleAddFilters({ key: '&status=', content: event });
  }

  function handleFilterVendedor(event: string) {
    handleAddFilters({ key: '&CODVEND=', content: event });
  }

  function handleFilterMotorista(event: string) {
    handleAddFilters({ key: '&CODMOT=', content: event });
  }

  function handleFilterParceiro(event: string) {
    handleAddFilters({ key: '&CODPARC=', content: event });
  }

  const [vendedor, setVendedor] = useState<{ CODVEND: number; VENDEDOR: string }[]>();
  const [motorista, setMotorista] = useState<{ CODMOT: number; MOTORISTA: string }[]>();
  useEffect(() => {
    api.get('/list-sellers').then(response => setVendedor(response.data.data));
    api.get('/list-drivers').then(response => setMotorista(response.data.data));
  }, []);

  return (
    <FiltersWrapper>
      <Form ref={formRef} layout="vertical">
        <Form.Item name="item1" label="Data Emissão">
          <DatePicker.RangePicker
            placeholder={['Data inicial', 'Data final']}
            format="DD/MM/YYYY"
            onChange={dates => !!dates && handleChangeFilters(dates)}
          />
        </Form.Item>

        <Form.Item name="item2" label="Número Nota">
          <Input name="numberNote" placeholder="Número Nota" onChange={e => handleFilterNumberNote(e.target.value)} />
        </Form.Item>

        <Form.Item name="item3" label="Status">
          <Select onChange={handleFilterStatus}>
            <Select.Option value={'Pendente'}>Pendente</Select.Option>
            <Select.Option value={'ProcEntrega'}> Processo de Entrega</Select.Option>
            <Select.Option value={'Problema'}> Problema</Select.Option>
            <Select.Option value={'Entregue'}>Entregue</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="item4" label="Vendedor">
          <Select onChange={handleFilterVendedor}>
            {vendedor?.map(item => (
              <Select.Option value={item.CODVEND}>{item.VENDEDOR}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="item5" label="Motorista">
          <Select onChange={handleFilterMotorista}>
            {motorista?.map(item => (
              <Select.Option value={item.CODMOT}>{item.MOTORISTA}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <SelectPar onChange={e => handleFilterParceiro(e)} />
      </Form>

      <footer>
        {filters.length !== 0 && (
          <Button type="primary" danger size="large" onClick={handleClearFilters}>
            Limpar filtros
          </Button>
        )}
        <Button type="primary" size="large" onClick={handleApplyFilters}>
          Adicionar filtros
        </Button>
      </footer>
    </FiltersWrapper>
  );
}
