interface ICheckUserRoleProps {
  permissions: string[] | null | undefined;
  userPermission: string;
  signOut: () => void;
  history: any;
}

export function checkUserRole({ permissions, userPermission, signOut, history }: ICheckUserRoleProps) {
  if (!permissions) {
    return;
  }

  if (!permissions.includes(userPermission)) {
    // signOut();
    // history.push('/');
  }
}
