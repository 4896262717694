import React, { useRef, useState } from 'react';

import { Button, Input, Form as AntdForm } from 'antd';
import { CardWrapper, FormWrapper, Container } from '../styles';
import { api } from '../../../services/api';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

interface IFormInitialFields {
  userId: number;
  name: string;
  email: string;
  status: number;
  tech_name: string;
  phone_tech: number;
  email_tech: string;
  url_sankhya: string;
  user_webservice: string;
  address: string;
  number: number;
  complement: string;
  district: string;
  cep: number;
  city: string;
  uf: string;
}

interface IFormContact {
  formType: 'put' | 'post';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  contactId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  contactId,
  initialFields = {
    name: '',
    userId: 1,
    email: '',
    address: '',
    cep: 1,
    complement: '',
    city: '',
    district: '',
    email_tech: '',
    number: 1,
    phone_tech: 1,
    status: 1,
    tech_name: '',
    uf: '',
    url_sankhya: '',
    user_webservice: '',
  },
}: IFormContact) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([] as UploadFile[]);
  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    try {
      if (formType === 'post') {
        await api.post('/companies', { name: data.name });
      } else {
        if (contactId) await api.put(`/companies/${contactId}`, { name: data.name });
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
      setFileList([]);
    } catch (err) {}

    setIsLoading(false);
    refetch();
  }

  return (
    <div>
      <FormWrapper>
        <AntdForm
          name="basic"
          onFinish={handleSubmit}
          style={{ padding: '30px' }}
          layout="vertical"
          initialValues={initialFields}
          ref={formRef}
        >
          <Container>
            <div>
              <CardWrapper type="inner" title="Informações Gerais">
                <AntdForm.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome obrigatório' }]}>
                  <Input placeholder="Nome Completo" />
                </AntdForm.Item>

                <AntdForm.Item label="Email" name="email" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input placeholder="Email" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Empresa"
                  name="company"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input placeholder="Nome da Empresa" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Telefone"
                  name="phone"
                  rules={[{ required: true, message: 'Telefone obrigatório' }]}
                >
                  <Input placeholder="Telefone" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Instagram"
                  name="instagram"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input placeholder="Perfil Instagram" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Facebook"
                  name="facebook"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input placeholder="Página Facebook" />
                </AntdForm.Item>

                <AntdForm.Item label="Linkedin" name="name" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input placeholder="Perfil Linkedin" />
                </AntdForm.Item>
              </CardWrapper>
            </div>

            <div>
              <CardWrapper type="inner" title="Informações Gerais">
                <AntdForm.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome obrigatório' }]}>
                  <Input placeholder="Nome Completo" />
                </AntdForm.Item>

                <AntdForm.Item label="Email" name="email" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input placeholder="Email" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Empresa"
                  name="company"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input placeholder="Nome da Empresa" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Telefone"
                  name="phone"
                  rules={[{ required: true, message: 'Telefone obrigatório' }]}
                >
                  <Input placeholder="Telefone" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Instagram"
                  name="instagram"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input placeholder="Perfil Instagram" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Facebook"
                  name="facebook"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input placeholder="Página Facebook" />
                </AntdForm.Item>

                <AntdForm.Item label="Linkedin" name="name" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input placeholder="Perfil Linkedin" />
                </AntdForm.Item>
              </CardWrapper>
            </div>
          </Container>
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        </AntdForm>
      </FormWrapper>
    </div>
  );
}
