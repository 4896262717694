import React, { useState, Suspense, useEffect } from 'react';
import Cookie from 'js-cookie';
import { Row, Slider, Col, Spin, Input, Affix, Drawer, Button, Select, Switch as AntSwitch, Divider } from 'antd';
import { Switch } from 'react-router-dom';
import { PageHeader } from '~/components/page-headers/page-headers';
import { Main } from '~/container/styled';
import { TopToolBox } from './Style';
import EmptyMessage from '~/components/emptyMessage';
import Grid from './overview/Grid';
import DetailsRight from './overview/DetailsRight';
import { SEARCH_PRODUCTS_URL } from '~/utility/useLinks';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { ThemeLayout } from '../../layout/themeLayout';
import { useAuth } from '../../hooks/useAuth';
import { checkUserRole } from '../../utility/checkUserRole';
import Cookies from 'js-cookie';

const { Search } = Input;

function Products() {
  const [isLoader, setIsLoader] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [data, setData] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const token = Cookie.get('deskioapp-front-token');
  const jstoken = Cookie.get('strutural-jsessionID');

  const history = useHistory();
  const { errorSessionTokenControl, user, signOut } = useAuth();
  const [unicPrice, setUnicPrice] = useState('');
  const [priceQuantity, setPriceQuantity] = useState(0);
  useEffect(() => {
    if (searchData?.length > 0) {
      const data = searchData.map(item => {
        const entries = Object.entries(item);
        const newEntries = entries.map(entry => {
          return [entry[0].replaceAll('?', 'ç'), entry[1]];
        });

        return Object.fromEntries(newEntries);
      });
      const products = Object.entries(data[0]).filter(item => item[0].includes('Preço') && !item[0].includes('PROMO'));
      setPriceQuantity(products.length);
      if (products.length > 0) setUnicPrice(products[0][0].replaceAll('?', 'ç'));
    }
  }, [searchData]);

  const cookie = JSON.parse(Cookies.get('deskioapp-account-info') || '');
  useEffect(() => {
    checkUserRole({
      permissions: user?.permissions,
      userPermission: 'CONSULTAPRODUTOS',
      signOut,
      history,
    });
  }, []);

  // Filtro de promoção
  const [filterPromocao, setFilterPromocao] = useState(false);
  function toogleFilterPromocao() {
    setFilterPromocao(oldState => !oldState);
  }

  // Filtro de marcas
  const [marcas, setMarcas] = useState([]);
  const [selectedMarcas, setSelectedMarcas] = useState([]);
  function toogleCheckboxMark(marca) {
    const alreadyExists = selectedMarcas.find(oldMarca => oldMarca === marca);
    if (alreadyExists) setSelectedMarcas(old => old.filter(oldMarca => oldMarca !== marca));
    else setSelectedMarcas(old => (old ? [...old, marca] : [marca]));
  }
  useEffect(() => {
    const array = [];
    if (searchData?.length > 0) {
      searchData.forEach(item => {
        const alreadyExists = array.find(marca => marca === item?.Cadastro_MARCA?.$?.trim());
        if (item.Cadastro_MARCA?.$ && !alreadyExists) {
          array.push(item.Cadastro_MARCA.$.trim());
        }
      });
    }
    setMarcas(array);
  }, [searchData]);

  // Filtro de dinheiro
  const [priceMedia, setPriceMedia] = useState({ min: 0, max: 0 });
  const [sliderPriceValue, setSliderPriceValue] = useState([0, 0]);
  const [priceMediaValue, setPriceMediaValue] = useState([0, 0]);

  let timeout;
  const debounce = (func, wait) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, wait);
  };

  function onChange(value) {
    setSliderPriceValue(value);
    debounce(() => setPriceMediaValue(value), 500);
  }

  useEffect(() => {
    if (!unicPrice) return;

    const data = searchData.map(item => {
      const entries = Object.entries(item);
      const newEntries = entries.map(entry => {
        return [entry[0].replaceAll('?', 'ç'), entry[1]];
      });

      return Object.fromEntries(newEntries);
    });

    let min = data?.length > 0 ? Number(data[0][unicPrice].$ || 0) : 0;
    let max = 0;

    if (data?.length > 0) {
      data.forEach(produto => {
        const isPromocao = produto.ISPROMOCAO === 'true';

        if (isPromocao) {
          if (Number(produto.Perc_PROMO.$ || 0) > max) max = Number(produto.Perc_PROMO.$);
          if (Number(produto.Perc_PROMO.$ || 0) > 0 && Number(produto.Perc_PROMO.$ || 0) < min)
            min = Number(produto.Perc_PROMO.$);
        } else {
          if (Number(produto[unicPrice].$ || 0) > max) max = Number(produto[unicPrice].$);
          if (Number(produto[unicPrice].$ || 0) > 0 && Number(produto[unicPrice].$ || 0) < min)
            min = Number(produto[unicPrice].$);
        }
      });
    }

    setPriceMedia({ min, max });
    onChange([min, max]);
  }, [searchData, unicPrice]);

  // AplicarFiltros
  useEffect(() => {
    if (searchData === null || searchData?.length < 1) {
      return;
    }
    const array = [];

    console.log(searchData);

    const data = searchData.map(item => {
      const entries = Object.entries(item);
      const newEntries = entries.map(entry => {
        return [entry[0].replaceAll('?', 'ç'), entry[1]];
      });

      return Object.fromEntries(newEntries);
    });

    if (data?.length > 0) {
      data.forEach(produto => {
        if (filterPromocao) {
          if (produto.ISPROMOCAO === 'true') array.push(produto);
        } else array.push(produto);
      });
    }

    let filterMarca = [];
    if (selectedMarcas.length > 0) {
      array.forEach(produto => {
        if (selectedMarcas.includes(produto.Cadastro_MARCA.$.trim())) {
          filterMarca.push(produto);
        }
      });
    } else {
      filterMarca = array;
    }

    let filterPrice = [];
    if (priceMediaValue[0] !== priceMedia.min || priceMediaValue[1] !== priceMedia.max) {
      filterMarca.forEach(produto => {
        const isPromocao = produto.ISPROMOCAO === 'true';
        if (isPromocao) {
          if (
            Number(produto.Perc_PROMO.$) >= priceMediaValue[0] &&
            Number(produto.Perc_PROMO.$) <= priceMediaValue[1]
          ) {
            filterPrice.push(produto);
          }
        } else {
          if (
            Number(produto[unicPrice].$ || 0) >= priceMediaValue[0] &&
            Number(produto[unicPrice].$ || 0) <= priceMediaValue[1]
          ) {
            filterPrice.push(produto);
          }
        }
      });
    } else {
      filterPrice = filterMarca;
    }

    setData(filterPrice);
  }, [searchData, filterPromocao, selectedMarcas, priceMedia, priceMediaValue]);

  async function handleSearch(searchText) {
    if (searchText) {
      setIsLoader(true);
      setItemSelected(null);
      await axios
        .post(
          SEARCH_PRODUCTS_URL,
          {
            termo: searchText,
          },
          {
            headers: {
              jsessionID: jstoken,
              authorization: `Bearer ${token}`,
            },
          },
        )
        .then(response => {
          const responseBody = response.data.responseBody;
          const responseData = response.data;

          if (responseData?.success === false) {
            errorSessionTokenControl(responseData);
          }

          if (!responseBody?.produtos.produto) {
            setSearchData(null);
            setIsLoader(false);
            return;
          }

          if (Array.isArray(responseBody.produtos.produto)) {
            const products = responseBody.produtos.produto;
            setSearchData(products);
          } else {
            const array = [];
            array.push(responseBody.produtos.produto);
            const products = array;
            setSearchData(products);
          }

          setIsLoader(false);
        })
        .catch(err => errorSessionTokenControl(err));
    }
  }

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    // const windowWidth = window.screen.width;
    if (itemSelected) {
      showDrawer();
    }
  }, [itemSelected]);

  function clearFilters() {
    setFilterPromocao(false);
    setSelectedMarcas([]);
    onChange([priceMedia.min, priceMedia.max]);
  }

  const isFilters =
    !!filterPromocao ||
    selectedMarcas.length > 0 ||
    priceMediaValue[0] !== priceMedia.min ||
    priceMediaValue[1] !== priceMedia.max;
  return (
    <ThemeLayout>
      <PageHeader
        ghost
        title="Consulta de Produtos"
        buttons={[
          <>
            {isFilters && searchData?.length > 0 && (
              <Button type="primay" danger onClick={clearFilters}>
                Limpar filtros
              </Button>
            )}
          </>,
        ]}
      />

      <Main>
        <Row gutter={5}>
          <Col flex={1} className="product-content-col" xxl={14} lg={14} md={14} xs={24}>
            <TopToolBox>
              <Row gutter={[0, 15]} xs={24} justify="space-between">
                <Col xxl={24} lg={24} xs={24} style={{ display: 'flex', alignItems: 'end', gap: '1rem' }}>
                  <Search
                    placeholder="Digite o nome do produto"
                    size="default"
                    onSearch={text => handleSearch(text)}
                    style={{ width: '100%' }}
                  />

                  {/* {searchData?.length > 0 && (
                    <Button type="primay" onClick={() => setDrawerFiltersIsOpen(true)}>
                      Filtrar
                    </Button>
                  )} */}
                </Col>
              </Row>
            </TopToolBox>

            {isLoader ? (
              <div className="spin d-flex align-center-v">
                <Spin />
              </div>
            ) : (
              <>
                {!searchData ? (
                  <Col xs={24}>
                    <div className="spin">
                      <EmptyMessage text="Nenhuma informação" iconSize={70} textSize={20} />
                    </div>
                  </Col>
                ) : (
                  <Switch>
                    <Suspense
                      fallback={
                        <div className="spin d-flex align-center-v">
                          <Spin />
                        </div>
                      }
                    >
                      <Grid
                        data={data.map(item => {
                          const entries = Object.entries(item);
                          const newEntries = entries.map(entry => {
                            return [entry[0].replaceAll('?', 'ç'), entry[1]];
                          });

                          return Object.fromEntries(newEntries);
                        })}
                        priceQuantity={priceQuantity}
                        priceLabel={unicPrice}
                        action={data => setItemSelected(data)}
                      />
                    </Suspense>
                  </Switch>
                )}
              </>
            )}
          </Col>
          <Col className="product-sidebar-col" xxl={10} xl={10} lg={10} md={10} xs={24} style={{ paddingLeft: 25 }}>
            {cookie.price_label && searchData?.length > 0 && (
              <div style={{ backgroundColor: '#FFF', padding: '2rem', borderRadius: '0.5rem' }}>
                <h3 style={{ marginBottom: '1rem' }}>Filtros:</h3>

                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  Somente Produtos em Promoção
                  <AntSwitch checked={filterPromocao} onClick={toogleFilterPromocao} />
                </div>

                <Divider />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                  Marcas: <br />
                  {marcas.map(marca => (
                    <div>
                      <input
                        type="checkbox"
                        checked={selectedMarcas.includes(marca)}
                        onClick={() => toogleCheckboxMark(marca)}
                        style={{ marginRight: '1rem' }}
                      />
                      {marca}
                    </div>
                  ))}
                </div>

                <Divider />

                <div>
                  Faixa de preço: ({sliderPriceValue[0].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}{' '}
                  - {sliderPriceValue[1].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })})
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <Slider
                      min={priceMedia.min}
                      max={priceMedia.max}
                      range
                      step={1}
                      style={{ minWidth: '12rem' }}
                      value={sliderPriceValue}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </Col>
          <Drawer width="30rem" title="Detalhes" placement="right" onClose={onClose} visible={drawerVisible}>
            <DetailsRight product={itemSelected} />
          </Drawer>
        </Row>
      </Main>
    </ThemeLayout>
  );
}

export default Products;
