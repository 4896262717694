import React from 'react';
import { Button, DatePicker, Form, Select } from 'antd';

import { FiltersWrapper } from '../../styles';
import moment from 'moment';
import { useFetch } from '../../../../hooks/useFetch';

type ITypeFilter = {
  content: string;
  key: string;
};

type FiltersProps = {
  filters: ITypeFilter[];
  clearFilters: () => void;
  handleAddFilters: (event: { key: string; content: string }) => void;
  handleApplyFilters: () => void;
};

interface UserProps {
  id: number;
  name: string;
}

export function FilterDrawer({ handleAddFilters, clearFilters, filters, handleApplyFilters }: FiltersProps) {
  const { dataFetch } = useFetch<UserProps[]>({ baseUrl: 'users' });

  function handleChangeFilters([date1, date2]: any) {
    const format = (date?: moment.Moment) => (date ? date.format('YYYY-MM-DD') : '');
    handleAddFilters({ key: '&filter.createdAt=$btw:', content: `${format(date1)},${format(date2)}` });
  }

  function handleFilter(event: string) {
    handleAddFilters({ key: '&filter.user=$btw:', content: event });
  }

  return (
    <FiltersWrapper>
      <Form layout="vertical">
        <Form.Item label="Usuários">
          <Select onChange={e => handleFilter(String(e))}>
            {dataFetch?.map(dataItem => (
              <Select.Option key={dataItem.id} value={dataItem.id}>
                {dataItem.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Data criação">
          <DatePicker.RangePicker
            placeholder={['Data inicial', 'Data Final']}
            format="DD/MM/YYYY"
            onChange={dates => handleChangeFilters(dates)}
          />
        </Form.Item>
      </Form>

      <footer>
        {filters.length !== 0 && (
          <Button danger size="large" onClick={clearFilters}>
            Limpar filtros
          </Button>
        )}

        <Button type="primary" size="large" onClick={handleApplyFilters}>
          Adicionar filtros
        </Button>
      </footer>
    </FiltersWrapper>
  );
}
