import React from 'react';
import { Row, Col } from 'antd';
import ProductCardList from './ProductCardList';
import EmptyMessage from '~/components/emptyMessage';

const List = ({ data }) => {
  return (
    <Row gutter={15}>
      {data.length ? (
        data.map((item, index) => {
          return (
            <Col xs={24} key={index}>
              <ProductCardList product={item} />
            </Col>
          );
        })
      ) : (
        <Col xs={24}>
          <div className="spin">
            <EmptyMessage text="Nenhuma informação" iconSize={70} textSize={20} />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default List;
