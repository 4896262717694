import React, { useEffect, useState } from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../../container/styled';
import { ThemeLayout } from '../../layout/themeLayout';
import { Container } from './styles';
import { TableComponent } from './table';
import Cookies from 'js-cookie';
import { Select as FetchSelect } from '../../components/Select';
import axios from 'axios';

import moment from 'moment';
import toast from 'react-hot-toast';

import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';
import { Button, DatePicker, Form, Input, Select, Spin } from 'antd';
import { SelectCodEmp, SelectPar, SelectTipVenda, SelectTipOper } from './Select';
import { useHistory } from 'react-router-dom';
import { treatment } from '../../hooks/treatment';
import ReactInputMask from 'react-input-mask';
import { SelectCodNat } from './Select/SelectCodNat';

const Head = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1 / span 2;

  padding-bottom: 1rem;
  margin-bottom: 1rem;

  border-bottom: 1px solid #e5e5e5;

  button {
    margin: 0px 0px 0px auto !important;
  }
`;

const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 1rem;
  margin-bottom: 1rem;

  border-bottom: 1px solid #e5e5e5;

  button {
    margin: 0px 0px 0px auto !important;
  }
`;

const DatePickerStyle = styled.div`
  .ant-picker {
    width: 100%;
    min-height: 3rem !important;
    max-height: 3rem !important;
  }

  input {
    padding: 1rem !important;
  }
`;

const FormWrapper = styled(Form)`
  display: grid;
  background: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
`;

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

interface formField {
  inputName: string;
  inputLabel: string;
  required: boolean;
  disabled: boolean;
  type: 'select' | 'input';
  options: {
    label: string;
    value: string;
  }[];
}

type defaul = {
  '@attributes': {
    entidade: string;
    nome: string;
  };
  expressao?: object | string;
};

type data = {
  '@attributes': {
    campoDestino?: string;
    nome: string;
  };
};

export function AddOrcamento() {
  const [fields, setFields] = useState<formField[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [codParState, setCodParState] = useState('');
  const history = useHistory();
  const token = Cookies.get('deskioapp-front-token');

  const [defaul, setDefaul] = useState<defaul[] | null>(null);
  const [cabecalhoTop, setCabecalhoTOP] = useState<data[] | null>(null);

  function getDefaul(name: string) {
    const find = defaul?.find(item => item['@attributes'].nome === name);
    if (!find) return '';

    if (typeof find?.expressao !== 'string') return '';
    return find.expressao;
  }

  const type = new URLSearchParams(window.location.search).get('type');

  function initialValues() {
    let initialValues = {};

    defaul?.forEach(item => {
      if (item.expressao && typeof item.expressao === 'string') {
        if (item['@attributes'].nome !== 'DTNEG') {
          initialValues = { ...initialValues, [item['@attributes'].nome]: item.expressao };
        }
      }
    });

    return initialValues;
  }

  async function getArrayFields() {
    const CODTIPOPER = new URLSearchParams(window.location.search).get('type');

    const [dataa, dada2] = await Promise.all([
      await axios.post(
        'https://apiapp.deskio.com.br/api/v1/quotes/get-cabecalho',
        { CODTIPOPER },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      ),
      await axios.post(
        'https://apiapp.deskio.com.br/api/v1/quotes/get-cabecalho-top',
        { CODTIPOPER },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      ),
    ]);

    const { data } = dataa;

    setDefaul(dada2.data.defaults.campo);
    setCabecalhoTOP(dada2.data.data);

    treatment(data);
    treatment(dada2);

    const ignoreFields = [
      'CODPARC',
      'CODTIPVENDA',
      'NUNOTA',
      'CODEMP',
      'CODTIPOPER',
      'DTFATUR',
      'DTNEG',
      'AD_TABELA',
      'DTFATUR',
      'CODVEND',
      'OBSERVACAO',
      'NUMNOTA',
      'CODCONTATO',
    ];

    if (data) {
      const myData: formField[] = [];

      data.forEach(item => {
        if (ignoreFields.includes(item['@attributes'].nome)) return;

        const options = item.opcao?.map(item => {
          return {
            label: item['@attributes'].descricao,
            value: item['@attributes'].valor,
          };
        });

        myData.push({
          inputName: item['@attributes'].nome,
          inputLabel: item['@attributes'].descricao,
          disabled: item['@attributes'].editavel === 'false' ? true : false,
          required: item['@attributes'].requerido === 'true',
          type: !!item.opcao ? 'select' : 'input',
          options: options || [],
        });
      });

      return myData;
    }

    return [];
  }

  async function onSubmit(data: any) {
    setIsLoading(true);
    try {
      const res = await axios.post('https://apiapp.deskio.com.br/api/v1/quotes/save-cabecalho', data, {
        headers: {
          authorization: `bearer ${token}`,
        },
      });
      treatment(res.data);
      history.push('/admin/orcamento/' + res.data.pk.NUNOTA);
      setIsLoading(false);
    } catch (err) {
      let error: any = err;
      toast.error(error?.response?.data?.erro || 'Sem mensagem de erro');
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getArrayFields().then(response => {
      setFields(response);
    });
  }, []);

  return (
    <ThemeLayout>
      <PageHeader
        ghost
        title="Criar novo orçamento"
        buttons={[
          <Button style={{ marginTop: '1rem' }} onClick={() => history.push('/admin/orcamentos')}>
            Voltar
          </Button>,
        ]}
      />

      <Main>
        {defaul && (
          <Container>
            <FormWrapper
              initialValues={{ ...initialValues(), CODTIPOPER: type, DTNEG: moment(new Date(), '') }}
              layout="vertical"
              onFinish={onSubmit}
            >
              <Head>
                <strong>Informações do cabeçalho:</strong>{' '}
              </Head>
              <SelectPar defaultValue={getDefaul('CODPARC')} onChange={setCodParState} />
              <SelectTipVenda defaultValue={getDefaul('CODTIPVENDA')} codParState={codParState} />
              <SelectCodEmp defaultValue={getDefaul('CODEMP')} />
              <SelectTipOper />

              <DatePickerStyle>
                <Form.Item name="DTNEG" label="Data de Negociação">
                  <DatePicker
                    placeholder="Selecionar Data"
                    format="DD/MM/YYYY"
                    defaultValue={moment(new Date(), '')}
                    className="ant-input"
                    style={{ padding: '1rem' }}
                  />
                </Form.Item>
              </DatePickerStyle>

              <FetchSelect
                postUrl="/quotes/save-new-contact-sankhya"
                name="CODCONTATO"
                title="Contato"
                defaultValue={getDefaul('CODCONTATO')}
                formModalInitialValues={{
                  codpar: codParState,
                }}
                methosPostValue={{
                  codpar: codParState,
                }}
                disabled={!codParState}
                placeholder="Pesquise um Contato"
                method="post"
                addText="Adicionar contato"
                addTextModal="Adicionar contato"
                fetchUrl="/quotes/search-codcontato"
                modalContent={
                  <>
                    <Form.Item
                      label="Nome contato"
                      name="NOMECONTATO"
                      rules={[{ required: true, message: 'Título é obrigatório' }]}
                    >
                      <Input placeholder="Nome contato" />
                    </Form.Item>
                    <Form.Item
                      label="Telefone"
                      name="TELEFONE"
                      rules={[{ required: true, message: 'Telefone é obrigatório' }]}
                    >
                      <ReactInputMask className="ant-input" mask="(99) 99999-9999" placeholder="telefone" />
                    </Form.Item>
                    <Form.Item rules={[{ max: 15, message: 'Máximo de 15 caracteres' }]} label="Apelido" name="APELIDO">
                      <Input placeholder="Apelido" />
                    </Form.Item>
                    <Form.Item label="Email" name="EMAIL">
                      <Input placeholder="email" />
                    </Form.Item>

                    <Form.Item name="codpar" rules={[{ required: true, message: 'Título é obrigatório' }]}>
                      <Input type="hidden" placeholder="codpar" />
                    </Form.Item>

                    <Button htmlType="submit" type="primary">
                      Salvar contato
                    </Button>
                  </>
                }
              />

              {fields.map(field => {
                if (
                  cabecalhoTop?.find(item => item['@attributes'].nome === field.inputName)?.['@attributes']
                    ?.campoDestino === 'CODPARC'
                ) {
                  return (
                    <SelectPar
                      name={field.inputName}
                      label={field.inputLabel}
                      defaultValue={getDefaul('CODPARC')}
                      onChange={setCodParState}
                    />
                  );
                }

                if (field.inputName === 'CODNAT') {
                  return <SelectCodNat defaultValue={getDefaul('CODNAT')} />;
                }

                if (field.type === 'input') {
                  return (
                    <Form.Item
                      name={field.inputName}
                      label={field.inputLabel}
                      rules={[{ message: 'Campo obrigatório', required: field.required }]}
                    >
                      <Input defaultValue={getDefaul(field.inputName)} disabled={field.disabled} />
                    </Form.Item>
                  );
                }

                return (
                  <Form.Item
                    name={field.inputName}
                    label={field.inputLabel}
                    rules={[{ message: 'Campo obrigatório', required: field.required }]}
                  >
                    <AntdSelect>
                      {field.options.map(item => (
                        <AntdSelect.Option defaultValue={getDefaul(field.inputName)} value={item.value}>
                          {item.label}
                        </AntdSelect.Option>
                      ))}
                    </AntdSelect>
                  </Form.Item>
                );
              })}

              <Form.Item style={{ marginTop: 'auto' }}>
                <Button loading={isLoading} htmlType="submit" type="primary" size="large">
                  Criar orçamento
                </Button>
              </Form.Item>
            </FormWrapper>

            <Cards headless bodyStyle={{ height: 'max-content' }}>
              <Head>
                <strong>Valores Totais:</strong>
              </Head>
              <Total>
                <div>
                  <p>Valor Total</p>
                  <CurrencyInput
                    className="ant-input ant-input-disabled"
                    name="input-name"
                    readOnly
                    decimalsLimit={2}
                    disabled
                  />
                </div>

                <div>
                  <p>Valor Desconto Total</p>
                  <CurrencyInput
                    className="ant-input ant-input-disabled"
                    name="input-name"
                    readOnly
                    decimalsLimit={2}
                    disabled
                  />
                </div>

                <div>
                  <p>Percentual de Desconto Total</p>
                  <CurrencyInput
                    className="ant-input ant-input-disabled"
                    name="input-name"
                    readOnly
                    decimalsLimit={0}
                    disabled
                  />
                </div>
              </Total>
            </Cards>

            <Cards headless bodyStyle={{ height: 'max-content' }}>
              <TableWrapper style={{ height: 'max-content' }} className="table-order table-responsive">
                <TableComponent />
              </TableWrapper>
            </Cards>
          </Container>
        )}

        {!defaul && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              flex: 1,
              height: '20rem',
            }}
          >
            <Spin />
          </div>
        )}
      </Main>
    </ThemeLayout>
  );
}
