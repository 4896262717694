import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { api } from '../../../../services/api';
import { Container } from './styles';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { E164Number } from 'libphonenumber-js/types';
/* import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input'; */
import pt from 'react-phone-input-2/lang/pt.json';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
//import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Props {
  data: any;
  refetch: () => Promise<void>;
}

const StyledInput = styled(PhoneInput)`
  input {
    border: none !important;
    outline: none !important;
  }
`;

export function MyAccount({ data }: Props) {
  const [saving, setSaving] = useState(false);

  const [value, setValue] = useState(data?.contact_resp_whatsapp || '');
  const [value2, setValue2] = useState(data.contact_tech_whatsapp || '');

  function submit(formData: any) {
    setSaving(true);
    api
      .post('/accounts/update-info', {
        ...formData,
        contact_resp_whatsapp: value,
        contact_tech_whatsapp: value2,
      })
      .then(() => toast.success('Salvo com sucesso'))
      .catch(() => toast.error('Não foi possivel salvar'))
      .finally(() => setSaving(false));
  }

  return (
    <Container>
      <header>
        <h1>Informações de Contato</h1>
        <p>Gerencie as informações de Contato</p>
      </header>

      <main>
        <Form layout="vertical" onFinish={submit} initialValues={data}>
          <Form.Item label="Nome Responsável da Empresa" name="contact_resp_name">
            <Input type="text" />
          </Form.Item>

          <Form.Item label="Email Responsável da Empresa" name="contact_resp_email">
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              ({ setFieldsValue }) => ({
                validator(e) {
                  if (value) {
                    setFieldsValue(value);
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Campo Obrigatório!'));
                },
              }),
            ]}
            name="contact_resp_whatsapp"
            label="Contato de Whatsapp (Responsável da Empresa)"
          >
             <StyledInput
            placeholder="Adicione um número de celular"
              value={value}
              className="ant-input"
              international={false}
              defaultCountry="BR"
              onChange={setValue}
            /> 
           
            <p style={{ display: 'none' }}>{value}</p>
          </Form.Item>

        

          <Form.Item label="Nome Responsável Técnico" name="contact_tech_name">
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Email Responsável Técnico" name="contact_tech_email">
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              ({ setFieldsValue }) => ({
                validator(e) {
                  if (value2) {
                    setFieldsValue(value2);
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Campo Obrigatório!'));
                },
              }),
            ]}
            name="contact_tech_whatsapp"
            label="Contato de Whatsapp (Responsável Técnico)"
          >
            <StyledInput
            placeholder="Adicione um número de celular"
              value={value}
              className="ant-input"
              international={false}
              defaultCountry="BR"
              onChange={setValue}
            /> 
            <p style={{ display: 'none' }}>{value2}</p>
          </Form.Item>

          <div className="changes">
            <Button type="primary" loading={saving} htmlType="submit">
              Salvar Alterações
            </Button>
          </div>
        </Form>
      </main>
    </Container>
  );
}
