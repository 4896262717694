import React, { useRef, useState } from 'react';

import { Button, Form as AntdForm, DatePicker } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';

import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import toast from 'react-hot-toast';

interface IFormInitialFields {
  agendamento: string;
}

interface IFormComponent {
  formType: 'put' | 'post';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  contactId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  contactId,
  initialFields = { agendamento: '' },
}: IFormComponent) {
  const [isLoading, setIsLoading] = useState(false);

  const [agendamento, setAgendamento] = useState(initialFields.agendamento);
  const [fileList, setFileList] = useState<UploadFile[]>([] as UploadFile[]);
  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    /*   if (fileList.length <= 0) {
      toast.error("É necessário indexar um arquivo");
      setIsLoading(false);
      return;
    } */

    const formData = {
      ...data,
      // url: fileList[0].response.filename,
    };

    try {
      if (formType === 'post') {
        await api.post('/invoices', formData);
      } else {
        if (contactId) await api.put(`/invoices/${contactId}`, { agendamento });
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
      setFileList([]);
    } catch (err) {
      const error: any = err;
      toast.error(error.response.erro);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Edição de Entrega">
          <AntdForm.Item label="Agendamento">
            <DatePicker
              showTime
              format="DD/MM/YYYY HH:mm"
              //value={agendamento}
              placeholder="Selecione uma Data e Horário"
              onChange={e => setAgendamento(String(e))}
            />
          </AntdForm.Item>
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
