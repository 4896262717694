import React, { ComponentType } from 'react';
import Cookie from 'js-cookie';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

interface IProtectedRouteProps {
  component: ComponentType<any>;
  path: string;
  exact?: boolean;
}

export function ProtectedRoute({ component, path, exact = false }: IProtectedRouteProps) {
  const token = Cookie.get('deskioapp-front-token');

  const isLoggedIn = token;
  return isLoggedIn ? <Route exact={exact} component={component} path={path} /> : <Redirect to="/" />;
}

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};
