import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';

import { Button } from '../../../../components/buttons/buttons';
import { Modal, Tooltip } from 'antd';
import { Form } from '../ContactForm';

interface ITableButtonEditProps {
  contactId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  agendamento: string;
}

export function TableButtonEdit({ contactId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Tooltip title="Agendar entrega">
        <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
          <FeatherIcon icon="edit" size={16} />
        </Button>
      </Tooltip>

      <Modal
        title="Agendar Entrega"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          formType="put"
          contactId={contactId}
          initialFields={{
            agendamento: data.agendamento,
          }}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}
