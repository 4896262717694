import React from 'react';
import { Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import Heading from '~/components/heading/heading';
import { Button } from '~/components/buttons/buttons';
import { ProductCard } from '../Style';
import moment from 'moment';

const ProductCardList = ({ product }) => {
  return (
    <ProductCard className="list-view" style={{ marginBottom: 20 }}>
      <div className="product-list-pc" style={{ display: 'flex' }}>
        <div className="product-single-description">
          <Heading className="product-single-title" as="h5">
            {product.cliente}
            {product.nome_parceiro || ''}
          </Heading>
          <strong>Número Único:</strong> {product.num_unico} <br />
          <strong>Data:</strong> {moment(product.created_at).format('DD/MM/YYYY')} <br />
          <strong>Vendedor:</strong> {product.vendedor} <br />
          <strong>Conferente:</strong> {product.conferente} <br />
          <strong>Tipo:</strong> {product.expedicao} <br />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%',
            boxSizing: 'border-box',
            paddingRight: 5,
            marginLeft: 'auto',
          }}
        >
          <div>
            <Tag
              icon={product.andamento === 'EA' ? <SyncOutlined spin /> : <ExclamationCircleOutlined />}
              color={product.andamento === 'EA' ? 'success' : 'warning'}
              style={{ padding: '8px 10px', fontSize: 13 }}
            >
              {product.andamento === 'EA' ? 'Em Andamento' : 'Aguardando Conferência'}
            </Tag>
            <div className="product-single-action">
              <NavLink to={`conferenceDetails/${product.num_unico}`}>
                <Button size="small" type="primary">
                  Acessar Conferência
                </Button>
              </NavLink>
            </div>
          </div>

          {/* <div className="product-single-action" style={{ marginRight: 2, marginBottom: 5 }}>
      <Button size="small" type="primary">
        Conferir
      </Button>
    </div> */}
        </div>
      </div>
    </ProductCard>
  );
};

ProductCardList.propTypes = {
  product: PropTypes.array,
};

export default ProductCardList;
