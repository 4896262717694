import React, { useEffect, useState } from 'react';
import { Col, Radio } from 'antd';

interface Props {
  filters: { key: string; content: string }[];
  handleAddFilters: (event: { content: string; key: string }) => void;
}

export function StatusRadioGroup({ filters, handleAddFilters }: Props) {
  const [value, setValue] = useState('Pendente');
  useEffect(() => {
    if (filters.length === 0) setValue('');
  }, [filters]);

  function handleChange(e: string) {
    console.log(e);
    setValue(e);
    handleAddFilters({ key: '&status=', content: e });
  }

  return (
    <Col lg={14} xs={24}>
      <div className="table-toolbox-menu">
        <span className="toolbox-menu-title"> Status:</span>

        <Radio.Group value={value} defaultValue="Pendente" onChange={e => handleChange(e.target.value)}>
          <Radio.Button value="Pendente">Pendente</Radio.Button>
          <Radio.Button value="ProcEntrega">Processo de Entrega</Radio.Button>
          <Radio.Button value="Problema">Problemas</Radio.Button>
          
          <Radio.Button value="Entregue">Entregue</Radio.Button>
        </Radio.Group>
      </div>
    </Col>
  );
}
