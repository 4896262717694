import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { ICampaignsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: ICampaignsProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const MaxWidthColumn: React.FC<{ width: number }> = ({ width, children }) => (
    <div
      style={{
        maxWidth: width,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </div>
  );

  const columns: ColumnsType<ICampaignsProps> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
    },

    {
      title: 'Título',
      dataIndex: 'title',
      sorter: true,
    },

    {
      title: 'Data de Envio',
      dataIndex: 'send_datetime',
      sorter: true,
    },
    {
      title: 'Destinatários',
      dataIndex: 'dest_total',
      sorter: true,
    },
    {
      title: 'Marcadores',
      dataIndex: 'labels',
      sorter: true,
    },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, row: ICampaignsProps) => {
        if (row.status === 1) {
          return (
            <MaxWidthColumn width={180}>
              {' '}
              <span
                style={{
                  color: '#20C9A5',
                  background: '#E2F4F0',
                  border: `0px solid ${'#b7eb8f'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '12px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                Ativo
              </span>
            </MaxWidthColumn>
          );
        } else if (row.status === 0) {
          return (
            <MaxWidthColumn width={180}>
              {' '}
              <span
                style={{
                  color: '#d8ae3a',
                  background: '#f4eacd',
                  border: `0px solid ${'#f4eacd'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '12px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                Desativado
              </span>
            </MaxWidthColumn>
          );
        }
      },
    },

    {
      title: 'Data de criação',
      dataIndex: 'created_at',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <TableButtonEdit contactId={String(item.id)} refetch={refetch} data={item} />

            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      tile: item.title,
      userId: item.userId,
      segmentId: item.segmentId,
      text: item.content,
      type: item.type,
      tag: item.tag,
      media: item.media,
      send_datetime: item.send_datetime,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
    });
  });

  return { columns, data };
}
