import React, { useRef, useState } from 'react';

import { Button, Input, Form as AntdForm, Checkbox } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';

interface IFormInitialFields {
  name: string;
}

interface IFormSegment {
  formType: 'put' | 'post';
  initialFields?: any;
  refetch: () => void;
  segmentId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ formType, setModalIsOpen, refetch, segmentId, initialFields }: IFormSegment) {
  console.log(initialFields);
  const [isLoading, setIsLoading] = useState(false);
  const [isTure, setIsTure] = useState(initialFields?.show_on_sidebar || false);
  const [fileList, setFileList] = useState<UploadFile[]>([] as UploadFile[]);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    try {
      if (formType === 'post') {
        await api.post('/tags', {
          ...data,
          show_on_sidebar: isTure,
        });
      } else {
        if (segmentId) await api.put(`/tags/${segmentId}`, data);
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
      setFileList([]);
    } catch (err) {
      //console.log(err);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome obrigatório' }]}>
            <Input placeholder="Nome da tag" />
          </AntdForm.Item>
          <AntdForm.Item
            label="Descrição"
            name="description"
            rules={[{ required: true, message: 'Descrição obrigatória' }]}
          >
            <Input placeholder="Descrição da tag" />
          </AntdForm.Item>
          <AntdForm.Item label="Cor" name="color" rules={[{ required: true, message: 'Cor obrigatória' }]}>
            <Input type="color" />
          </AntdForm.Item>

          <AntdForm.Item>
            <Checkbox defaultChecked={isTure} onChange={() => setIsTure(old => !old)} />
            <span> Mostrar Tag na Barra Lateral?</span>
          </AntdForm.Item>
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
