import React, { useRef } from 'react';
import { FormInstance, Row } from 'antd';

import { ClearFilterButton } from './components/ClearFilterButton';
import { FilterButton } from './components/FilterButton';
import { SearchInput } from './components/SearchInput';
import { TableComponent } from './components/table';
import { ButtonAdd } from './components/table/TableButtonAdd';
import { StatusRadioGroup } from './components/StatusRadioGroup';

import { ThemeLayout } from '../../layout/themeLayout';
import { Main, TableWrapper } from '../../container/styled';
import { TopToolBox } from '../../container/ecommerce/Style';

import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';

import { useFetch } from '../../hooks/useFetch';
import { IFetchPropsInvoices } from './types';

export function Invoicesqueue() {
  const {
    isFetching,
    dataFetch,
    setSearch,
    filters,
    handleAddFilters,
    clearFilters,
    refetch,
    currentPage,
    itensPerPage,
    setCurrentPage,
    setColumnOrdenation,
    setItensPerPage,
    totalItens,
    ApplyFilters,
    applyFiltersNow,
  } = useFetch<IFetchPropsInvoices>({ baseUrl: 'fila-notas' });

  const formRef = useRef<FormInstance<any>>(null);

  function handleClearFilters() {
    formRef.current?.resetFields();
    clearFilters();
  }

  return (
    <ThemeLayout>
      <PageHeader
        ghost
        title="Fila de Entregas"
        buttons={[
          <div key="1" className="page-header-actions">
            {filters.length !== 0 && <ClearFilterButton clearFilter={handleClearFilters} />}
            <FilterButton
              formRef={formRef}
              ApplyFilters={ApplyFilters}
              filters={filters}
              handleAddFilters={handleAddFilters}
              clearFilters={clearFilters}
            />
          </div>,
        ]}
      />

      <Main>
        <Cards headless>
          <TopToolBox>
            <Row gutter={15} className="justify-content-center">
              <SearchInput handleSearch={setSearch} />
              <StatusRadioGroup filters={filters} handleAddFilters={applyFiltersNow} />
              <ButtonAdd refetch={refetch} />
            </Row>
          </TopToolBox>

          <TableWrapper className="table-order table-responsive">
            <TableComponent
              setCurrentPage={setCurrentPage}
              setColumnOrdenation={setColumnOrdenation}
              currentPage={currentPage}
              refetch={refetch}
              isLoading={isFetching}
              dataFetch={dataFetch}
              totalItens={totalItens}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
            />
          </TableWrapper>
        </Cards>
      </Main>
    </ThemeLayout>
  );
}
