import React, { useState } from 'react';
import { Select, Form as AntdForm } from 'antd';
import Cookie from 'js-cookie';
import axios from 'axios';
import styled from 'styled-components';
import { treatment } from '../../../hooks/treatment';

interface SelectParProps {
  defaultValue: string;
  onChange: (e: string) => void;
  label?: string;
  name?: string;
}

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function SelectPar({ defaultValue, onChange, label = 'Parceiro/Cliente', name = 'CODPARC' }: SelectParProps) {
  const [options, setOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookie.get('deskioapp-front-token');

  async function get(search: string) {
    setIsLoading(true);
    const res = await axios.post(
      'https://apiapp.deskio.com.br/api/v1/quotes/search-parc',
      {
        term: search,
      },
      {
        headers: {
          authorization: `bearer ${token?.replaceAll('"', '')}`,
        },
      },
    );

    treatment(res.data);
    setOptions(Array.isArray(res.data) ? res.data : [res.data]);
    setIsLoading(false);
  }

  let timer: NodeJS.Timeout;
  function debounce(event: string) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      get(event);
    }, 1000);
  }

  return (
    <AntdForm.Item
      label={label}
      name={name}
      style={{ width: '100%' }}
      rules={[{ required: true, message: `Campo obrigatório` }]}
    >
      <AntdSelect
        showSearch
        defaultValue={defaultValue}
        onChange={(e: any) => onChange(e)}
        filterOption={false}
        placeholder="Busque um parceiro"
        onSearch={e => debounce(e)}
        loading={isLoading}
      >
        {options?.map(option => (
          <AntdSelect.Option value={option.CODPARC}>{option.NOMEPARC}</AntdSelect.Option>
        ))}
      </AntdSelect>
    </AntdForm.Item>
  );
}
