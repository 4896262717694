import React, { useEffect, useState } from 'react';
import { Select, Form as AntdForm } from 'antd';
import Cookie from 'js-cookie';
import axios from 'axios';
import styled from 'styled-components';
import { treatment } from '../../../hooks/treatment';

interface Props {
  defaultValue: string | undefined;
}

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function SelectTipOper({ defaultValue }: Props) {
  const [options, setOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookie.get('deskioapp-front-token');
  async function get() {
    setIsLoading(true);
    const res = await axios.post(
      'https://apiapp.deskio.com.br/api/v1/quotes/search-tipo-operacao',
      {},
      {
        headers: {
          authorization: `bearer ${token?.replaceAll('"', '')}`,
        },
      },
    );

    treatment(res.data);

    setOptions(res.data);
    setIsLoading(false);
  }

  useEffect(() => {
    get();
  }, []);

  return (
    <AntdForm.Item
      label="Tipo de Operação"
      name="CODTIPOPER"
      style={{ width: '100%' }}
      rules={[{ required: true, message: `Campo obrigatório` }]}
    >
      <AntdSelect
        defaultValue={defaultValue}
        filterOption={false}
        placeholder="Escolha um tipo de oper"
        loading={isLoading}
        disabled
      >
        {options?.map(option => (
          <AntdSelect.Option value={option.CODTIPOPER}>{option.DESCROPER}</AntdSelect.Option>
        ))}

        <AntdSelect.Option value={'1100'}>Venda NFe</AntdSelect.Option>
      </AntdSelect>
    </AntdForm.Item>
  );
}
