import React from 'react';
import { Button, DatePicker, Form, Select } from 'antd';

import { FiltersWrapper } from '../../styles';
import moment from 'moment';
import { Select as TagSelect } from '../../../../components/Select';
import { SelectPar } from '../../../filaOfNotes/FilterButton/SelectPar';

type ITypeFilter = {
  content: string;
  key: string;
};

type FiltersProps = {
  filters: ITypeFilter[];
  clearFilters: () => void;
  handleAddFilters: (event: { key: string; content: string }) => void;
  handleApplyFilters: () => void;
};

export function FilterDrawer({ handleAddFilters, clearFilters, filters, handleApplyFilters }: FiltersProps) {
  function handleChangeFilters([date1, date2]: any) {
    const format = (date?: moment.Moment) => (date ? date.format('YYYY-MM-DD') : '');
    handleAddFilters({ key: '&filter.createdAt=$btw:', content: `${format(date1)},${format(date2)}` });
  }

  function handleFilterStatus(event: string) {
    handleAddFilters({ key: '&contactSankhya=', content: event });
  }

  function handleFilterTag(event: string) {
    handleAddFilters({ key: '&filter.tagId=$btw:', content: event });
  }

  function handleFilterParceiro(event: string) {
    handleAddFilters({ key: '&CODPARC=', content: event });
  }

  return (
    <FiltersWrapper>
      <Form layout="vertical">
        <Form.Item label="Data criação">
          <DatePicker.RangePicker
            placeholder={['Data inicial', 'Data final']}
            format="DD/MM/YYYY"
            onChange={dates => handleChangeFilters(dates)}
          />
        </Form.Item>

        <Form.Item name="item3" label="Sincronizado Sankhya">
          <Select onChange={handleFilterStatus} placeholder={'Selecione uma opção'}>
            <Select.Option value={'1'}>Sim</Select.Option>
            <Select.Option value={'2'}>Não</Select.Option>
          </Select>
        </Form.Item>

        <SelectPar onChange={e => handleFilterParceiro(e)} />
        <TagSelect name="filterSelectTag" title="Selecione uma Tag" fetchUrl="tags" onChange={handleFilterTag} />
      </Form>

      <footer>
        {filters.length !== 0 && (
          <Button danger size="large" onClick={clearFilters}>
            Limpar filtros
          </Button>
        )}

        <Button type="primary" size="large" onClick={handleApplyFilters}>
          Adicionar filtros
        </Button>
      </footer>
    </FiltersWrapper>
  );
}
