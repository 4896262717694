import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IPaymentsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IPaymentsProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const MaxWidthColumn: React.FC<{ width: number }> = ({ width, children }) => (
    <div
      style={{
        maxWidth: width,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </div>
  );

  const columns: ColumnsType<IPaymentsProps> = [
    {
      title: 'Empresa',
      dataIndex: 'nome',
      sorter: true,
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      sorter: true,
    },

    {
      title: 'Dia do pagamento',
      dataIndex: 'payment_duodate',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} paymentId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      payment_duodate: item.payment_duodate.substring(0, 11),
      paid_at: item.paid_at.substring(0, 11),
      description: item.description,
      status: item.status,
      amount: item.amount,
      nome: item.nome,
    });
  });

  return { columns, data };
}
