import React, { memo, useCallback, useEffect, useState } from 'react';

import { Select as AntdSelesct, Form as AntdForm, Input, Button, Modal } from 'antd';
import { api } from '../../services/api';
import toast from 'react-hot-toast';
import { ISelectSegmentoComponent, IFetchProps } from './types';
import styled from 'styled-components';
import { treatment } from '../../hooks/treatment';

const AntdSelect = styled(AntdSelesct)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function Select({
  title,
  postUrl = '',
  fetchUrl,
  name,
  onChange,
  ApiName,
  addText,
  method,
  disabled = false,
  methosPostValue,
  formModalInitialValues,
  modalContent,
  fetchInSearch,
  placeholder,
  defaultValue,
  defaultSearch,
  formRef,
}: ISelectSegmentoComponent) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dataInputModal, setDataInputModal] = useState('');
  const [isFetch, setIsFetch] = useState(false);

  // const [search, setSearch] = useState(defaultSearch);

  const [selectValue, setSelectValue] = useState({ value: '', label: '' });

  const [data, setData] = useState<any[]>([]);

  const fetch = useCallback(
    async (search?: string) => {
      setIsFetch(true);
      if (method === 'get') {
        const response = await api.get(fetchUrl + `?search=${search}`);
        setData(response.data);
        setIsFetch(false);

        return;
      }

      if (methosPostValue) {
        if (!search) return;
        const response = await api.post(fetchUrl, { term: search, ...methosPostValue });
        setIsFetch(false);
        setData(response.data);

        return;
      }

      const response = await api.post(fetchUrl, { term: search });
      setIsFetch(false);
      setData(response.data);
    },
    [fetchUrl, methosPostValue],
  );

  useEffect(() => {
    fetch();
    // else if (fetchInSearch) fetch();
  }, [methosPostValue]);

  async function handleSubmit(formData: any) {
    setDataInputModal(formData);
    try {
      const { data } = await api.post(postUrl, {
        NOMECONTATO: formData?.NOMECONTATO || '',
        TELEFONE: formData.TELEFONE || '',
        APELIDO: formData?.APELIDO || '',
        EMAIL: formData?.EMAIL || '',
        codpar: formData?.codpar || 'codpar',
      });

      treatment(data);

      if (!data.success) {
        toast.error(data.erro);
        return;
      }

      setData(allData => [...allData, [data.CODCONTATO.$, formData.NOMECONTATO]]);
      formRef?.current?.setFieldsValue({
        [name]: data.CODCONTATO.$,
      });
      fetch(data.name);
      setModalIsOpen(false);
    } catch (err) {
      const error: any = err;
      toast.error(error);
    }
  }

  function handleSelected(event: string) {
    setSelectValue({ label: event, value: event });
    if (onChange) onChange(event);
  }

  let timer: NodeJS.Timeout;

  function debounce(event: string) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      fetch(event);
    }, 1000);
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <AntdForm.Item label={title} name={name} style={{ width: '100%' }}>
          <AntdSelect
            showSearch
            filterOption={false}
            disabled={disabled}
            placeholder={placeholder || title}
            defaultValue={defaultValue ? String(defaultValue) : undefined}
            onSearch={e => debounce(e)}
            loading={isFetch}
            value={selectValue.value !== '' && String(selectValue.value)}
            onChange={value => handleSelected(String(value))}
          >
            {Array.isArray(data) &&
              data?.map(segment => {
                return <AntdSelect.Option value={String(segment[0])}>{segment[1]}</AntdSelect.Option>;
              })}
          </AntdSelect>
        </AntdForm.Item>

        {postUrl && (
          <Button
            disabled={disabled}
            onClick={() => setModalIsOpen(true)}
            style={{ marginTop: '2.45rem', height: '2.3rem' }}
          >
            +
          </Button>
        )}
      </div>

      <Modal
        title={addText ? addText : 'Adicionar item ao select'}
        visible={!postUrl ? false : modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        footer={null}
        maskClosable={false}
        width={500}
      >
        <AntdForm initialValues={formModalInitialValues} name="basic" onFinish={handleSubmit} layout="vertical">
          {modalContent ? (
            modalContent
          ) : (
            <>
              <AntdForm.Item label="Título" name="name">
                <Input placeholder="Nome" />
              </AntdForm.Item>
            </>
          )}
        </AntdForm>
      </Modal>
    </>
  );
}
