import React, { useRef, useState } from 'react';

import { Button, Form as AntdForm, Input } from 'antd';
import toast from 'react-hot-toast';
import { api } from '../../../services/api';
import { CardWrapper, FormWrapper } from '../styles';
import { IHandleSubmitProps } from '../types';

// import { MultiSelect } from "../../../components/MultiSelect";

interface IFormInitialFields {
  name: string;
}

interface IFormHomart {
  formType: 'put' | 'post';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  homartId?: string;
  setModalIsOpen: (event: boolean) => void;
  setModalPart: (e: string) => void;
  ModalPart: string;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  homartId,
  ModalPart,
  setModalPart,
  initialFields = { name: '' },
}: IFormHomart) {
  const [isLoading, setIsLoading] = useState(false);

  const [url_webhook, setUrl_webhook] = useState('');

  const formRef = useRef<any>(null);

  function VerifyIfCloseModal() {
    setModalIsOpen(false);
    setUrl_webhook('');
  }
  function clearFieldsAndCloseModal() {
    {
      ModalPart === 'first-part' && VerifyIfCloseModal();
    }
    {
      ModalPart === 'second-part' && setModalPart('first-part');
    }
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    const formData = {
      name: data.name,
    };

    async function SendDataAndCatchReturnDataHotmart() {
      const { data } = await api.post('/hotmartProducts', formData);
      setUrl_webhook(data.data.url_webhook);
    }

    try {
      if (formType === 'post') SendDataAndCatchReturnDataHotmart();
      else if (homartId) await api.put(`/hotmartProducts/${homartId}`, formData);

      setModalPart('second-part');
      formRef.current.resetFields();
    } catch (err) {
      const error: any = err;
      toast.error(error.response.data.message);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        {ModalPart === 'first-part' && (
          <CardWrapper type="inner" title="Informações Gerais">
            <AntdForm.Item label="Nome:" name="name" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Input placeholder="Nome" />
            </AntdForm.Item>
          </CardWrapper>
        )}

        {ModalPart === 'second-part' && (
          <CardWrapper type="inner" title="Informações Gerais">
            <Input disabled value={url_webhook} />
          </CardWrapper>
        )}

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          {ModalPart === 'first-part' && (
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Próximo
            </Button>
          )}

          {ModalPart === 'second-part' && (
            <Button type="primary" htmlType="button" onClick={VerifyIfCloseModal} loading={isLoading}>
              Fechar modal
            </Button>
          )}
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
