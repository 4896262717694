import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { CardWrapper } from '../../pages/contacts/styles';

type PaimentStepProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function PaimentStep({ isOpen, onClose }: PaimentStepProps) {
  const [stage, setStage] = useState(0);
  const [dataForms, setDataForms] = useState<any[]>([]);

  function close() {
    setStage(0);
    onClose();
  }

  function ok1(data: any) {
    setStage(1);
    setDataForms([data]);
  }

  function cancel2() {
    setStage(0);
    setDataForms(old => []);
  }

  function cancel3() {
    setStage(1);
    setDataForms(old => [old[0]]);
  }

  function ok2(data: string) {
    setStage(2);
    setDataForms(old => [...old, { pagamentType: data }]);
  }

  return (
    <>
      {isOpen && stage === 0 && (
        <Modal visible closable={false} footer={null} bodyStyle={{ padding: 0 }}>
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'center',
            }}
            layout="vertical"
            onFinish={ok1}
          >
            <CardWrapper
              title="Informações do usuário"
              style={{
                marginTop: '1rem',
                margin: '20px',
              }}
              type="inner"
            >
              <Form.Item
                rules={[{ required: true, message: 'Esse campo é obrigatório' }]}
                label="Nome completo"
                name="name"
              >
                <Input type="text" />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: 'Esse campo é obrigatório' }]}
                label="Seu Email"
                name="email"
              >
                <Input type="text" />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: 'Esse campo é obrigatório' }]}
                label="CPF/CNPJ"
                name="credentials"
              >
                <Input type="text" />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: 'Esse campo é obrigatório' }]}
                label="Telefone"
                name="phone"
              >
                <Input type="text" />
              </Form.Item>
            </CardWrapper>

            <footer
              style={{
                gap: '5px',
                padding: '20px',
                display: 'flex',
                justifyContent: 'end',
                borderTop: '1px solid #f0f0f0',
              }}
            >
              <Button type="default" htmlType="button" onClick={close}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit">
                Próxima etapa
              </Button>
            </footer>
          </Form>
        </Modal>
      )}
      {isOpen && stage === 1 && (
        <Modal visible closable={false} footer={null} bodyStyle={{ padding: 0, paddingTop: '0.25rem' }}>
          <CardWrapper
            title="Tipo de pagamento"
            style={{
              margin: '20px',
            }}
            bodyStyle={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            type="inner"
          >
            <Button onClick={() => ok2('Cartão de crédito')} size="large" style={{ width: '15rem' }}>
              Cartão de crédito
            </Button>
            <Button onClick={() => ok2('Pix')} size="large" style={{ width: '15rem', marginTop: '1rem' }}>
              Pix
            </Button>
            <Button onClick={() => ok2('Boleto')} size="large" style={{ width: '15rem', marginTop: '1rem' }}>
              Boleto
            </Button>
          </CardWrapper>

          <footer
            style={{
              gap: '5px',
              padding: '20px',
              display: 'flex',
              justifyContent: 'end',
              borderTop: '1px solid #f0f0f0',
            }}
          >
            <Button type="default" htmlType="button" onClick={cancel2}>
              Voltar
            </Button>
          </footer>
        </Modal>
      )}
      {isOpen && stage === 2 && (
        <Modal visible closable={false} footer={null} bodyStyle={{ padding: 0, paddingTop: '0.25rem' }}>
          <CardWrapper
            title={'Pagar - ' + dataForms[1]?.pagamentType}
            style={{
              margin: '20px',
            }}
            bodyStyle={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            type="inner"
          ></CardWrapper>

          <footer
            style={{
              gap: '5px',
              padding: '20px',
              display: 'flex',
              justifyContent: 'end',
              borderTop: '1px solid #f0f0f0',
            }}
          >
            <Button type="default" htmlType="button" onClick={cancel3}>
              Voltar
            </Button>
            <Button type="primary" htmlType="button">
              Finalizar
            </Button>
          </footer>
        </Modal>
      )}
    </>
  );
}
