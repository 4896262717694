import styled from 'styled-components';

export const Container = styled.div`
  .firstLine {
    display: flex;
    align-items: center;
    gap: 1rem;

    > div {
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 0.25rem;
      }

      input {
        height: 2.30rem;
        width: 15rem;
      }
    }
  }

  .midLine {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    gap: 0.5rem;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 0.25rem;
      }

      input {
        height: 2.30rem;
        width: 15rem;
      }
    }
  }

  .textareaLine {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;

      p {
        margin-bottom: 0.25rem;
      }

      input {
        height: 2.30rem;
        width: 15rem;
      }

      textarea {
        resize: none;
        width: 100%;
        height: 6rem;
      }
    }
  }
`;
