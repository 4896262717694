import React, { useRef, useState } from 'react';
import { Button, Form as AntdForm } from 'antd';
import { SelectPar } from './selects/SelectPar';
import { api } from '../../services/api';
import { CardWrapper, FormWrapper } from '../contacts/styles';

interface IFormContact {
  refetch: () => void;
  chatWoodIdContact: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ setModalIsOpen, refetch, chatWoodIdContact }: IFormContact) {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: any) {
    setIsLoading(true);
    await api.post(`/contacts/update-contact-chatwoot`, {
      codpar: data.codparc,
      id: chatWoodIdContact,
    });
    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm name="basic" onFinish={handleSubmit} style={{ padding: '30px' }} layout="vertical" ref={formRef}>
        <CardWrapper type="inner" title="Selecione um contato abaixo, para vincular ao Sankhya">
          <SelectPar id={chatWoodIdContact} />
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Vincular
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
