import Cookies from 'js-cookie';
import { treatment } from '../../../hooks/treatment';
import { useAuth } from '../../../hooks/useAuth';
import { api } from '../../../services/api';

const fieldsName = [
  'Produto_DESCRPROD',
  'CODPROD',
  'CODVOL',
  'QTDNEG',
  'VLRUNIT',
  'VLRDESC',
  'VLRTOT',
  'CODLOCALORIG',
  'Produto_VLRLIQ',
  'PERCDESC',
  'SEQUENCIA',
];

interface ReturnProps {
  DESCRPROD: string;
  CODPROD: string;
  CODVOL: string;
  QTDNEG: string;
  VLRUNIT: string;
  VLRDESC: string;
  VLRTOT: string;
  PERCDESC: string;
  CODLOCALORIG: string;
  VLRLIQ: string;
  SEQUENCIA: string;
}

const URL = '/quotes/get-items';
export async function getTableProducts(nunota: string): Promise<ReturnProps[] | { error: 90 }> {
  const token = Cookies.get('deskioapp-front-token');
  const bodyContent = { nunota };

  const response = await api.post(URL, bodyContent, {
    headers: {
      authorization: `bearer ${token}`,
    },
  });

  treatment(response.data);

  const fields = response?.data?.entities?.metadata?.fields?.field || [];
  const data = response.data?.entities.entity
    ? Array.isArray(response.data?.entities.entity)
      ? response.data?.entities.entity
      : [response.data?.entities.entity]
    : [];

  const fieldsIndexes =
    fieldsName.map(name => {
      return fields?.findIndex(field => field['@attributes'].name === name);
    }) || [];

  const resData =
    data.map(dataItem => {
      return fieldsIndexes?.map(fieldIndex => dataItem[`f${fieldIndex}`]);
    }) || [];

  const tratado: ReturnProps[] = resData?.map(item => {
    return {
      DESCRPROD: item[0] || '',
      CODPROD: item[1] || '',
      CODVOL: item[2] || '',
      QTDNEG: item[3] || '',
      VLRUNIT: item[4] || '',
      VLRDESC: item[5] || '',
      VLRTOT: item[6] || '',
      CODLOCALORIG: item[7] || '',
      VLRLIQ: item[8] || '',
      PERCDESC: item[9] || '',
      SEQUENCIA: item[10] || '',
    };
  });

  return tratado || { error: 90 };
}
