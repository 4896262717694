import React from 'react';
import { Menu } from 'antd';
import { useHistory, Link, useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { api } from '../../services/api';
import Cookies from 'js-cookie';
import { useAuth } from '../../hooks/useAuth';
import { menuItemApear } from '../menuAppear';

interface IMenuItensProps {
  topMenu: boolean;
}

export function MenuItens({ topMenu }: IMenuItensProps) {
  const token = Cookies.get('deskioapp-front-token');
  const location = useLocation();

  const { user } = useAuth();

  const permissions = user?.permissions;
  const models: {
    enable_search_products: boolean;
    enable_invoicesqueue: boolean;
    enable_conference: boolean;
    enable_quotes: boolean;
    enable_omnichannel: boolean;
  } = JSON.parse(Cookies.get('deskioapp-account-info') || '');

  async function Help() {
    const { data } = await api.get('https://apiapp.deskio.com.br/api/v1/get-url-sso', {
      headers: {
        authorization: `bearer ${token}`,
      },
    });

    window.open(data.url, '_blank');
  }

  return (
    <Menu theme="light" defaultSelectedKeys={[location.pathname]}>
      {!topMenu && <p className="sidebar-nav-title">Operacional</p>}
      {menuItemApear({
        models,
        permissions,
        model: 'enable_search_products',
        permission: 'CONSULTAPRODUTOS',
        html: (
          <Menu.Item
            key="/admin/products"
            icon={
              <Link className="menuItem-iocn" to={'/admin/products'}>
                <FeatherIcon icon="shopping-bag" />
              </Link>
            }
          >
            <Link to={'/admin/products'}>Consulta de Produtos</Link>
          </Menu.Item>
        ),
      })}
      {menuItemApear({
        models,
        permissions,
        model: 'enable_invoicesqueue',
        permission: 'FILANOTAS',
        html: (
          <Menu.Item
            key="/admin/invoicesqueue"
            icon={
              <Link className="menuItem-iocn" to={'/admin/invoicesqueue'}>
                <FeatherIcon icon="truck" />
              </Link>
            }
          >
            <Link to={'/admin/invoicesqueue'}>Fila de Entregas</Link>
          </Menu.Item>
        ),
      })}
      {menuItemApear({
        models,
        permissions,
        model: 'enable_conference',
        permission: 'FILACONFERENCIA',
        html: (
          <Menu.Item
            key="/admin/conference"
            icon={
              <Link className="menuItem-iocn" to={'/admin/conference'}>
                <FeatherIcon icon="crosshair" />
              </Link>
            }
          >
            <Link to={'/admin/conference'}>Fila de Conferência</Link>
          </Menu.Item>
        ),
      })}
      {menuItemApear({
        models,
        permissions,
        model: 'enable_quotes',
        permission: 'VENDAS',
        html: (
          <Menu.Item
            key="/admin/orcamentos"
            icon={
              <Link className="menuItem-iocn" to={'/admin/orcamentos'}>
                <FeatherIcon icon="file-text" />
              </Link>
            }
          >
            <Link to={'/admin/orcamentos'}>Orçamentos</Link>
          </Menu.Item>
        ),
      })}

     {!topMenu && <p className="sidebar-nav-title">CRM</p>} 
      {menuItemApear({
        models,
        permissions,
        model: 'enable_omnichannel',
        permission: 'VENDAS',
        html: (
          
          <Menu.Item
            key="/admin/contacts"
            icon={
              <Link className="menuItem-iocn" to={'/admin/contacts'}>
                <FeatherIcon icon="book" />
              </Link>
            }
          >
            <Link to={'/admin/contacts'}>Contatos</Link>
          </Menu.Item>
        ),
      })}
       {menuItemApear({
        models,
        permissions,
        model: 'enable_omnichannel',
        permission: 'ADMIN',
        html: (
          <Menu.Item
            key="/admin/segments"
            icon={
              <Link className="menuItem-iocn" to={'/admin/segments'}>
                <FeatherIcon icon="bookmark" />
              </Link>
            }
          >
            <Link to={'/admin/segments'}>Categorias</Link>
          </Menu.Item>
        ),
      })} 
       {menuItemApear({
        models,
        permissions,
        model: 'enable_omnichannel',
        permission: 'ADMIN',
        html: (
          <Menu.Item
            key="/admin/tags"
            icon={
              <Link className="menuItem-iocn" to={'/admin/tags'}>
                <FeatherIcon icon="tag" />
              </Link>
            }
          >
            <Link to={'/admin/tags'}>Tags</Link>
          </Menu.Item>
        ),
      })}

      {!topMenu && <p className="sidebar-nav-title">Atendimento</p>}
      {menuItemApear({
        models,
        permissions,
        permission: 'MULTIATENDIMENTO',
        html: (
          <Menu.Item
            key="/chat"
            icon={
              <a className="menuItem-iocn" href="" target="_blank">
                <FeatherIcon icon="message-circle" />
              </a>
            }
          >
            <a rel="noopener noreferrer" onClick={Help} target="_blank">
              Chat
            </a>
          </Menu.Item>
        ),
      })}
      {menuItemApear({
        models,
        permissions,
        permission: 'ADMIN',
        html: (
          <Menu.Item
            key="/instances"
            icon={
              <Link className="menuItem-iocn" to={'/instances'}>
                <FeatherIcon icon="smartphone" />
              </Link>
            }
          >
            <Link to={'/instances'}>Conexões Whatsapp</Link>
          </Menu.Item>
        ),
      })}
      {menuItemApear({
        models,
        permissions,
        model: 'enable_omnichannel',
        permission: 'ADMIN',
        html: (
          <Menu.Item
            key="/admin/campaignswhats"
            icon={
              <Link className="menuItem-iocn" to={'/admin/campaignswhats'}>
                <FeatherIcon icon="navigation" />
              </Link>
            }
          >
            <Link to={'/admin/campaignswhats'}>Campanhas Whatsapp</Link>
          </Menu.Item>
        ),
      })}
      {menuItemApear({
        models,
        permissions,
        model: 'enable_omnichannel',
        permission: 'ADMIN',
        html: (
          <Menu.Item
            key="/admin/teams"
            icon={
              <Link className="menuItem-iocn" to={'/admin/teams'}>
                <FeatherIcon icon="users" />
              </Link>
            }
          >
            <Link to={'/admin/teams'}>Departamentos/Times</Link>
          </Menu.Item>
        ),
      })} 

      {!topMenu && <p className="sidebar-nav-title">Ajuda</p>}

      <Menu.Item
        key="/documentacao"
        icon={
          <a className="menuItem-iocn" href="https://help.deskio.com.br" target="_blank">
            <FeatherIcon icon="message-circle" />
          </a>
        }
      >
        <a rel="noopener noreferrer" href="https://help.deskio.com.br" target="_blank">
          Suporte e Tutoriais
        </a>
      </Menu.Item>

      {!topMenu && <p className="sidebar-nav-title">Configurações</p>}
      {menuItemApear({
        models,
        permissions,
        permission: 'ADMIN',
        html: (
          <Menu.Item
            key="/admin/logs"
            icon={
              <Link className="menuItem-iocn" to={'/admin/logs'}>
                <FeatherIcon icon="smartphone" />
              </Link>
            }
          >
            <Link to={'/admin/logs'}>Logs Usuários</Link>
          </Menu.Item>
        ),
      })}
      {menuItemApear({
        models,
        permissions,
        permission: 'ADMIN',
        html: (
          <Menu.Item
            key="/admin/usuarios"
            icon={
              <Link className="menuItem-iocn" to={'/admin/usuarios'}>
                <FeatherIcon icon="user" />
              </Link>
            }
          >
            <Link to={'/admin/usuarios'}>Usuários</Link>
          </Menu.Item>
        ),
      })}
      {menuItemApear({
        models,
        permissions,
        permission: 'ADMIN',
        html: (
          <Menu.Item
            key="/profile/0"
            icon={
              <Link className="menuItem-iocn" to={'/profile/0'}>
                <FeatherIcon icon="user" />
              </Link>
            }
          >
            <Link to={'/profile/0'}>Configurações</Link>
          </Menu.Item>
        ),
      })}
    </Menu>
  );
}
