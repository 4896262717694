import React, { useState } from 'react';
import { ButtonOrc } from '../ButtonOrc';
import { TableComponent } from '../Table';
import { Container } from './styles';

interface ItemsProps {
  title: string;
  cor: string;
}

interface DataProps {
  data: string;
  codPedido: string;
  redirectLink: string;
  codUnic: string;
  items: ItemsProps;
  id: number;
  setSelectedNumber: (e: { nunota: number; nucomp: number }) => void;
  selectedNumber: {
    nunota: number;
    nucomp: number;
  };
}

export function BlockComponent({
  id,
  selectedNumber,
  redirectLink,
  setSelectedNumber,
  data,
  items,
  codUnic,
  codPedido,
}: DataProps) {
  const idSelect = selectedNumber.nucomp;
  const [tipoName, setTipoName] = useState('Orçamento');

  function toggle(e: number) {
    setSelectedNumber({
      nucomp: id,
      nunota: e,
    });
  }

  function close() {
    setSelectedNumber({
      nucomp: 0,
      nunota: 0,
    });
  }

  return (
    <>
      <Container>
        <p style={{ width: '9rem', justifyContent: 'center' }}>{`${data.slice(0, 2)}/${data.slice(2, 4)}/${data.slice(
          4,
          8,
        )}`}</p>
        <p style={{ flex: 1, marginLeft: '1rem' }}>{codPedido}</p>
        <p style={{ flex: 1, marginLeft: '1rem' }}>{codUnic}</p>
        <div style={{ flex: 1, marginLeft: '1rem' }}>
          <ButtonOrc redirectLink={redirectLink} title={items.title} color={items.cor} />
        </div>
      </Container>

      {idSelect === id && <TableComponent tipoName={tipoName} close={close} nunota={selectedNumber.nunota} />}
    </>
  );
}
