import React, { useState } from 'react';
import { Button } from '../../../../components/buttons/buttons';
import FeatherIcon from 'feather-icons-react';
import { Modal, Tooltip } from 'antd';
import { useFetch } from '../../../../hooks/useFetch';
import { Table as AntdTable } from 'antd';
import styled from 'styled-components';
import { api } from '../../../../services/api';
import { ColumnsType } from 'antd/lib/table';
import toast from 'react-hot-toast';

interface TableModalViewProps {
  data: any;
  refresh: () => void;
}

interface ModalContentProps {
  nuNota: string;
  motorista: string;
  status: string;

  refresh: () => void;
  setIsOpen: (a: boolean) => void;
}

const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem;

  .button {
    margin-top: 2rem;
  }
`;

const columns: ColumnsType<any> = [
  {
    title: 'Problema Relatado',
    dataIndex: 'obs',
    sorter: false,
  },

  {
    title: 'Data e horário',
    dataIndex: 'created_at',
    sorter: false,
  },
];

const ModalContent = ({ refresh, motorista, status, nuNota, setIsOpen }: ModalContentProps) => {
  const { dataFetch } = useFetch<any>({ baseUrl: `/invoices/${nuNota}`, isLinkProps: false, isArray: false });
  const { dataFetch: dataFetch2, refetch } = useFetch<any>({
    baseUrl: `/invoices-issues/${nuNota}`,
    isLinkProps: false,
    isArray: false,
  });

  async function mot() {
    try {
      await api.post('/remove-driver', { nuNota });
      setIsOpen(false);
      toast.success('Motorista Desvinculado com sucesso!');
    } catch (err) {
      toast.error('Não foi possivel desvincular Motorista');
    }

    refetch();
    refresh();
  }

  if (status === '2') {
    const status= 'Entregue';
  }else if (status === '1') {
    const status= 'Processo de Entrega';
  }else if (status === '3') {
    const status= 'Problema na Entrega';
  }else if (status === '4') {
    const status= 'Pendente';
  }



  return (

    
    <Content>
      <p>
        <strong>Motorista: </strong> {motorista}
      </p>
      <p>
        <strong>Status: </strong>  {status === '3' && (
            <span>Problema na Entrega</span>    
       
      )}
      {status === '2' && (
            <span>Entregue</span>    
       
      )}
      {status === '1' && (
            <span>Em Processo de Entrega</span>    
       
      )}
      {status === '0' && (
            <span>Pendente</span>    
       
      )}
      </p>
      
      <p>
        <strong>Hora Leitura Chave NFE: </strong> {dataFetch?.hora_leitura || ''}
      </p>
      <p>
        <strong>Hora Entrega: </strong> {dataFetch?.hora_entrega || ''}
      </p>

      <p>
        <strong>Problemas Relatados: </strong>
      </p>
      <AntdTable columns={columns} dataSource={dataFetch2} />

      {status === 'Problema na Entrega' && (
        <Button className="button" onClick={mot} type="danger">
          Desvincular Motorista
        </Button>
      )}
    </Content>
  );
};

export function TableModalView({ refresh, data }: TableModalViewProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Tooltip title="Ver detalhes">
        <Button className="btn-icon" type="primary" shape="circle" onClick={() => setIsOpen(true)}>
          <FeatherIcon icon="eye" size={16} />
        </Button>
      </Tooltip>

      <Modal
        title="Detalhes da entrega"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        {isOpen && (
          <ModalContent
            refresh={refresh}
            setIsOpen={setIsOpen}
            motorista={data.entregador}
            status={data.status}
            nuNota={data.nuNota}
          />
        )}
      </Modal>
    </>
  );
}
