import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../../container/styled';
import { Content, FakeTableHeader } from './styles';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Pagination, Select, Spin } from 'antd';
import { VincularContanto } from './VincularContato';
import { BlockComponent } from './BlockComponent';
import { useFetch } from './useFetch';

export function FilaOfNotasClient() {
                                      const [id, setId] = useState<null | number>(null);

                                      window.addEventListener('message', function(event) {
                                        const data = JSON.parse(event.data);
                                        setId(data?.data?.contact?.id || null);
                                      });

                                      const {
                                        dataFetch,
                                        currentPage,
                                        itensPerPage,
                                        setCurrentPage,
                                        refetch,
                                        isFetching,
                                        setColumnOrdenation,
                                        setItensPerPage,
                                      } = useFetch<any>({
                                        id,
                                        baseUrl: `/contacts/get-orders`,
                                        isArray: false,
                                      });

                                      useEffect(() => {
                                        refetch();
                                      }, [id]);

                                      const [selectedNumber, setSelectedNumber] = useState({ nunota: 0, nucomp: 0 });
                                      const [sorterOrder, setSorterOrder] = useState({ order: 'ASC', field: 'PARC' });

                                      function onChangeSorterOrder(sorter: string, order: 'DESC' | 'ASC') {
                                        setColumnOrdenation(`${sorter}:${order}`);
                                        setSorterOrder({ field: sorter, order });
                                      }

                                      return (
                                        <Main>
                                          <PageHeader ghost title="Orçamentos" />

                                          {isFetching ? (
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <Spin />
                                            </div>
                                          ) : (
                                            <>
                                              {!id || dataFetch?.success === false ? (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: '2rem',
                                                  }}
                                                >
                                                  <img
                                                    src="https://botzzy.fly.dev/build/_assets/welcome-light-IK276PGO.svg"
                                                    style={{ height: 200 }}
                                                  />
                                                  {id && <VincularContanto chatwoodId={String(id)} refetch={refetch} />}
                                                </div>
                                              ) : (
                                                <>
                                                  {dataFetch?.data.length > 0 ? (
                                                    <Content>
                                                      <FakeTableHeader>
                                                        <p
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                          }}
                                                          className="data"
                                                        >
                                                          Data
                                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                                                              <CaretUpOutlined
                                                                size={5}
                                                                style={{
                                                                  height: '0.5rem',
                                                                  color:
                                                                    sorterOrder.field === 'Created_at' &&
                                                                    sorterOrder.order === 'ASC'
                                                                      ? '#40499f'
                                                                      : '#c6c6c6',
                                                                }}
                                                                onClick={() => onChangeSorterOrder('Created_at', 'ASC')}
                                                              />
                                                            </span>
                                                            <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                                                              <CaretDownOutlined
                                                                size={5}
                                                                style={{
                                                                  height: '0.5rem',
                                                                  color:
                                                                    sorterOrder.field === 'Created_at' &&
                                                                    sorterOrder.order === 'DESC'
                                                                      ? '#40499f'
                                                                      : '#c6c6c6',
                                                                }}
                                                                onClick={() =>
                                                                  onChangeSorterOrder('Created_at', 'DESC')
                                                                }
                                                              />
                                                            </span>
                                                          </div>
                                                        </p>
                                                        <p
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                          }}
                                                          className="pedido-cliente"
                                                        >
                                                          Código do pedido
                                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                                                              <CaretUpOutlined
                                                                size={5}
                                                                style={{
                                                                  height: '0.5rem',
                                                                  color:
                                                                    sorterOrder.field === 'PARC' &&
                                                                    sorterOrder.order === 'ASC'
                                                                      ? '#40499f'
                                                                      : '#c6c6c6',
                                                                }}
                                                                onClick={() => onChangeSorterOrder('PARC', 'ASC')}
                                                              />
                                                            </span>
                                                            <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                                                              <CaretDownOutlined
                                                                size={5}
                                                                style={{
                                                                  height: '0.5rem',
                                                                  color:
                                                                    sorterOrder.field === 'PARC' &&
                                                                    sorterOrder.order === 'DESC'
                                                                      ? '#40499f'
                                                                      : '#c6c6c6',
                                                                }}
                                                                onClick={() => onChangeSorterOrder('PARC', 'DESC')}
                                                              />
                                                            </span>
                                                          </div>
                                                        </p>
                                                        <p
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                          }}
                                                          className="pedido-cliente"
                                                        >
                                                          Código Único
                                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                                                              <CaretUpOutlined
                                                                size={5}
                                                                style={{
                                                                  height: '0.5rem',
                                                                  color:
                                                                    sorterOrder.field === 'PARC' &&
                                                                    sorterOrder.order === 'ASC'
                                                                      ? '#40499f'
                                                                      : '#c6c6c6',
                                                                }}
                                                                onClick={() => onChangeSorterOrder('PARC', 'ASC')}
                                                              />
                                                            </span>
                                                            <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                                                              <CaretDownOutlined
                                                                size={5}
                                                                style={{
                                                                  height: '0.5rem',
                                                                  color:
                                                                    sorterOrder.field === 'PARC' &&
                                                                    sorterOrder.order === 'DESC'
                                                                      ? '#40499f'
                                                                      : '#c6c6c6',
                                                                }}
                                                                onClick={() => onChangeSorterOrder('PARC', 'DESC')}
                                                              />
                                                            </span>
                                                          </div>
                                                        </p>
                                                        <p
                                                          className="pedido-cliente"
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                          }}
                                                        >
                                                          Tipo de Pedido/Orçamento
                                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                                                              <CaretUpOutlined
                                                                size={5}
                                                                style={{
                                                                  height: '0.5rem',
                                                                  color:
                                                                    sorterOrder.field === 'NUNOTA' &&
                                                                    sorterOrder.order === 'ASC'
                                                                      ? '#40499f'
                                                                      : '#c6c6c6',
                                                                }}
                                                                onClick={() => onChangeSorterOrder('NUNOTA', 'ASC')}
                                                              />
                                                            </span>
                                                            <span className="anticon anticon-caret-down ant-table-column-sorter-down">
                                                              <CaretDownOutlined
                                                                style={{
                                                                  color:
                                                                    sorterOrder.field === 'NUNOTA' &&
                                                                    sorterOrder.order === 'DESC'
                                                                      ? '#40499f'
                                                                      : '#c6c6c6',
                                                                }}
                                                                onClick={() => onChangeSorterOrder('NUNOTA', 'DESC')}
                                                              />
                                                            </span>
                                                          </div>
                                                        </p>
                                                      </FakeTableHeader>

                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          flexDirection: 'column',
                                                          gap: '0.5rem',
                                                        }}
                                                      >
                                                        {dataFetch?.data.map(item => (
                                                          <BlockComponent
                                                            key={item[0]}
                                                            selectedNumber={selectedNumber}
                                                            setSelectedNumber={setSelectedNumber}
                                                            id={item[0]}
                                                            data={item[0]}
                                                            codPedido={item[1]}
                                                            redirectLink={dataFetch.url_base + item[2]}
                                                            codUnic={item[2]}
                                                            items={{ cor: item[7], title: item[4] }}
                                                          />
                                                        ))}
                                                      </div>

                                                      <div>
                                                        <div
                                                          style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}
                                                        >
                                                          <Pagination
                                                            current={currentPage}
                                                            onChange={(e, i) => setCurrentPage(e)}
                                                            defaultCurrent={1}
                                                            total={dataFetch?.total}
                                                            showSizeChanger={false}
                                                          />
                                                          <Select
                                                            defaultValue={itensPerPage}
                                                            onChange={setItensPerPage}
                                                          >
                                                            <Select.Option value={10}>10 / pagina</Select.Option>
                                                            <Select.Option value={30}>30 / pagina</Select.Option>
                                                            <Select.Option value={50}>50 / pagina</Select.Option>
                                                          </Select>
                                                        </div>
                                                      </div>
                                                    </Content>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: '2rem',
                                                      }}
                                                    >
                                                      <img
                                                        src="https://botzzy.fly.dev/build/_assets/welcome-light-IK276PGO.svg"
                                                        style={{ height: 200 }}
                                                      />
                                                      <h3>Esse orçamento está vazio</h3>
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </Main>
                                      );
                                    }
