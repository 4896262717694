// eslint-disable-next-line
import React from 'react';
import { Row, Col } from 'antd';
import ProductCards from './ProductCards';
import Heading from '~/components/heading/heading';
import { NotFoundWrapper } from '../Style';

const Grid = ({ data, action, priceLabel, priceQuantity }) => {
  function handleClick(data) {
    return action(data);
  }

  return (
    <Row gutter={30}>
      {data ? (
        data.map((item, index) => (
          <ProductCards
            priceQuantity={priceQuantity}
            priceLabel={priceLabel}
            key={index}
            product={item}
            action={handleClick}
          />
        ))
      ) : (
        <Col md={24}>
          <NotFoundWrapper>
            <Heading as="h1">Data Not Found</Heading>
          </NotFoundWrapper>
        </Col>
      )}
      <Col xs={24} className="pb-30"></Col>
    </Row>
  );
};

export default Grid;
