import React from 'react';
import { Button, Upload, UploadProps } from 'antd';
import Cookies from 'js-cookie';
import { UploadFile } from 'antd/lib/upload/interface';

type IUploadComponent = {
  maxCount?: number;
  fileList: UploadFile[];
  setFileList: (event: UploadFile[]) => void;
};

export function UploadComponent({ maxCount = 5, fileList, setFileList }: IUploadComponent) {
  const token = Cookies.get('deskioapp-front-token');

  const props: UploadProps = {
    name: 'file',
    action: 'https://apiapp.deskio.com.br/api/v1/files',
    headers: {
      authorization: `Bearer ${token}`,
    },

    onChange(info) {
      if (info.fileList) {
        setFileList(info.fileList);
      }
    },
  };

  return (
    <Upload {...props} maxCount={maxCount} defaultFileList={[...fileList]}>
      <Button>Clique para carregar</Button>
    </Upload>
  );
}
