import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { ISegmentsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: ISegmentsProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Descrição',
      dataIndex: 'description',
      sorter: true,
    },

    {
      title: 'Cor',
      dataIndex: 'color',
      render: (_, data) => <p style={{ width: '3rem', height: '1.3rem', background: data.color }}></p>,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <TableButtonEdit segmentId={String(item.id)} refetch={refetch} data={item} />

            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  // const data: any[] = [];

  // dataList?.forEach(item => {
  //   data.push({
  //     key: item.id,
  //     id: item.id,
  //     name: item.name,
  //     createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
  //   });
  // });

  return { columns, data: dataList };
}
