import React from 'react';
import { Radio } from 'antd';

interface Props {
  numberPage: number;
  setNumberPage: (e: number) => void;
}

export function LeftMenu({ numberPage, setNumberPage }: Props) {
  return (
    <Radio.Group defaultValue={numberPage} size="large" onChange={e => setNumberPage(e.target.value)}>
      <Radio.Button value={0} style={{ fontSize: '16px' }}>
        Informações de contato
      </Radio.Button>
      <Radio.Button value={6} style={{ fontSize: '16px' }}>
        Assinaturas
      </Radio.Button>
      <Radio.Button value={3} style={{ fontSize: '16px' }}>
        Histórico de pagamentos
      </Radio.Button>
      <Radio.Button value={4} style={{ fontSize: '16px' }}>
        Informações da Empresa
      </Radio.Button>
      <Radio.Button value={5} style={{ fontSize: '16px' }}>
        Diversos
      </Radio.Button>
      
    </Radio.Group>
  );
}
