import React, { useEffect, useRef, useState } from 'react';

import { Button, Input, Form as AntdForm } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { SelectPar } from '../selects/SelectPar';
import styled from 'styled-components';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ReactInputMask from 'react-input-mask';
import { Select } from '../../../components/Select';

const StyledInput = styled(PhoneInput)`
  input {
    border: none !important;
    outline: none !important;
  }
`;

interface ICabecalhoFields {
  inputName: string;
  inputLabel: string;
  required: boolean;
  disabled: boolean;
  defaultValue: string;
  type: 'select' | 'input';
  options: {
    label: string;
    value: string;
  }[];
}

interface formFieldApi {
  '@attributes': {
    descricao: string;
    editavel: string;
    nome: string;
    requerido: string;
  };
  opcao?: {
    '@attributes': {
      descricao: string;
      valor: string;
    };
  }[];
}

interface IFormContact {
  formType: 'put' | 'post';
  initialFields?: any;
  refetch: () => void;
  contactId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ formType, setModalIsOpen, refetch, contactId, initialFields }: IFormContact) {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<any>(null);
  const [value, setValue] = useState(initialFields?.phone_number || '');

  const params = useParams<any>();
  const token = Cookies.get('deskioapp-front-token');

  async function get() {
    if (!params?.nunota) return;

    const resFields = await axios.post<formFieldApi[]>(
      'https://apiapp.deskio.com.br/api/v1/quotes/get-cabecalho',
      {},
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      },
    );

    const apiValues = await api.post(
      '/quotes/get-cabecalho-valores',
      {
        nunota: params.nunota,
      },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      },
    );

    const extraFields = [
      'CODTIPOPER',
      'TipoNegociacao_DESCRTIPVENDA',
      'Parceiro_NOMEPARC',
      'TipoOperacao_DESCROPER',
      'Empresa_NOMEFANTASIA',
      'Vendedor_APELIDO',
      'Natureza_DESCRNAT',
      'STATUSNOTA',
      'PENDENTE',
      'VLRNOTA',
      'PERCDESC',
      'VLRDESCTOT',
    ];

    const ignoreFields = [
      'CODNAT',
      'CODPARC',
      'CODTIPVENDA',
      'NUNOTA',
      'CODEMP',
      'CODTIPOPER',
      'DTFATUR',
      'DTNEG',
      'AD_TABELA',
      'DTFATUR',
      'CODVEND',
      'OBSERVACAO',
      'NUMNOTA',
    ];

    if (resFields) {
      const myData: ICabecalhoFields[] = [];
      const allData: ICabecalhoFields[] = [];
      const values: any = [];

      extraFields.forEach(item => {
        const indexExtraValue = apiValues.data.entities.metadata.fields.field.findIndex(
          valueItem => valueItem['@attributes'].name === item,
        );

        values.push({ name: item, value: apiValues.data.entities.entity[`f${indexExtraValue}`] });
      });

      resFields.data.forEach(item => {
        const options = item.opcao?.map(item => {
          return {
            label: item['@attributes'].descricao,
            value: item['@attributes'].valor,
          };
        });

        const indexValue = apiValues.data.entities.metadata.fields.field.findIndex(
          valueItem => valueItem['@attributes'].name === item['@attributes'].nome,
        );

        allData.push({
          inputName: item['@attributes'].nome,
          inputLabel: item['@attributes'].descricao,
          disabled: item['@attributes'].editavel === 'false' ? true : false,
          required: item['@attributes'].requerido === 'true',
          type: !!item.opcao ? 'select' : 'input',
          options: options || [],
          defaultValue: apiValues.data.entities.entity[`f${indexValue}`],
        });

        if (ignoreFields.includes(item['@attributes'].nome)) return;

        myData.push({
          inputName: item['@attributes'].nome,
          inputLabel: item['@attributes'].descricao,
          disabled: item['@attributes'].editavel === 'false' ? true : false,
          required: item['@attributes'].requerido === 'true',
          type: !!item.opcao ? 'select' : 'input',
          options: options || [],
          defaultValue: apiValues.data.entities.entity[`f${indexValue}`],
        });
      });
    }
  }

  useEffect(() => {
    get();
  }, []);

  const [codParState, setCodParState] = useState(initialFields?.codparc || '');

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);
    try {
      if (formType === 'post') {
        await api.post('/contacts', {
          ...data,
          phone_number: value,
        });
      } else {
        if (contactId)
          await api.put(`/contacts/${contactId}`, {
            ...data,
            phone_number: value,
          });
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
    } catch (err) {}

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome obrigatório' }]}>
            <Input placeholder="Nome Completo" />
          </AntdForm.Item>
          <AntdForm.Item label="Email" name="email" rules={[{ required: true, message: 'Nome obrigatório' }]}>
            <Input placeholder="Email" />
          </AntdForm.Item>

          <div>
            <span>Telefone</span>
            <StyledInput
              className="ant-input"
              international={false}
              placeholder="Adicione um numero de celular"
              defaultCountry="BR"
              value={value}
              onChange={setValue}
            />
            <br />
          </div>

          <SelectPar onChange={e => setCodParState(e)} defaultValue={initialFields?.codparc} />

          <Select
            postUrl="/quotes/save-new-contact-sankhya"
            name="codcontato"
            title="Vincule a um Contato do Sankhya"
            formModalInitialValues={{
              codpar: codParState,
            }}
            methosPostValue={{
              codpar: codParState,
            }}
            disabled={!codParState}
            placeholder="Pesquise por um contato no Sankhya"
            method="post"
            addText="Adicionar contato"
            addTextModal="Adicionar novo Contato no Sankhya"
            fetchUrl="/quotes/search-codcontato"
            modalContent={
              <>
                <AntdForm.Item
                  label="Nome do contato"
                  name="NOMECONTATO"
                  rules={[{ required: true, message: 'Título é obrigatório' }]}
                >
                  <Input placeholder="Nome do contato" />
                </AntdForm.Item>
                <AntdForm.Item
                  label="Telefone"
                  name="TELEFONE"
                  rules={[{ required: true, message: 'Telefone é obrigatório' }]}
                >
                  <ReactInputMask className="ant-input" mask="(99) 99999-9999" placeholder="telefone" />
                </AntdForm.Item>
                <AntdForm.Item rules={[{ max: 15, message: 'Máximo de 15 caracteres' }]} label="Apelido" name="APELIDO">
                  <Input placeholder="Apelido" />
                </AntdForm.Item>
                <AntdForm.Item label="Email" name="EMAIL">
                  <Input placeholder="email" />
                </AntdForm.Item>

                <Button htmlType="submit" type="primary">
                  Salvar Contato
                </Button>
              </>
            }
          />

          <AntdForm.Item label="Facebook" name="facebook">
            <Input placeholder="Facebook" />
          </AntdForm.Item>
          <AntdForm.Item label="Instagram" name="instagram">
            <Input placeholder="Instagram" />
          </AntdForm.Item>
          <AntdForm.Item label="Linkedin" name="linkedin">
            <Input placeholder="Linkedin" />
          </AntdForm.Item>
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
