import React, { useState, useEffect, Suspense } from 'react';
import Cookie from 'js-cookie';
import { Row, Col, Spin, InputNumber, Typography, Modal } from 'antd';
import { useParams, Switch, useHistory } from 'react-router-dom';
import { BarcodeOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { PageHeader } from '~/components/page-headers/page-headers';
import { Main } from '~/container/styled';
import { Button } from '~/components/buttons/buttons';
import { TopToolBox, HorizontalSeparator, InputError, ModalDivergente } from './Style';
import EmptyMessage from '~/components/emptyMessage';
import ItemsList from './overview/ItemsList';
import { ThemeLayout } from '../../layout/themeLayout';
import { useAuth } from '../../hooks/useAuth';
import {
  CONFERENCE_ITEMS_URL,
  SAVE_ITEM_CONFERRED_URL,
  INFO_CONFERENCE_QUEUE_URL,
  STATUS_CONFERRED_ITEMS_URL,
  END_CONFERENCE_URL,
} from '~/utility/useLinks';
import axios from 'axios';
import { api } from '../../services/api';
const { Text } = Typography;

function ConferenceDetails() {
  const history = useHistory();
  const { codPedido } = useParams();
  const [isLoader, setIsLoader] = useState(false);
  const [endLoading, setEndLoading] = useState(false);
  const [conferenceLoading, setConferenceLoading] = useState(false);
  const [topInfo, setTopInfo] = useState(null);
  const [conferenceData, setConferenceData] = useState(null);
  const [barcodeText, setBarcodeText] = useState(null);
  const [barcodeError, setBarcodeError] = useState(false);
  const [quantityText, setQuantityText] = useState(1);
  const [numConf, setNumConf] = useState(null);
  const [extraData, setExtraData] = useState(null);
  const [divergencias, setDivergencias] = useState(null);
  const token = Cookie.get('deskioapp-front-token');
  const { errorSessionTokenControl } = useAuth();

  const [conferindoDivergencias, setConferindoDivergencias] = useState(false);

  const [modalDivergente, setModalDivergente] = useState(false);
  const [produtosDivergentes, setProdutosDivergentes] = useState(undefined);

  async function fetchData() {
    setIsLoader(true);

    const response = await axios.post(
      CONFERENCE_ITEMS_URL,
      {
        cod_pedido: codPedido,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.data) {
      const responseBody = response.data.responseBody;
      const responseBodyData = response.data;
      if (responseBodyData?.success == false) {
        errorSessionTokenControl(responseBodyData);
      }

      if (responseBody?.result) {
        let newArray = [];

        responseBody?.result.forEach(item => {
          const existInArray = newArray.find(itemnew => itemnew[0] === item[0]);
          if (existInArray) {
            const arrayFilter = newArray.filter(itens => itens[0] !== item[0]);
            const newItem = [
              item[0],
              item[1],
              item[2],
              item[3],
              Number(item[4]) + Number(existInArray[4]),
              item[5],
              item[6],
              item[7],
              item[8],
              item[9],
              item[10],
              item[11],
              item[12],
              item[13],
              item[14],
              item[15],
              item[16],
              item[17],
            ];

            arrayFilter.push(newItem);
            newArray = arrayFilter;
          } else {
            newArray.push(item);
          }
        });

        setConferenceData(newArray);
      }
      const responseInfo = await axios.post(
        INFO_CONFERENCE_QUEUE_URL,
        {
          nuNota: codPedido,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      const responseInfoBodyData = responseInfo.data;
      if (responseInfoBodyData?.success == false) {
        errorSessionTokenControl(responseInfoBodyData);
      }
      const responseInfoBody = responseInfo.data.responseBody;

      setNumConf(responseInfoBody.numConf);
      setTopInfo({
        nroNota: responseInfoBody.numNota,
        vendedor: responseInfoBody.vendedor.$,
        parceiro: responseInfoBody.parceiro.$,
      });

      const responseStatus = await axios.post(
        STATUS_CONFERRED_ITEMS_URL,
        {
          nuConf: responseInfoBody.numConf,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      const responseStatusData = responseStatus.data;
      if (responseStatusData?.success == false) {
        errorSessionTokenControl(responseStatusData);
      }

      const clientEvents = responseStatus.data.responseBody;
      const diverg = clientEvents.result;
      setDivergencias(diverg);
    }
    setIsLoader(false);
  }

  useEffect(() => {
    fetchData();
  }, [codPedido]);

  async function refetchData() {
    const response = await axios.post(
      CONFERENCE_ITEMS_URL,
      {
        cod_pedido: codPedido,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    const responseStatus2Data = response.data;
    if (responseStatus2Data?.success == false) {
      errorSessionTokenControl(responseStatus2Data);
    }

    if (response.data) {
      const responseBody = response.data.responseBody;
      if (responseBody?.result) {
        let newArray = [];

        responseBody?.result.forEach(item => {
          const existInArray = newArray.find(itemnew => itemnew[0] === item[0]);
          if (existInArray) {
            const arrayFilter = newArray.filter(itens => itens[0] !== item[0]);
            const newItem = [
              item[0],
              item[1],
              item[2],
              item[3],
              Number(item[4]) + Number(existInArray[4]),
              item[5],
              item[6],
              item[7],
              item[8],
              item[9],
              item[10],
              item[11],
              item[12],
              item[13],
              item[14],
              item[15],
              item[16],
              item[17],
            ];

            arrayFilter.push(newItem);
            newArray = arrayFilter;
          } else {
            newArray.push(item);
          }
        });

        setConferenceData(newArray);
      }

      const responseInfo = await axios.post(
        INFO_CONFERENCE_QUEUE_URL,
        {
          nuNota: codPedido,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );

      const responseInfo2Data = responseInfo.data;
      if (responseInfo2Data?.success == false) {
        errorSessionTokenControl(responseInfo2Data);
      }

      const responseInfoBody = responseInfo.data.responseBody;
      setNumConf(responseInfoBody.numConf);
      setTopInfo({
        nroNota: responseInfoBody.numNota,
        vendedor: responseInfoBody.vendedor.$,
        parceiro: responseInfoBody.parceiro.$,
      });
      const responseStatus = await axios.post(
        STATUS_CONFERRED_ITEMS_URL,
        {
          nuConf: responseInfoBody.numConf,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );

      const responseStatus3Data = responseStatus.data;
      if (responseStatus3Data?.success == false) {
        errorSessionTokenControl(responseStatus3Data);
      }

      const clientEvents = responseStatus.data.responseBody;
      const diverg = clientEvents.result;
      setDivergencias(diverg);
    }
  }

  function closeModalDivergente() {
    setModalDivergente(false);
    setConferindoDivergencias(false);
  }

  async function conferirItem() {
    if (barcodeText) {
      setBarcodeError(false);
      const filter = conferenceData.filter(
        obj => String(obj[0]) === String(barcodeText) || String(obj[16]) === String(barcodeText),
      );
      const total = filter[0][4];
      const codProd = filter[0][0];

      async function fetchData() {
        const res = await axios.post(
          SAVE_ITEM_CONFERRED_URL,
          {
            numConf: numConf,
            nunNota: codPedido,
            codBar: String(barcodeText),
            qtde: quantityText,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );

        const responseStatus4Data = res.data;
        if (responseStatus4Data?.success == false) {
          errorSessionTokenControl(responseStatus4Data);
        }

        if (res.data.status == '0') {
          Modal.warning({
            title: 'Erro!',
            content: (
              <div>
                <p>{res.data.statusMessage}</p>
              </div>
            ),
          });
          setConferenceLoading(false);
        } else {
          setConferenceLoading(false);
          setExtraData({
            barcode: String(barcodeText),
            qtde: quantityText,
          });
        }
        setBarcodeText('');
      }
      fetchData();
    } else {
      setBarcodeError(true);
    }
    setTimeout(() => {
      setExtraData(null);
    }, 200);
  }

  async function finalizarConferencia() {
    setEndLoading(true);

    async function fetchData() {
      const res = await axios.post(
        END_CONFERENCE_URL,
        {
          nuConf: numConf,
          codPedido: codPedido,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );

      const responseStatus5Data = res.data;
      if (responseStatus5Data?.success == false) {
        errorSessionTokenControl(responseStatus5Data);
      }

      if (res.data.status == '1') {
        setConferindoDivergencias(false);
        setEndLoading(false);

        history.push(`/admin/conference`);
        if (produtosDivergentes) {
          Modal.success({
            title: 'Sucesso!',
            content: (
              <div>
                <p>Conferência Finalizada com Sucesso (Divergente)!</p>
              </div>
            ),
          });

          setProdutosDivergentes(undefined);
        } else {
          Modal.success({
            title: 'Sucesso!',
            content: (
              <div>
                <p>Conferência Finalizada com Sucesso!</p>
              </div>
            ),
          });
        }
      } else {
        Modal.warning({
          title: 'Erro!',
          content: (
            <div>
              <p>{res.data.statusMessage}</p>
            </div>
          ),
        });
        setEndLoading(false);
        setConferindoDivergencias(false);
      }
    }
    fetchData();
  }

  function confirm() {
    Modal.confirm({
      title: 'Finalizar Conferência',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja realmente finalizar a conferência?',
      okText: 'OK',
      cancelText: 'Cancelar',
      onOk: () => finalizarConferencia(),
      //onOk: () => {},
    });
  }

  async function conferirProdutoDivergente() {
    setConferindoDivergencias(true);
    const res = await api.post(
      `/consulta-produtos-divergentes`,
      {
        nuNota: codPedido,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    if (res.data.responseBody.produtos.produto) {
      if (Array.isArray(res.data.responseBody.produtos.produto)) {
        setProdutosDivergentes(res.data.responseBody.produtos.produto);
      } else {
        setProdutosDivergentes([res.data.responseBody.produtos.produto]);
      }
      setModalDivergente(true);
    } else {
      confirm();
    }
  }

  useEffect(() => {
    if (barcodeText) {
      setBarcodeError(false);
    }
  }, [barcodeText]);

  return (
    <ThemeLayout>
      <PageHeader ghost title="Conferência de Itens" />
      <Main>
        {/* <Row gutter={5}> */}
        {/* <Col flex={1} className="product-content-col" xxl={24} lg={24} md={14} xs={24}> */}
        <TopToolBox style={{ paddingLeft: 5 }}>
          <Col xs={24}>
            <Row gutter={[16, 16]}>
              <Text type="secondary">
                <Text strong>Pedido:</Text> {!topInfo ? 'Carregando...' : topInfo.nroNota}
              </Text>
              <HorizontalSeparator />
              <Text type="secondary">
                <Text strong>Vendedor:</Text> {!topInfo ? 'Carregando...' : topInfo.vendedor}
              </Text>
              <HorizontalSeparator />
              <Text type="secondary">
                <Text strong>Parceiro:</Text> {!topInfo ? 'Carregando...' : topInfo.parceiro}
              </Text>
            </Row>
          </Col>
        </TopToolBox>
        <TopToolBox style={{ background: '#F4F5F7', padding: '20px 0', marginTop: -20 }}>
          <Col xs={24}>
            <Row gutter={[16, 16]}>
              <Col className="ant-row" xs={24} xl={8}>
                <InputNumber
                  addonAfter={<BarcodeOutlined />}
                  placeholder="Código de barras"
                  value={barcodeText}
                  onChange={txt => setBarcodeText(txt)}
                  controls={false}
                  style={{
                    width: '100%',
                    border: `1px solid ${barcodeError ? '#f5222d' : 'transparent'}`,
                    borderRadius: 6,
                  }}
                />
              </Col>

              <Col className="ant-row" xs={5} xl={4}>
                <InputNumber
                  value={quantityText}
                  onChange={txt => setQuantityText(txt)}
                  placeholder="Quantidade"
                  min="1"
                  style={{ width: '100%' }}
                />
              </Col>

              <Col className="ant-row" xs={11} xl={4}>
                <Button
                  icon={<CheckOutlined />}
                  onClick={conferirItem}
                  size="large"
                  type="primary"
                  loading={conferenceLoading}
                >
                  {conferenceLoading ? 'Conferindo...' : 'Conferir'}
                </Button>
              </Col>

              <Col className="ant-row" xs={8} xl={4}>
                <Button
                  style={{ background: 'white' }}
                  // onClick={confirm}
                  onClick={conferirProdutoDivergente}
                  size="large"
                  type="danger"
                  outlined
                  loading={conferindoDivergencias}
                >
                  {conferindoDivergencias ? 'Finalizando...' : 'Finalizar'}
                </Button>
              </Col>
            </Row>
            {barcodeError && <InputError>Digite o código de barras ou o código do produto</InputError>}
          </Col>
        </TopToolBox>
        {isLoader ? (
          <div className="spin d-flex align-center-v">
            <Spin />
          </div>
        ) : (
          <>
            {!conferenceData ? (
              <Col xs={24}>
                <div className="spin">
                  <EmptyMessage text="Nenhuma informação" iconSize={70} textSize={20} />
                </div>
              </Col>
            ) : (
              <Switch>
                <Suspense
                  fallback={
                    <div className="spin d-flex align-center-v">
                      <Spin />
                    </div>
                  }
                >
                  <ItemsList
                    refetchData={refetchData}
                    setExtraData={setExtraData}
                    numConf={numConf}
                    data={conferenceData}
                    extraData={extraData}
                    divergencias={divergencias}
                  />
                </Suspense>
              </Switch>
            )}
          </>
        )}
        {/* </Col> */}
        {/* </Row> */}

        <Modal
          title="Sua conferencia está divergente, fechar mesmo assim?"
          visible={modalDivergente}
          onCancel={closeModalDivergente}
          confirmLoading={true}
          footer={null}
          maskClosable={false}
          width={1200}
          style={{ marginTop: -60 }}
          bodyStyle={{ padding: 0 }}
        >
          <ModalDivergente>
            <h2>Itens Divergentes:</h2>
            {produtosDivergentes &&
              produtosDivergentes.map(produto => (
                <div>
                  <p style={{ flex: 3 }}>{produto.DESCRPROD.$}</p>
                  <p>
                    <span>Cód:</span>
                    {produto.CODPROD.$}
                  </p>

                  <p style={{ color: 'red', margin: 'unset' }}>
                    <span>Qtde:</span>
                    {parseFloat(produto.QTDPEDIDO.$)}
                  </p>
                  <p style={{ color: 'green', margin: 'unset' }}>
                    <span>Conferido:</span>
                    {parseFloat(produto.QTDCONFERIDA.$)}
                  </p>
                  <p style={{ color: 'blue', margin: 'unset' }}>
                    <span>QTD Restante:</span>
                    {parseFloat(produto.QTDPEDIDO.$) - parseFloat(produto.QTDCONFERIDA.$)}
                  </p>
                </div>
              ))}

            <footer>
              <Button
                style={{ background: 'white' }}
                onClick={closeModalDivergente}
                size="large"
                outlined
                loading={endLoading}
              >
                Cancelar
              </Button>

              <Button
                style={{ background: 'white' }}
                onClick={finalizarConferencia}
                size="large"
                type="danger"
                outlined
                loading={endLoading}
              >
                {endLoading ? 'Finalizando...' : 'Finalizar mesmo assim'}
              </Button>
            </footer>
          </ModalDivergente>
        </Modal>
      </Main>
    </ThemeLayout>
  );
}

export default ConferenceDetails;
