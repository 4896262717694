import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import { ExclamationCircleOutlined, CheckOutlined, SyncOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Heading from '~/components/heading/heading';
import { ProductCard } from '../Style';
import Cookies from 'js-cookie';

const ItemsCardList = ({ item, data, divergencias, onClick }) => {
  const [image, setImage] = useState(null);
  const [qtyChecked, setQtyChecked] = useState(0);
  const [status, setStatus] = useState('AGUARDANDO');
  const cookies_user = JSON.parse(Cookies.get('strutural-user'));

  useEffect(() => {
    if (item) {
      const url = cookies_user.urlimagens.replace('{CODPROD}', item[0]);
      const defaultUrl = require('../../../static/img/produto-sem-imagem.png');
      const img = new Image();
      img.src = url;
      if (img.complete) {
        setImage(url);
      } else {
        img.onload = () => {
          setImage(url);
        };

        img.onerror = () => {
          setImage(defaultUrl);
        };
      }
    }
    if (data) {
      if ((data.barcode === item[16] || data.barcode === item[0]) && qtyChecked < parseFloat(item[4])) {
        const total = qtyChecked + parseFloat(data.qtde);
        if (total > 0 && total < item[4]) {
          setQtyChecked(total);
          setStatus('EM_CONFERENCIA');
        } else if (total >= parseFloat(item[4])) {
          setQtyChecked(parseFloat(item[4]));
          setStatus('CONFERIDO');
        }
      }
    }
  }, [item, data, cookies_user.urlimagens, qtyChecked]);

  useEffect(() => {
    if (Array.isArray(divergencias)) {
      let qtd = 0;

      divergencias.forEach(unic => {
        if (unic[3] === item[0]) {
          qtd = qtd + Number(unic[8]);
        }
      });

      if (qtd >= parseFloat(item[4])) {
        setQtyChecked(parseFloat(item[4]));
        setStatus('CONFERIDO');
      } else {
        setQtyChecked(qtd);

        if (qtd === 0) setStatus('AGUARDANDO');
        else setStatus('EM_CONFERENCIA');
      }
    }
  }, [divergencias, item]);

  return (
    <ProductCard style={{ marginBottom: 20 }} onClick={onClick}>
      <div className="product-list-pc">
        <div className="areaImgButtons">
          <figure>
            <img style={{ width: '100%' }} src={image} alt="" />
          </figure>

          <div className="itemDiv" style={{ background: '#f7b542' }}>
            <p>Qtd. Total:</p>
            <p>{parseFloat(item[4])}</p>
          </div>

          <div className="itemDiv" style={{ background: 'green' }}>
            <p>Conferidos:</p>
            <p>{parseFloat(qtyChecked)}</p>
          </div>

          <div className="itemDiv" style={{ background: '#f94343' }}>
            <p>Restante:</p>
            <p>{parseFloat(item[4]) - parseFloat(qtyChecked)}</p>
          </div>
        </div>

        <div className="product-single-description">
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'left' }}>
            {window.screen.width <= 767 && (
              <div style={{ width: 70, height: 70, overflow: 'hidden' }}>
                <figure>
                  <img style={{ width: '100%' }} src={image} alt="" />
                </figure>
              </div>
            )}
            <div style={{ paddingLeft: window.screen.width > 767 ? 0 : 15 }}>
              <Heading className="product-single-title" as="h5">
                {item[1]}
              </Heading>
            </div>
          </div>

          <div className="itemDiv" style={{ background: '#4B5CD9', marginBottom: '1rem' }}>
            <p>Cód:</p>
            <p>{item[0]}</p>
          </div>

          <p>
            <strong>Unidade:</strong> {item[5]} <br />
            <strong>Descrição Local:</strong> {item[11]} <br />
            <strong>Ref. Fornecedor:</strong> {item[13]} <br />
            <strong>Marca:</strong> {item[15]} <br />
            <strong>Cód. Barras:</strong> {item[16]} <br />
          </p>

          <div className="itemDiv" style={{ background: '#4B5CD9', marginTop: '1rem' }}>
            <p>Cód. Local:</p>
            <p>{item[10]}</p>
          </div>
        </div>

        <Tag
          icon={
            status === 'AGUARDANDO' ? (
              <ExclamationCircleOutlined />
            ) : status === 'EM_CONFERENCIA' ? (
              <SyncOutlined spin />
            ) : (
              <CheckOutlined />
            )
          }
          color={status === 'AGUARDANDO' ? 'warning' : status === 'EM_CONFERENCIA' ? 'processing' : 'success'}
          style={{ padding: '8px 10px', fontSize: 13, marginLeft: 'auto' }}
        >
          {status === 'AGUARDANDO'
            ? 'Aguardando Conferência'
            : status === 'EM_CONFERENCIA'
            ? 'Em Conferência'
            : 'Conferido'}
        </Tag>
      </div>
      {window.screen.width <= 767 && (
        <div className="product-title">
          <Heading className="product-single-title" as="h5">
            {item[1]}
          </Heading>
        </div>
      )}
      <div className="product-list-mobile">
        <div className="product-single-description">
          <Tag
            icon={
              status === 'AGUARDANDO' ? (
                <ExclamationCircleOutlined />
              ) : status === 'EM_CONFERENCIA' ? (
                <SyncOutlined spin />
              ) : (
                <CheckOutlined />
              )
            }
            color={status === 'AGUARDANDO' ? 'warning' : status === 'EM_CONFERENCIA' ? 'processing' : 'success'}
            style={{ padding: '8px 10px', fontSize: 13, marginLeft: 'auto' }}
          >
            {status === 'AGUARDANDO'
              ? 'Aguardando Conferência'
              : status === 'EM_CONFERENCIA'
              ? 'Em Conferência'
              : 'Conferido'}
          </Tag>

          <p>
            <div className="itemDiv" style={{ background: '#4B5CD9', margin: '1rem 0' }}>
              <p>Cód:</p>
              <p>{item[0]}</p>
            </div>
            <strong>Nome:</strong> {item[1]} <br />
            <strong>Unidade:</strong> {item[5]} <br />
            <strong>Descrição Local:</strong> {item[11]} <br />
            <strong>Ref. Fornecedor:</strong> {item[13]} <br />
            <strong>Marca:</strong> {item[15]} <br />
            <strong>Cód. Barras:</strong> {item[16]} <br />
          </p>
        </div>

        <div className="areaImgButtons">
          <figure>
            <img style={{ width: '100%' }} src={image} alt="" />
          </figure>

          <div className="itemDiv" style={{ background: '#f7b542' }}>
            <p>Qtd. total:</p>
            <p>{parseFloat(item[4])}</p>
          </div>

          <div className="itemDiv" style={{ background: 'green' }}>
            <p>Conferidos:</p>
            <p>{parseFloat(qtyChecked)}</p>
          </div>

          <div className="itemDiv" style={{ background: '#f94343' }}>
            <p>Restante:</p>
            <p>{parseFloat(item[4]) - parseFloat(qtyChecked)}</p>
          </div>
        </div>
      </div>
    </ProductCard>
  );
};

ItemsCardList.propTypes = {
  product: PropTypes.array,
};

export default ItemsCardList;
