import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../../container/styled';
import { ThemeLayout } from '../../layout/themeLayout';
import moment from 'moment';
import { Container } from './styles';
import { TableComponent } from './table';
import Cookies from 'js-cookie';
import { api } from '../../services/api';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import uuid from 'react-uuid';

import { Select as FetchSelect } from '../../components/Select';

import { IProductDTO } from './types';
import { handleCreateRow } from './utils';
import { getTableProducts } from './functions';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import CurrencyInput from 'react-currency-input-field';
import { SelectPar } from './Selectes/SelectPar';
import { SelectCodEmp } from './Selectes/SelectCodEmp';
import { SelectTipOper } from './Selectes/SelectTipOper';
import { SelectTipVenda } from './Selectes/SelectTipVenda';
import TextArea from 'antd/lib/input/TextArea';
import { useAuth } from '../../hooks/useAuth';
import { treatment } from '../../hooks/treatment';
import ReactInputMask from 'react-input-mask';
import { SelectCodNat } from './Selectes/SelectCodNat';

interface ICabecalhoFields {
  inputName: string;
  inputLabel: string;
  required: boolean;
  disabled: boolean;
  defaultValue: string;
  type: 'select' | 'input';
  options: {
    label: string;
    value: string;
  }[];
}

interface formFieldApi {
  '@attributes': {
    descricao: string;
    editavel: string;
    nome: string;
    requerido: string;
  };
  opcao?: {
    '@attributes': {
      descricao: string;
      valor: string;
    };
  }[];
}

const Head = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1 / span 2;

  padding-bottom: 1rem;
  margin-bottom: 1rem;

  border-bottom: 1px solid #e5e5e5;

  button {
    margin: 0px 0px 0px auto !important;
  }
`;

const DatePickerStyle = styled.div`
  .ant-picker {
    width: 100%;
    min-height: 3rem !important;
    max-height: 3rem !important;
  }

  input {
    padding: 1rem !important;
  }
`;

const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 1rem;
  margin-bottom: 1rem;

  border-bottom: 1px solid #e5e5e5;

  button {
    margin: 0px 0px 0px auto !important;
  }
`;

const FormWrapper = styled(Form)`
  display: grid;
  background: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
`;

const AntdSelect = styled(Select)`
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function EditOrcamento() {
  const [state, setState] = useState<IProductDTO[]>([]);
  const [fields, setFields] = useState<ICabecalhoFields[]>([]);
  const [allFields, setAllFields] = useState<ICabecalhoFields[]>([]);
  const [allDefaultFields, setAllDefaultFields] = useState<any[]>([]);
  const [saveds, setSaveds] = useState<{ id: string; value: 'saved' | 'created' | 'editaded' }[]>([]);
  const params = useParams<any>();
  const token = Cookies.get('deskioapp-front-token');
  const { errorSessionTokenControl } = useAuth();
  const user: any = JSON.parse(Cookies.get('strutural-user') || '');

  const searchParams = new URLSearchParams(window.location.search);
  const CODTIPOPER = searchParams.get('type') || '900';

  function chamarFuncao(callbackFunction: any) {
    if (isDisabled) {
      Modal.warning({
        title: 'Erro!',
        content: (
          <div>
            <p>Salve primeiro os itens antes de executar essa ação</p>
          </div>
        ),
      });
      return;
    }

    callbackFunction();
  }

  async function fetch() {
    await getTableProducts(params.nunota).then(response => {
      if (!Array.isArray(response)) {
        errorSessionTokenControl(response);
        return;
      }
      const array: IProductDTO[] = [];

      response.forEach((item, index) => {
        const vlrDescCalc = (Number(item.VLRUNIT) * Number(item.PERCDESC)) / 100;

        array.push({
          codlocalorig: item.CODLOCALORIG,
          sequencia: item.SEQUENCIA,
          codprod: item.CODPROD,
          codvol: item.CODVOL,
          descprod: item.DESCRPROD,
          id: uuid(),
          percdesc: item.PERCDESC,
          img: user.urlimagens.replace('{CODPROD}', item.CODPROD),
          position: index + 1,
          qtdneg: Number(item.QTDNEG),
          vlrdesc: Number(item.VLRDESC),
          vlrliq: !vlrDescCalc
            ? Number((Number(item.QTDNEG) * Number(item.VLRUNIT)).toFixed(2))
            : Number(((Number(item.VLRUNIT) - vlrDescCalc) * Number(item.QTDNEG)).toFixed(2)),
          vlrtot: Number(item.VLRTOT),
          vlrunit: Number(item.VLRUNIT),
        });
      });
      setState(array);

      array.forEach(item => {
        setSaveds(olsState => [...olsState, { id: item.codprod, value: 'saved' }]);
      });
    });
  }

  const [f, setF] = useState<any>({});

  useEffect(() => {
    fetch();
  }, []);
  const [codParState, setCodParState] = useState('');
  useEffect(() => {
    setCodParState(allFields.find(item => item.inputName === 'CODPARC')?.defaultValue || '');
  }, [allFields]);

  const [resFieldsBASE, setResFieldsBASE] = useState<any>(null);
  const [cabecalhoTOP, setCabecalhoTOP] = useState<any>(null);

  async function get() {
    if (!params?.nunota) return;

    const [dataa, dada2] = await Promise.all([
      await axios.post(
        'https://apiapp.deskio.com.br/api/v1/quotes/get-cabecalho',
        { CODTIPOPER },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      ),
      await axios.post(
        'https://apiapp.deskio.com.br/api/v1/quotes/get-cabecalho-top',
        { CODTIPOPER },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      ),
    ]);

    setCabecalhoTOP(dada2.data.data);

    treatment(dataa.data);

    const apiValues = await api.post(
      '/quotes/get-cabecalho-valores',
      {
        nunota: params.nunota,
      },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      },
    );

    treatment(apiValues.data);

    const extraFields = [
      'CODTIPOPER',
      'TipoNegociacao_DESCRTIPVENDA',
      'Parceiro_NOMEPARC',
      'TipoOperacao_DESCROPER',
      'Empresa_NOMEFANTASIA',
      'Vendedor_APELIDO',
      'Natureza_DESCRNAT',
      'STATUSNOTA',
      'PENDENTE',
      'VLRNOTA',
      'PERCDESC',
      'VLRDESCTOT',
    ];

    const ignoreFields = [
      'CODTIPVENDA',
      'NUNOTA',
      'CODEMP',
      'CODTIPOPER',
      'DTFATUR',
      'DTNEG',
      'AD_TABELA',
      'DTFATUR',
      'CODVEND',
      'CODPARC',
      'OBSERVACAO',
      'NUMNOTA',
      'CODCONTATO',
    ];

    if (dataa) {
      setResFieldsBASE(dataa);

      const myData: ICabecalhoFields[] = [];
      const allData: ICabecalhoFields[] = [];
      const values: any = [];

      setF(apiValues.data.entities.entity);

      extraFields.forEach(item => {
        const indexExtraValue = apiValues.data.entities.metadata.fields.field.findIndex(
          valueItem => valueItem['@attributes'].name === item,
        );

        values.push({ name: item, value: apiValues.data.entities.entity[`f${indexExtraValue}`] });
      });

      dataa.data.forEach(item => {
        const options = item.opcao?.map(item => {
          return {
            label: item['@attributes'].descricao,
            value: item['@attributes'].valor,
          };
        });

        const indexValue = apiValues.data.entities.metadata.fields.field.findIndex(
          valueItem => valueItem['@attributes'].name === item['@attributes'].nome,
        );

        allData.push({
          inputName: item['@attributes'].nome,
          inputLabel: item['@attributes'].descricao,
          disabled: item['@attributes'].editavel === 'false' ? true : false,
          required: item['@attributes'].requerido === 'true',
          type: !!item.opcao ? 'select' : 'input',
          options: options || [],
          defaultValue: apiValues.data.entities.entity[`f${indexValue}`],
        });

        if (ignoreFields.includes(item['@attributes'].nome)) return;

        myData.push({
          inputName: item['@attributes'].nome,
          inputLabel: item['@attributes'].descricao,
          disabled: item['@attributes'].editavel === 'false' ? true : false,
          required: item['@attributes'].requerido === 'true',
          type: !!item.opcao ? 'select' : 'input',
          options: options || [],
          defaultValue: apiValues.data.entities.entity[`f${indexValue}`],
        });
      });

      setAllDefaultFields(values);
      setAllFields(allData);
      setFields(myData);
    }
  }
  useEffect(() => {
    get();
  }, []);

  async function att() {
    if (!resFieldsBASE) return;

    const apiValues = await api.post(
      '/quotes/get-cabecalho-valores',
      {
        nunota: params.nunota,
      },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      },
    );

    treatment(apiValues.data);

    const extraFields = [
      'CODTIPOPER',
      'TipoNegociacao_DESCRTIPVENDA',
      'Parceiro_NOMEPARC',
      'TipoOperacao_DESCROPER',
      'Empresa_NOMEFANTASIA',
      'Vendedor_APELIDO',
      'Natureza_DESCRNAT',
      'STATUSNOTA',
      'PENDENTE',
      'VLRNOTA',
      'PERCDESC',
      'VLRDESCTOT',
    ];

    const ignoreFields = [
      'CODTIPVENDA',
      'NUNOTA',
      'CODEMP',
      'CODTIPOPER',
      'DTFATUR',
      'DTNEG',
      'AD_TABELA',
      'DTFATUR',
      'CODVEND',
      'CODPARC',
      'OBSERVACAO',
      'NUMNOTA',
      'CODCONTATO',
    ];

    const myData: ICabecalhoFields[] = [];
    const allData: ICabecalhoFields[] = [];
    const values: any = [];

    setF(apiValues.data.entities.entity);

    extraFields.forEach(item => {
      const indexExtraValue = apiValues.data.entities.metadata.fields.field.findIndex(
        valueItem => valueItem['@attributes'].name === item,
      );

      values.push({ name: item, value: apiValues.data.entities.entity[`f${indexExtraValue}`] });
    });

    resFieldsBASE.data.forEach(item => {
      const options = item.opcao?.map(item => {
        return {
          label: item['@attributes'].descricao,
          value: item['@attributes'].valor,
        };
      });

      const indexValue = apiValues.data.entities.metadata.fields.field.findIndex(
        valueItem => valueItem['@attributes'].name === item['@attributes'].nome,
      );

      allData.push({
        inputName: item['@attributes'].nome,
        inputLabel: item['@attributes'].descricao,
        disabled: item['@attributes'].editavel === 'false' ? true : false,
        required: item['@attributes'].requerido === 'true',
        type: !!item.opcao ? 'select' : 'input',
        options: options || [],
        defaultValue: apiValues.data.entities.entity[`f${indexValue}`],
      });

      if (ignoreFields.includes(item['@attributes'].nome)) return;

      myData.push({
        inputName: item['@attributes'].nome,
        inputLabel: item['@attributes'].descricao,
        disabled: item['@attributes'].editavel === 'false' ? true : false,
        required: item['@attributes'].requerido === 'true',
        type: !!item.opcao ? 'select' : 'input',
        options: options || [],
        defaultValue: apiValues.data.entities.entity[`f${indexValue}`],
      });
    });

    setAllDefaultFields(values);
    setAllFields(allData);
    setFields(myData);
  }

  const isDisabled = !!saveds.find(item => item.value !== 'saved') || state.length > saveds.length;

  function changeQuantity(qtdned: number, id: string) {
    const initialProduct = state.find(prod => prod.id === id);
    const array = [...state.filter(itens => itens.id !== id)];

    if (!initialProduct) {
      return;
    }

    const vlrDescCalc = (initialProduct.vlrunit * initialProduct.vlrdesc) / 100;

    const obj: IProductDTO = {
      ...initialProduct,
      qtdneg: qtdned,
      vlrtot: Number((qtdned * initialProduct.vlrunit).toFixed(2)),
      vlrliq: !vlrDescCalc
        ? Number((qtdned * initialProduct.vlrunit).toFixed(2))
        : Number(((initialProduct.vlrunit - vlrDescCalc) * qtdned).toFixed(2)),
    };
    console.log(obj);

    const prod = saveds.find(item => item.id === initialProduct.codprod);
    if (prod?.value !== 'created') {
      const itesFilter = saveds.filter(item => item.id !== initialProduct.codprod);
      itesFilter.push({ id: initialProduct.codprod, value: 'editaded' });

      setSaveds(itesFilter);
    }
    setState([...array, obj]);
  }

  function changeVlrDesc(vlrDesc: number, id: string) {
    const initialProduct = state.find(prod => prod.id === id);
    const array = [...state.filter(itens => itens.id !== id)];

    if (!initialProduct) {
      return;
    }

    const vlrDescCalc = (initialProduct.vlrunit * vlrDesc) / 100;

    const obj: IProductDTO = {
      ...initialProduct,
      vlrdesc: vlrDesc,
      percdesc: vlrDesc.toString(),
      vlrliq: !vlrDescCalc
        ? Number((initialProduct.qtdneg * initialProduct.vlrunit).toFixed(2))
        : Number(((initialProduct.vlrunit - vlrDescCalc) * initialProduct.qtdneg).toFixed(2)),
    };

    console.log(obj);
    const prod = saveds.find(item => item.id === initialProduct.codprod);
    if (prod?.value !== 'created') {
      const itesFilter = saveds.filter(item => item.id !== initialProduct.codprod);
      itesFilter.push({ id: initialProduct.codprod, value: 'editaded' });

      setSaveds(itesFilter);
    }

    setState([...array, obj]);
  }

  function changeVlrUnit(vlrUnit: number, id: string) {
    const initialProduct = state.find(prod => prod.id === id);
    const array = [...state.filter(itens => itens.id !== id)];

    if (!initialProduct) {
      return;
    }

    const vlrDescCalc = (vlrUnit * initialProduct.vlrdesc) / 100;
    const obj: IProductDTO = {
      ...initialProduct,
      vlrunit: vlrUnit,
      vlrtot: Number((initialProduct.qtdneg * vlrUnit).toFixed(2)),
      vlrliq: !vlrDescCalc
        ? Number((initialProduct.qtdneg * Number(vlrUnit)).toFixed(2))
        : Number(((Number(vlrUnit) - vlrDescCalc) * initialProduct.qtdneg).toFixed(2)),
    };

    console.log(vlrDescCalc);
    console.log(obj);

    const prod = saveds.find(item => item.id === initialProduct.codprod);
    if (prod?.value !== 'created') {
      const itesFilter = saveds.filter(item => item.id !== initialProduct.codprod);
      itesFilter.push({ id: initialProduct.codprod, value: 'editaded' });

      setSaveds(itesFilter);
    }

    setState([...array, obj]);
  }

  async function handlePDF() {
    if (!user.jsessionID) return;
    const path = window.location.hostname;
    const hostname = process.env.NODE_ENV === 'development' ? 'strutural' : path.split('.')[0];

    await axios
      .get(`https://apiapp.deskio.com.br/api/v1/gera-pdf-danfe-app/${hostname}/${params.nunota}`, {
        headers: {
          authorization: `bearer ${user.jsessionID}`,
        },
      })
      .then(res => {
        treatment(res.data);
        if (res.data?.success === false) {
          toast(res.data.message);
          return;
        }
        window.open(res.data.url, '_blank');
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          toast(err?.response?.data?.message);
        } else {
          toast('Não foi possível gerar o PDF');
        }
      });
  }

  const [isConfirmOrcamento, setIsConfirmOrcamento] = useState(false);
  async function confirmOrcamento() {
    setIsConfirmOrcamento(true);
    try {
      const res = await api.post('/quotes/confirm-quote', { NUNOTA: params.nunota });
      treatment(res.data);
      await get();
      await fetch();

      if (res.data.avisos) {
        toast.error(res.data.avisos.aviso);
        setIsConfirmOrcamento(false);
        return;
      }

      toast.success('Orçamento confirmado com sucesso');
    } catch (err) {
      const error: any = err;
      toast.error(error.response.data.erro);
    }
    setIsConfirmOrcamento(false);
  }

  const history = useHistory();
  const [isconverterPedido, setIsconverterPedido] = useState(false);
  async function converterPedido() {
    try {
      setIsconverterPedido(true);
      const { data } = await api.post('/quotes/convert-quote-to-order', { NUNOTA: params.nunota });
      treatment(data);
      Modal.success({
        title: 'Sucesso! ',
        onOk: () => {
          history.push(`/admin/orcamento/${data.notas.nota}`);
          window.location.reload();
        },
        closable: true,
        onCancel: () => {
          Modal.destroyAll();
        },
        content: (
          <div>
            <p>
              Seu orçamento foi convertido para pedido de numero-{data.notas.nota} com sucesso! Deseja ir para a tela de
              pedido?
            </p>
          </div>
        ),
      });

      setIsconverterPedido(false);
    } catch (err) {
      const { response }: any = err;
      setIsconverterPedido(false);
      toast.error(response.data.erro);
    }
  }

  const [isconfirmarPedido, setIsconfirmarPedido] = useState(false);
  async function confirmarPedido() {
    setIsconfirmarPedido(true);
    try {
      const res = await api.post('/quotes/confirm-quote', { NUNOTA: params.nunota });
      treatment(res.data);
      await get();
      await fetch();
      if (res.data.avisos) {
        toast.error(res.data.avisos.aviso);
        setIsConfirmOrcamento(false);
        return;
      }

      toast.success('Pedido confirmado com sucesso');
    } catch (err) {
      const error: any = err;
      toast.error(error.response.data.erro);
    }
    setIsconfirmarPedido(false);
  }

  const [isconfirmarNota, setIsconfirmarNota] = useState(false);
  async function confirmarNota() {
    setIsconfirmarNota(true);
    await api.post('/quotes/confirm-quote', { NUNOTA: params.nunota }).then(res => treatment(res.data));
    await get();
    await fetch();
    toast.success('Confirmado com sucesso');
    setIsconfirmarNota(false);
  }

  // 'CODTIPOPER', 'STATUSNOTA', 'PENDENTE'
  // const CODTIPOPER = allDefaultFields.find(item => item.name === 'CODTIPOPER')?.value || '';
  const STATUSNOTA = allDefaultFields.find(item => item.name === 'STATUSNOTA')?.value || '';
  const PENDENTE = allDefaultFields.find(item => item.name === 'PENDENTE')?.value || '';

  const title = () => {
    if (CODTIPOPER === '900') {
      return `Orçamento de Venda (${params.nunota})`;
    }

    if (CODTIPOPER === '1000' || CODTIPOPER === '1100') {
      return `Pedido de Venda (${params.nunota})`;
    }

    return '';
  };

  const botoes = () => {
    if (CODTIPOPER === '900') {
      return [
        <>
          {STATUSNOTA === 'A' && PENDENTE === 'S' && (
            <Button
              loading={isConfirmOrcamento}
              onClick={() => chamarFuncao(confirmOrcamento)}
              style={{ marginTop: '1rem' }}
              type="primary"
            >
              Confirmar Orçamento
            </Button>
          )}
          <Button style={{ marginTop: '1rem' }} onClick={() => chamarFuncao(handlePDF)} type="primary">
            Ver PDF
          </Button>
        </>,
        <>
          {STATUSNOTA === 'L' && PENDENTE === 'S' && (
            <Button
              loading={isconverterPedido}
              onClick={() => chamarFuncao(converterPedido)}
              style={{ marginTop: '1rem' }}
              type="primary"
              danger
            >
              Converter para pedido
            </Button>
          )}
        </>,
      ];
    }

    if (CODTIPOPER === '1000') {
      return [
        <>
          {STATUSNOTA === 'A' && (
            <Button
              loading={isconfirmarPedido}
              onClick={() => chamarFuncao(confirmarPedido)}
              style={{ marginTop: '1rem' }}
              type="primary"
              danger
            >
              Confirmar pedido
            </Button>
          )}
          <Button style={{ marginTop: '1rem' }} onClick={() => chamarFuncao(handlePDF)} type="primary">
            Ver PDF
          </Button>
        </>,
      ];
    }

    if (CODTIPOPER === '1100') {
      return [
        <>
          {STATUSNOTA === 'A' && (
            <Button
              loading={isconfirmarNota}
              onClick={() => chamarFuncao(confirmarNota)}
              style={{ marginTop: '1rem' }}
              type="primary"
              danger
            >
              Confirmar nota
            </Button>
          )}
        </>,
        <>
          <Button style={{ marginTop: '1rem' }} onClick={() => chamarFuncao(handlePDF)} type="primary">
            Imprimir DANFE
          </Button>
        </>,
      ];
    }

    return [];
  };

  const [isLoading, setIsLoading] = useState(false);
  async function onSubmit(data: any) {
    setIsLoading(true);
    if (data.OBSERVACAO) {
      try {
        await axios
          .post(
            'https://apiapp.deskio.com.br/api/v1/quotes/save-cabecalho',
            {
              ...data,
              SO_OBSERVACAO: 1,
              NUNOTA: params.nunota,
            },
            {
              headers: {
                authorization: `bearer ${token}`,
              },
            },
          )
          .then(res => treatment(res.data));
        setIsLoading(false);
        toast.success('Salvo com sucesso!');
        await get();
      } catch (err) {
        let error: any = err;
        toast.error(error.response.data.erro);
        setIsLoading(false);
      }
    } else {
      try {
        await axios
          .post(
            'https://apiapp.deskio.com.br/api/v1/quotes/save-cabecalho',
            {
              ...data,
              NUNOTA: params.nunota,
            },
            {
              headers: {
                authorization: `bearer ${token}`,
              },
            },
          )
          .then(res => res.data);
        setIsLoading(false);
        toast.success('Salvo com sucesso!');
        await get();
      } catch (err) {
        let error: any = err;
        toast.error(error.response.data.erro);
        setIsLoading(false);
      }
    }
  }

  const formCabecalhoRef = useRef(null);

  return (
    <ThemeLayout>
      <PageHeader
        ghost
        title={title()}
        buttons={[
          <Button style={{ marginTop: '1rem' }} onClick={() => history.push('/admin/orcamentos')}>
            Voltar
          </Button>,
          ...botoes(),
        ]}
      />
      <div style={{ display: 'flex', color: '#424666', alignItems: 'center', gap: '1rem', paddingLeft: '2rem' }}>
        <p>
          <strong>Nro. Único</strong>: {allFields.find(item => item.inputName === 'NUNOTA')?.defaultValue}
        </p>
        <p>
          <strong>Data</strong>: {allFields.find(item => item.inputName === 'DTFATUR')?.defaultValue}
        </p>
        <p>
          <strong>Natureza</strong>: {allFields.find(item => item.inputName === 'CODNAT')?.defaultValue}
        </p>
        <p>
          <strong>Tabela de Preço</strong>: {allFields.find(item => item.inputName === 'AD_TABELA')?.defaultValue}
        </p>
        <p>
          <strong>Vendedor</strong>: {allDefaultFields.find(item => item.name === 'Vendedor_APELIDO')?.value}
        </p>
      </div>
      <Main>
        <Container>
          {allFields.length > 0 && allDefaultFields.length > 0 && (
            <FormWrapper
              initialValues={{
                CODPARC: allFields.find(field => field.inputName === 'CODPARC')?.defaultValue,
                CODTIPVENDA: allFields.find(field => field.inputName === 'CODTIPVENDA')?.defaultValue,
                CODEMP: allFields.find(field => field.inputName === 'CODEMP')?.defaultValue,
                CODTIPOPER: allFields.find(field => field.inputName === 'CODTIPOPER')?.defaultValue,
                DTNEG: moment(allFields.find(field => field.inputName === 'DTNEG')?.defaultValue, 'DD/MM/YYYY'),
              }}
              layout="vertical"
              ref={formCabecalhoRef}
              onFinish={onSubmit}
            >
              <Head>
                <strong>Informações do cabeçalho:</strong>{' '}
              </Head>

              <SelectPar
                onChange={setCodParState}
                defaultValue={allFields.find(field => field.inputName === 'CODPARC')?.defaultValue || 'banana'}
                defaultOption={{
                  CODPARC: allFields.find(field => field.inputName === 'CODPARC')?.defaultValue || '',
                  NOMEPARC: allDefaultFields.find(field => field.name === 'Parceiro_NOMEPARC')?.value || '',
                }}
              />

              <SelectTipVenda
                codParState={codParState}
                defaultOption={{
                  CODTIPVENDA: allFields.find(field => field.inputName === 'CODTIPVENDA')?.defaultValue || '',
                  DESCRTIPVENDA:
                    allDefaultFields.find(field => field.name === 'TipoNegociacao_DESCRTIPVENDA')?.value || '',
                }}
                defaultValue={allFields.find(field => field.inputName === 'CODTIPVENDA')?.defaultValue || ''}
              />
              <SelectCodEmp defaultValue={allFields.find(field => field.inputName === 'CODEMP')?.defaultValue} />
              <SelectTipOper defaultValue={allFields.find(field => field.inputName === 'CODTIPOPER')?.defaultValue} />
              <DatePickerStyle>
                <Form.Item
                  name="DTNEG"
                  label="Data de Negociação"
                  rules={[{ message: 'Campo obrigatório', required: true }]}
                >
                  <DatePicker placeholder="Selecionar Data" format="DD/MM/YYYY" style={{ flex: '1' }} />
                </Form.Item>
              </DatePickerStyle>

              {allFields.find(item => item.inputName === 'CODCONTATO') && (
                <FetchSelect
                  postUrl="/quotes/save-new-contact-sankhya"
                  name="CODCONTATO"
                  title="Contato"
                  method="post"
                  addText="Adicionar contato"
                  placeholder="Pesquise um Contato"
                  addTextModal="Adicionar contato"
                  fetchUrl="/quotes/search-codcontato"
                  formRef={formCabecalhoRef}
                  defaultValue={f?.f12}
                  formModalInitialValues={{
                    codpar: codParState || allFields.find(field => field.inputName === 'CODPARC')?.defaultValue,
                  }}
                  methosPostValue={{
                    codpar: codParState || allFields.find(field => field.inputName === 'CODPARC')?.defaultValue,
                  }}
                  modalContent={
                    <>
                      <Form.Item
                        label="Nome contato"
                        name="NOMECONTATO"
                        rules={[{ required: true, message: 'Título é obrigatório' }]}
                      >
                        <Input placeholder="Nome contato" />
                      </Form.Item>
                      <Form.Item
                        label="Telefone"
                        name="TELEFONE"
                        rules={[{ required: true, message: 'Telefone é obrigatório' }]}
                      >
                        <ReactInputMask className="ant-input" mask="(99) 99999-9999" placeholder="telefone" />
                      </Form.Item>
                      <Form.Item
                        rules={[{ max: 15, message: 'Máximo de 15 caracteres' }]}
                        label="Apelido"
                        name="APELIDO"
                      >
                        <Input placeholder="Apelido" />
                      </Form.Item>
                      <Form.Item label="Email" name="EMAIL">
                        <Input placeholder="email" />
                      </Form.Item>

                      <Form.Item name="codpar" rules={[{ required: true, message: 'Título é obrigatório' }]}>
                        <Input type="hidden" placeholder="codpar" />
                      </Form.Item>

                      <Button htmlType="submit" type="primary">
                        Salvar contato
                      </Button>
                    </>
                  }
                />
              )}

              {fields.map(field => {
                if (
                  cabecalhoTOP.find(item => item['@attributes'].nome === field.inputName)['@attributes']
                    ?.campoDestino === 'CODPARC'
                ) {
                  return (
                    <SelectPar
                      name={field.inputName}
                      label={field.inputLabel}
                      onChange={setCodParState}
                      defaultValue={allFields.find(field => field.inputName === 'CODPARC')?.defaultValue || 'banana'}
                      defaultOption={{
                        CODPARC: allFields.find(field => field.inputName === 'CODPARC')?.defaultValue || '',
                        NOMEPARC: allDefaultFields.find(field => field.name === 'Parceiro_NOMEPARC')?.value || '',
                      }}
                    />
                  );
                }
                if (field.inputName === 'CODNAT') {
                  return <SelectCodNat defaultValue={field.defaultValue} />;
                }

                if (field.type === 'input') {
                  return (
                    <Form.Item
                      name={field.inputName}
                      label={field.inputLabel}
                      rules={[{ message: 'Campo obrigatório', required: field.required }]}
                    >
                      <Input defaultValue={field.defaultValue} disabled={field.disabled} />
                    </Form.Item>
                  );
                }

                return (
                  <Form.Item
                    name={field.inputName}
                    label={field.inputLabel}
                    rules={[{ message: 'Campo obrigatório', required: field.required }]}
                  >
                    <AntdSelect defaultValue={field.defaultValue}>
                      {field.options.map(item => (
                        <AntdSelect.Option value={item.value}>{item.label}</AntdSelect.Option>
                      ))}
                    </AntdSelect>
                  </Form.Item>
                );
              })}
              <Form.Item style={{ display: 'block', paddingTop: 'auto', marginTop: 'auto' }}>
                <Button loading={isLoading} htmlType="submit" type="primary" size="large">
                  Salvar Cabeçalho
                </Button>
              </Form.Item>
            </FormWrapper>
          )}

          <Cards headless bodyStyle={{ height: 'max-content' }}>
            <TableWrapper style={{ height: 'max-content' }} className="table-order table-responsive">
              <TableComponent
                codpar={allFields.find(field => field.inputName === 'CODPARC')?.defaultValue || ''}
                statusNota={STATUSNOTA}
                codtipoper={CODTIPOPER}
                changeVlrUnit={changeVlrUnit}
                pendente={PENDENTE}
                state={state}
                saveds={saveds}
                fetch={fetch}
                get={att}
                setSaveds={setSaveds}
                setState={setState}
                changeQuantity={changeQuantity}
                changeVlrDesc={changeVlrDesc}
                initialData={state.sort((a, b) => a.position - b.position)}
                handleAddItem={() => handleCreateRow({ state, setState })}
              />
            </TableWrapper>
          </Cards>

          {allFields.length > 0 && (
            <FormWrapper
              initialValues={{ OBSERVACAO: allFields.find(item => item.inputName === 'OBSERVACAO')?.defaultValue }}
              layout="vertical"
              onFinish={onSubmit}
            >
              <Form.Item style={{ gridColumn: '1 / span 2' }} name="OBSERVACAO" label="Observação">
                <TextArea style={{ resize: 'none' }} rows={5} />
              </Form.Item>

              <Form.Item>
                <Button loading={isLoading} htmlType="submit" type="primary" size="large">
                  Salvar Alterações
                </Button>
              </Form.Item>
            </FormWrapper>
          )}

          <Cards headless bodyStyle={{ height: 'max-content' }}>
            <Head>
              <strong>Valores Totais:</strong>
            </Head>
            <Total>
              <div>
                <p>Valor Total</p>
                <Input
                  readOnly
                  value={new Intl.NumberFormat('pt-br', { currency: 'BRL', style: 'currency' }).format(
                    Number(allDefaultFields.find(item => item.name === 'VLRNOTA')?.value || 0),
                  )}
                  disabled
                />
              </div>

              <div>
                <p>Valor Desconto Total</p>
                <Input
                  readOnly
                  value={new Intl.NumberFormat('pt-br', { currency: 'BRL', style: 'currency' }).format(
                    Number(f.f25 || 0),
                  )}
                  disabled
                />
              </div>
            </Total>
          </Cards>
        </Container>
      </Main>
    </ThemeLayout>
  );
}
