const baseUrl = 'https://apiapp.deskio.com.br';

export const SEARCH_PRODUCTS_URL = `${baseUrl}/api/v1/consulta-produtos`;
export const INVENTORY_DETAILS_URL = `${baseUrl}/api/v1/consulta-produtos-estoque`;
export const PENDING_ENTRIES_URL = `${baseUrl}/api/v1/consulta-produtos-entradaspendentes`;
export const CONFERENCE_QUEUE_URL = `${baseUrl}/api/v1/fila-conferencia`;
export const CONFERENCE_ITEMS_URL = `${baseUrl}/api/v1/fila-conferencia-itens`;
export const SAVE_ITEM_CONFERRED_URL = `${baseUrl}/api/v1/salvar-item-conferido`;
export const INFO_CONFERENCE_QUEUE_URL = `${baseUrl}/api/v1/informacoes-fila-conferencia`;
export const STATUS_CONFERRED_ITEMS_URL = `${baseUrl}/api/v1/status-itens-conferidos`;
export const END_CONFERENCE_URL = `${baseUrl}/api/v1/finalizar-conferencia`;
export const IMAGES_URL = 'https://strutural.s3.sa-east-1.amazonaws.com';
