import React, { useState } from 'react';
import { ButtonOrc } from '../ButtonOrc';
import { TableComponent } from '../Table';
import { Container } from './styles';

interface ItemsProps {
  nunota: number;
  tipo: number;
  cor: 'AMARELO' | 'AZUL';
  titulo: string;
}

interface DataProps {
  data: string;
  parceiro: string;
  items: ItemsProps[];
  id: number;
  setSelectedNumber: (e: { nunota: number; nucomp: number }) => void;
  selectedNumber: {
    nunota: number;
    nucomp: number;
  };
}

export function BlockComponent({ id, selectedNumber, setSelectedNumber, data, items, parceiro }: DataProps) {
  const idSelect = selectedNumber.nucomp;
  const [tipoName, setTipoName] = useState('');
  const [tipo, setTipo] = useState(0);

  function toggle(e: number) {
    setSelectedNumber({
      nucomp: id,
      nunota: e,
    });
  }

  function close() {
    setSelectedNumber({
      nucomp: 0,
      nunota: 0,
    });
  }

  return (
    <>
      <Container>
        <p style={{ width: '9rem', justifyContent: 'center' }}>{`${data.slice(0, 2)}/${data.slice(2, 4)}/${data.slice(
          4,
          8,
        )}`}</p>
        <p style={{ width: '30rem', marginLeft: '1rem' }} title={parceiro}>
          {parceiro}
        </p>

        <div>
          {items.map(item => (
            <ButtonOrc
              data={item}
              setTipo={setTipo}
              selectedNumber={idSelect === id ? selectedNumber.nunota : 0}
              setSelectedNumber={toggle}
              setTipoName={setTipoName}
            />
          ))}
        </div>
      </Container>

      {idSelect === id && (
        <TableComponent tipo={tipo} tipoName={tipoName} close={close} nunota={selectedNumber.nunota} />
      )}
    </>
  );
}
