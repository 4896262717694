import React, { useState } from 'react';
import styled from 'styled-components';
import { Select, Form as AntdForm } from 'antd';
import { searchProduct, getProduct } from '../functions';
import { IProductDTO } from '../types';
import { handleChangeRow } from '../utils';

const AntdSelect = styled(Select)`
  margin-top: 1.3rem !important;
  .ant-select-selector {
    min-height: 3rem !important;
    max-height: 3rem !important;

    span {
      padding-top: 0.4rem !important;
    }
  }
`;

export function SelectProdu({
  defaultValue,
  nunota,
  row,
  setState,
  saveds,
  setSaveds,
  state,
  codpar,
}: {
  nunota: string;
  row: any;
  defaultValue: string;
  state: IProductDTO[];
  codpar: string;
  setSaveds: (e: { id: string; value: 'saved' | 'created' | 'editaded' }[]) => void;
  saveds: { id: string; value: 'saved' | 'created' | 'editaded' }[];
  setState: (value: React.SetStateAction<IProductDTO[]>) => void;
}) {
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  async function onChange(itemValue: string) {
    setIsLoading(true);
    const res = await getProduct({ codprod: itemValue, nunota });
    const optionDes = options.find(option => option.value === itemValue);

    handleChangeRow({
      saveds,
      setSaveds,
      productProps: res,
      produDescription: optionDes?.label || '',
      rowId: row.id,
      setState,
      state,
    });
    setIsLoading(false);
  }

  async function handleSearch(e: string) {
    const res = await searchProduct({ handleLoading: setIsLoading, nunota, search: e, codpar });
    setIsLoading(false);
    setOptions(res);
  }

  return (
    <AntdForm.Item name="a" style={{ minWidth: '15rem', width: '100%' }}>
      <AntdSelect
        showSearch
        onSearch={e => handleSearch(e)}
        filterOption={false}
        placeholder="Escolha um código de empresa"
        loading={isLoading}
        onChange={(e: any) => onChange(e)}
        style={{ maxWidth: '16rem' }}
        defaultValue={defaultValue}
      >
        {options?.map(option => (
          <AntdSelect.Option value={option.value}>
            {`[${option.value}]`} - {option.label}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    </AntdForm.Item>
  );
}
